import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import axios from 'axios';
import config from '../config';
import baseUrl from '../url';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom';

import ReactDropzone from 'react-dropzone';
import { setObject, getObject } from '../utils/localstorage';
import DateTime from 'react-datetime';
import moment from 'moment';
import { convertToStd, convertToYYMMDD } from '../utils/date';
import TagInputComponent from '../utils/tagInput_component';
import LimitedTextarea from '../utils/textarea_component';
import ReactTable from 'react-table';
import ReactModal from 'react-modal';
import DeleteModal from '../components/DeleteModal';
import WorkActivityCreate from '../components/WorkActivityCreate';
import WorkActivityEdit from '../components/WorkActivityEdit';
import isBase64 from 'is-base64';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import newId from '../utils/newid';
import { arrayMoveImmutable } from 'array-move';

class AnalysisCreatorTemplateEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActiveLoadingOverlay: false,
			image1Url: '',
			project_name: '',
			description: '',
			location: '',
			column: '',
			line_names: '',
			foreman: '',
			safety_representative: '',
			site_supervisor: '',
			site_safety_representative: '',
			estimate_start_date: '',
			estimate_end_date: '',
			schedule_start_date: '',
			schedule_end_date: '',
			list_equipment: [],

			work_operation_list: [],
			work_operation: '',
			work_operation_edit: '',
			showDeleteModal: false,
			deleteId: null,
			showAddModal: false,
			showToEditModal: false,
			showhOpenEditModel: false,
			isBack: false
		};
		this.getDetailAnalysis = this.getDetailAnalysis.bind(this);
		this.handleCreateWorkoperation = this.handleCreateWorkoperation.bind(this);
		this.handleRefreshWorkoperation = this.handleRefreshWorkoperation.bind(this);
		this.handleSaveEditWorkOperation = this.handleSaveEditWorkOperation.bind(this);
		this.handleDeleteWorkOperation = this.handleDeleteWorkOperation.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.refreshTable = this.refreshTable.bind(this);
		this.clearMessage = this.clearMessage.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleToEdit = this.handleToEdit.bind(this);
		this.handleCloseToEdit = this.handleCloseToEdit.bind(this);
		this.handleOpenEditModel = this.handleOpenEditModel.bind(this);
		this.handleCloseEditModel = this.handleCloseEditModel.bind(this);
		this.onDrop1 = this.onDrop1.bind(this);
		this.handleEditAnalysis = this.handleEditAnalysis.bind(this);
		this.readImage = this.readImage.bind(this);

		this.analysis_creator_id = props.analysis_creator_id;
		this.hideNavBar = props.hideNavBar;
		// this.handleEditWorkOperation = props.handleEditWorkOperation;
		this.handleAddWorkOperation = this.handleAddWorkOperation.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	onDrop1(pictureFiles) {
		this.readImage(pictureFiles, (image1Url, image1) => ({
			image1Url,
			image1
		}));
	}

	readImage(pictureFiles, onLoadEndSetState) {
		const reader = new FileReader();
		reader.readAsDataURL(pictureFiles[0]);

		reader.onload = () => {
			this.setState(() => onLoadEndSetState(reader.result, pictureFiles.length === 0 ? null : pictureFiles));
		};
	}

	handleCloseEditModel() {
		this.hideNavBar(false);
		this.setState({
			showhOpenEditModel: false,
			showToEditModal: false,
			work_activity_id: null
		});
	}

	handleOpenEditModel() {
		this.setState({
			showhOpenEditModel: true,
			showToEditModal: false
		});
	}

	handleCloseToEdit() {
		this.hideNavBar(false);
		this.setState({
			showToEditModal: false
		});
	}

	handleToEdit(value) {
		this.hideNavBar(true);
		this.setState({
			showToEditModal: true,
			work_activity_id: value
		});
	}

	handleDelete(deleteId) {
		this.setState({
			showDeleteModal: true,
			deleteId
		});
	}

	async handleEditAnalysis(event) {
		event.preventDefault();

		if (this.state.list_equipment !== 0) {
			try {
				this.setState({
					isActiveLoadingOverlay: true,
					failedMessagePut: ''
				});
				const user = getObject('user');

				const header = {
					headers: { 'x-access-token': user.token }
				};
				const data = {
					project_name: this.state.project_name,
					description: this.state.description,
					location: this.state.location,
					column: this.state.column,
					line_names: this.state.line_names,
					foreman: this.state.foreman,
					safety_representative: this.state.safety_representative,
					site_safety_supervisor: this.state.site_safety_representative,
					site_supervisor: this.state.site_supervisor,

					estimate_start_date: convertToYYMMDD(this.state.estimate_start_date),
					estimate_end_date: convertToYYMMDD(this.state.estimate_end_date),
					schedule_start_date: convertToYYMMDD(this.state.schedule_start_date),
					schedule_end_date: convertToYYMMDD(this.state.schedule_end_date),

					list_equipment: this.state.list_equipment.toString(),
					logo: this.state.image1Url
				};
				const dataWorkOperation = {
					analysis_creator_id: this.analysis_creator_id,
					data: this.state.work_operation_list.map((v) => ({
						description: v.description
					}))
				};

				await axios.put(`${config.api.analysiscreator}/${this.analysis_creator_id}`, data, header);
				await axios.post(`${config.api.analysiscreator}/work_operation/multiple`, dataWorkOperation, header);

				this.setState({
					isBack: true
				});
			} catch (e) {
				this.setState({
					failedMessagePut: e.message
				});
			} finally {
				this.setState({
					isActiveLoadingOverlay: false
				});
			}
		} else {
			this.setState({
				failedMessage: 'Personal protective equipment required'
			});
		}
	}

	clearMessage() {
		this.setState({
			successMessage: '',
			failedMessage: ''
		});
	}

	fetchData(state, instance) {
		this.clearMessage();
		this.setState({ loading: true });
		this.refreshTable(state);
	}
	refreshTable(state) {
		const user = getObject('user');
		const size = state.pageSize;
		const page = parseInt(state.page) + 1;

		this.page = parseInt(state.page);
		this.pageSize = state.pageSize;

		// const filtered = this.filters;

		// // console.log(filtered);

		// let filterString = '';
		// const filterXs = R.pipe(R.filter((x) => x.value !== ''), R.map((x) => `${x.id}=${x.value}`))(filtered);

		// filterString = filterXs.join('&');

		// const sortedXs = R.map((x) => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`, state.sorted || []);
		// let sortedString = '&orderBy=createdDate&orderDirection=desc';

		// if (sortedXs.length > 0) {
		// 	sortedString = sortedXs.join('&');
		// }

		axios({
			url: `${config.api.analysiscreator}/work_activity_all/${this
				.analysis_creator_id}?size=${size}&page=${page}`,
			method: 'get',
			headers: { 'x-access-token': user.token }
		})
			.then((response) => {
				const data = response.data.data;
				console.log(data);
				this.setState({
					data: data.results,
					pages: data.totalPage,
					loading: false
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	async componentDidMount() {
		this.getDetailAnalysis();
	}

	async handleSaveEditWorkOperation(id) {
		if (this.state.work_operation_edit) {
			try {
				const user = getObject('user');
				this.setState({
					isActiveLoadingOverlay: true
				});
				const dataWorkOperation = {
					description: this.state.work_operation_edit
				};

				axios({
					url: `${config.api.analysiscreator}/work_operation/${id}`,
					method: 'put',
					data: dataWorkOperation,
					headers: { 'x-access-token': user.token }
				})
					.then(async (v) => {
						await this.refreshWorkOperation(this.state.analysis_creator_id);
					})
					.catch((err) => {
						this.setState({
							isActiveLoadingOverlay: false
						});
					});
			} catch (e) {
			} finally {
				this.setState({
					// isActiveLoadingOverlay: false,
					work_operation_edit: ''
				});
			}
		}
	}

	async getDetailAnalysis() {
		try {
			const user = getObject('user');

			this.setState({
				isActiveLoadingOverlay: true,
				detailData: null,
				work_operation_list: []
			});

			const analysisDetailPromise = axios({
				url: `${config.api.analysiscreator}/${this.analysis_creator_id}`,
				method: 'get',
				headers: { 'x-access-token': user.token }
			});

			const workOperationListPromise = axios({
				url: `${config.api.analysiscreator}/list_work_operation/${this.analysis_creator_id}`,
				method: 'get',
				headers: { 'x-access-token': user.token }
			});

			const [ analysisDetailResult, workOperationListResult ] = await Promise.all([
				analysisDetailPromise,
				workOperationListPromise
			]);
			const analysisDetail = analysisDetailResult.data.data;
			const workOperationListDetail = workOperationListResult.data.data.map((v) => ({
				id: v.id.toString(),
				description: v.description
			}));

			this.setState({
				detailData: analysisDetail,
				isActiveLoadingOverlay: false,
				image1Url: analysisDetail.logo,
				project_name: analysisDetail.project_name,
				description: analysisDetail.description,
				location: analysisDetail.location,
				column: analysisDetail.column,
				line_names: analysisDetail.line_names,
				foreman: analysisDetail.foreman,
				safety_representative: analysisDetail.safety_representative,
				site_supervisor: analysisDetail.site_supervisor ? analysisDetail.site_supervisor : '',
				site_safety_representative: analysisDetail.site_safety_supervisor,
				estimate_start_date: analysisDetail.estimate_start_date,
				estimate_end_date: analysisDetail.estimate_end_date,
				schedule_start_date: analysisDetail.schedule_start_date,
				schedule_end_date: analysisDetail.schedule_end_date,

				list_equipment: analysisDetail.list_equipment.split(','),
				work_operation_list: workOperationListDetail
			});
		} catch (e) {
		} finally {
			this.setState({
				isActiveLoadingOverlay: false
			});
		}
	}

	handleAddWorkOperation() {
		let markers = [
			...this.state.work_operation_list,
			{
				id: newId(),
				description: this.state.work_operation
			}
		];

		this.setState({
			work_operation_list: markers,
			work_operation: ''
		});
	}

	handleCreateWorkoperation() {
		const user = getObject('user');

		this.setState({
			isActiveLoadingOverlay: true
		});
		const dataWorkOperation = {
			description: this.state.work_operation,
			analysis_creator_id: this.analysis_creator_id
		};

		axios({
			url: `${config.api.analysiscreator}/work_operation`,
			method: 'post',
			data: dataWorkOperation,
			headers: { 'x-access-token': user.token }
		})
			.then(async (response) => {
				const data = response.data.data;

				await this.handleRefreshWorkoperation();

				this.setState({
					work_operation: '',
					isActiveLoadingOverlay: false
				});
			})
			.catch((err) => {
				this.setState({
					isActiveLoadingOverlay: false
				});
			});
	}

	async handleDeleteWorkOperation(id) {
		this.setState((prevState) => ({
			work_operation_list: prevState.work_operation_list.filter((v) => v.id !== id)
		}));
		// try {
		// 	const user = getObject('user');
		// 	this.setState({
		// 		isActiveLoadingOverlay: true
		// 	});

		// 	const response = await axios({
		// 		url: `${config.api.analysiscreator}/work_operation/${id}`,
		// 		method: 'delete',

		// 		headers: { 'x-access-token': user.token }
		// 	});
		// 	await this.handleRefreshWorkoperation();
		// } catch (e) {
		// } finally {
		// 	this.setState({
		// 		isActiveLoadingOverlay: false
		// 	});
		// }
	}
	async handleRefreshWorkoperation() {
		// const user = getObject('user');
		// this.setState({
		// 	work_operation_list: []
		// });
		// await axios({
		// 	url: `${config.api.analysiscreator}/list_work_operation/${this.analysis_creator_id}`,
		// 	method: 'get',
		// 	headers: { 'x-access-token': user.token }
		// })
		// 	.then((response) => {
		// 		const data = response.data.data;
		// 		this.setState({
		// 			work_operation_list: data
		// 		});
		// 	})
		// 	.catch((err) => {
		// 		this.setState({
		// 			isActiveLoadingOverlay: false
		// 		});
		// 	});
	}

	handleEditWorkOperation(value, id) {
		let markers = [ ...this.state.work_operation_list ];
		const fromIndex = markers.map((x) => x.id).indexOf(id);

		markers[fromIndex] = { ...markers[fromIndex], description: value };
		this.setState({
			work_operation_list: markers
		});
		// this.setState({
		// 	work_operation_edit
		// });
	}

	async onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		let arr = [ ...this.state.work_operation_list ];

		const fromIndex = arr.map((x) => x.id).indexOf(result.draggableId);
		const toIndex = result.destination.index;

		var newArry = arrayMoveImmutable(arr, fromIndex, toIndex);

		this.state.work_operation_list = newArry;

		this.forceUpdate();
	}

	render() {
		if (this.state.isBack) {
			return <Redirect to={`/analysiscreator/analysiscreatordetail/${this.analysis_creator_id}`} />;
		}
		const columns = [
			{
				Header: 'Action',
				accessor: 'id',
				// id: "inspection",
				width: 150,
				sortable: false,
				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					// console.log('Masuk inspection = ', dataAnalysis);
					return (
						<div className="row">
							<div className="col-md-auto">
								<button
									type="button"
									className="btn btn-sm"
									onClick={() => this.handleToEdit(cellInfo.value)}
								>
									Edit
								</button>
							</div>
							<div className="col-md-auto">
								<button
									type="button"
									className="btn btn-sm"
									onClick={() => this.handleDelete(cellInfo.value)}
								>
									Delete
								</button>
							</div>
						</div>
					);
				}
			},

			{
				Header: 'Work Activity',
				accessor: 'task',
				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					const image = `${baseUrl}/${dataAnalysis.image}`;
					// console.log('Masuk inspection = ', dataAnalysis);
					return (
						<div className="col-md-auto">
							<div className="col-md-auto">
								<label>{dataAnalysis.task}</label>
							</div>
							{dataAnalysis.image ? (
								<div className="col-md-auto">
									<img
										src={image}
										width="200"
										height="140"
										className="img-fluid"
										alt="Responsive image"
									/>
								</div>
							) : null}
							{/* <div className="col-md-auto">
								<img src={image} width="200" height="140" class="img-fluid" alt="Responsive image" />
							</div> */}
						</div>
					);
				}
			},
			{
				Header: 'Potential Hazard',
				accessor: 'workResult',
				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					// console.log('Potential Hazard = ', dataAnalysis);
					return (
						<div>
							{dataAnalysis.workResult.map((v) => (
								<div className="col-md-auto">
									<p>{v.potensial} </p>
								</div>
							))}
						</div>
					);
				}
			},
			{
				Header: 'Preventive/Countermeasures',
				accessor: 'workResult',
				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					const image = `${baseUrl}/${dataAnalysis.image}`;

					// console.log('Preventive/Countermeasures = ', dataAnalysis);
					return (
						<div>
							{dataAnalysis.workResult.map((v) => (
								<div className="col-md-auto">
									<div className="col-md-auto">
										<p>{v.preventive} </p>
									</div>
									{v.image ? (
										<div className="col-md-auto">
											<img
												src={`${baseUrl}/${v.image}`}
												width="200"
												height="140"
												className="img-fluid"
												alt="Responsive image"
											/>
										</div>
									) : null}
								</div>
							))}
						</div>
					);
				}
			}
		];
		const { data, pages, loading } = this.state;
		var modalStyles = { overlay: { zIndex: 10 } };

		return (
			<BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
				<div className="container">
					<br />

					<div className="col-12">
						<h1>Edit Analysis</h1>
					</div>
					<hr />
					<br />

					<form key={this.analysis_creator_id} onSubmit={(event) => this.handleEditAnalysis(event)}>
						<div className="col-12">
							<label className="text-muted">
								<b>COMPANY LOGO</b>
							</label>
							<div className="border rounded p-3">
								<ReactDropzone
									className="dragAndDropArea d-flex align-items-center justify-content-center"
									style={{ height: 250 }}
									onDrop={this.onDrop1}
									accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
									multiple={false}
									required
									accept="image/*"
								>
									{this.state.detailData ? !this.state.image1Url ? (
										<div>
											<label className="mx-auto text-muted">
												Drag a photo here or click to upload.
											</label>
										</div>
									) : isBase64(this.state.image1Url, { allowMime: true }) ? (
										<img
											className="profile-panel DragnDropDP"
											key={this.state.image1Url}
											src={this.state.image1Url}
											style={{ height: 250 }}
										/>
									) : (
										<img
											src={`${baseUrl}/${this.state.image1Url}`}
											style={{ height: 250 }}
											className="img-fluid"
											alt="Responsive image"
										/>
									) : (
										<p>-</p>
									)}
									{/* {this.state.detailData ? this.state.image1Url ? (
										<img
											className="img-fluid"
											alt="Responsive image"
											key={this.state.image1Url}
											src={`${baseUrl}/${this.state.image1Url}`}
											style={{ height: 250 }}
										/>
									) : (
										<p>-</p>
									) : (
										<div>
											<label className="mx-auto text-muted">
												{' '}
												Drag a photo here or click to upload.{' '}
											</label>
										</div>
									)} */}
								</ReactDropzone>
							</div>
						</div>
						<br />
						<div className=" col-12">
							<label className="text-muted">
								<b>JOB INFORMATION</b>
							</label>
						</div>
						<div className="col-12">
							<label>Project Name</label>
							<input
								type="text"
								placeholder="Input project name"
								value={this.state.project_name}
								defaultValue={this.state.project_name}
								required
								className="form-control mb-3"
								onChange={(event) => {
									this.setState({
										project_name: event.target.value
									});
								}}
							/>

							<label>Description of Work</label>
							<textarea
								className="form-control mb-3"
								id="exampleFormControlTextarea1"
								maxLength={10000}
								rows="3"
								required
								defaultValue={this.state.description}
								value={this.state.description}
								onChange={(event) => {
									this.setState({
										description: event.target.value
									});
								}}
							/>
							<div className="row justify-content-between">
								<div className=" col-4">
									<label>Location</label>
									<input
										type="text"
										placeholder="Input location "
										required
										value={this.state.location}
										defaultValue={this.state.location}
										className="form-control mb-3"
										onChange={(event) => {
											this.setState({
												location: event.target.value
											});
										}}
									/>
								</div>
								<div className=" col-4">
									<label>Column(s)</label>
									<input
										type="text"
										placeholder="Input column "
										required
										value={this.state.column}
										defaultValue={this.state.column}
										className="form-control mb-3"
										onChange={(event) => {
											this.setState({
												column: event.target.value
											});
										}}
									/>
								</div>
								<div className=" col-4">
									<label>Line Name(s)</label>
									<input
										type="text"
										placeholder="Input line name "
										required
										value={this.state.line_names}
										defaultValue={this.state.line_names}
										className="form-control mb-3"
										onChange={(event) => {
											this.setState({
												line_names: event.target.value
											});
										}}
									/>
								</div>
							</div>
						</div>
						<br />

						<div className=" col-12">
							<label className="text-muted">
								<b>CONTACT INFORMATION</b>
							</label>
						</div>
						<div className="col-12">
							<label>Foreman/Supervisor Name</label>
							<input
								type="text"
								placeholder="Input foreman/supervisor name"
								required
								value={this.state.foreman}
								defaultValue={this.state.foreman}
								className="form-control mb-3"
								onChange={(event) => {
									this.setState({
										foreman: event.target.value
									});
								}}
							/>
							<label>Safety Representative</label>
							<input
								type="text"
								required
								placeholder="Input safety representative"
								value={this.state.safety_representative}
								defaultValue={this.state.safety_representative}
								className="form-control mb-3"
								onChange={(event) => {
									this.setState({
										safety_representative: event.target.value
									});
								}}
							/>
							<label>Site Supervisor</label>
							<input
								type="text"
								placeholder="Input site supervisor"
								required
								value={this.state.site_supervisor}
								defaultValue={this.state.site_supervisor}
								className="form-control mb-3"
								onChange={(event) => {
									this.setState({
										site_supervisor: event.target.value
									});
								}}
							/>
							<label>Site Safety Representative</label>
							<input
								type="text"
								placeholder="Input site safety representative"
								required
								value={this.state.site_safety_representative}
								defaultValue={this.state.site_safety_representative}
								className="form-control mb-3"
								onChange={(event) => {
									this.setState({
										site_safety_representative: event.target.value
									});
								}}
							/>
						</div>
						<br />

						<div className=" col-12">
							<label className="text-muted">
								<b>ESTIMATED WORK DATES</b>
							</label>
						</div>
						<div className=" col-12">
							<div className="row justify-content-between">
								<div className="col-6">
									<label>Start Date</label>
									<DateTime
										onChange={(value) => {
											if (moment(value, 'YYYY-MM-DD').isValid()) {
												this.setState({
													estimate_start_date: value
												});
											} else {
												this.setState({
													estimate_start_date: null
												});
											}
										}}
										closeOnSelect={true}
										closeOnTab={true}
										dateFormat={true}
										timeFormat={false}
										inputProps={{
											placeholder: 'Start Date',
											required: true,
											value: `${this.state.estimate_start_date
												? convertToStd(this.state.estimate_start_date)
												: ''}`
										}}
										value={convertToStd(this.state.estimate_start_date)}
									/>
								</div>
								<div className=" col-6">
									<label>End Date</label>
									<DateTime
										onChange={(value) => {
											if (moment(value, 'YYYY-MM-DD').isValid()) {
												this.setState({
													estimate_end_date: value
												});
											} else {
												this.setState({
													estimate_end_date: null
												});
											}
										}}
										closeOnSelect={true}
										closeOnTab={true}
										dateFormat={true}
										timeFormat={false}
										inputProps={{
											placeholder: 'End Date',
											required: true,
											value: `${this.state.estimate_end_date
												? convertToStd(this.state.estimate_end_date)
												: ''}`
										}}
										value={convertToStd(this.state.estimate_end_date)}
									/>
								</div>
							</div>
						</div>
						<br />
						<div className=" col-12">
							<label className="text-muted">
								<b>WORK SCHEDULE</b>
							</label>
						</div>
						<div className=" col-12">
							<div className="row justify-content-between">
								<div className="col-6">
									<label>Start Date</label>
									<DateTime
										onChange={(value) => {
											if (moment(value, 'YYYY-MM-DD').isValid()) {
												this.setState({
													schedule_start_date: value
												});
											} else {
												this.setState({
													schedule_start_date: null
												});
											}
										}}
										closeOnSelect={true}
										closeOnTab={true}
										dateFormat={true}
										timeFormat={false}
										inputProps={{
											placeholder: 'Start Date',
											required: true,
											value: `${this.state.schedule_start_date
												? convertToStd(this.state.schedule_start_date)
												: ''}`
										}}
										value={convertToStd(this.state.schedule_start_date)}
									/>
								</div>
								<div className=" col-6">
									<label>End Date</label>
									<DateTime
										onChange={(value) => {
											if (moment(value, 'YYYY-MM-DD').isValid()) {
												this.setState({
													schedule_end_date: value
												});
											} else {
												this.setState({
													schedule_end_date: null
												});
											}
										}}
										closeOnSelect={true}
										closeOnTab={true}
										dateFormat={true}
										timeFormat={false}
										inputProps={{
											placeholder: 'End Date',
											required: true,
											value: `${this.state.schedule_end_date
												? convertToStd(this.state.schedule_end_date)
												: ''}`
										}}
										value={convertToStd(this.state.schedule_end_date)}
									/>
								</div>
							</div>
						</div>

						<br />

						<div className=" col-12">
							<label className="text-muted">
								<b>PPE</b>
							</label>
						</div>
						<div className="col-12">
							<label>Required Personal Protective Equipment</label>
							<TagInputComponent
								tags={this.state.list_equipment}
								onChange={(value) => {
									this.setState({
										list_equipment: value.tagData
									});
								}}
							/>

							<p className="text-muted float-left">Input Multiple Item, press enter to add Item</p>
						</div>
						<br />
						<br />
						<br />

						<div className=" col-12">
							<label className="text-muted">
								<b>WORK OPERATION</b>
							</label>
						</div>
						<div className="col-12">
							<label>Work Operation Description</label>
							{/* <LimitedTextarea
								limit={10000}
								required
								defaultValue={this.state.work_operation}
								rows="1"
								onChange={(event) => this.handleworkOperationDescription(event.text)}
							/> */}

							<div className="row mb-2">
								<div className="col-10">
									<textarea
										class="form-control"
										id="exampleFormControlTextarea1"
										maxLength={10000}
										rows="1"
										value={this.state.work_operation}
										onChange={(event) => {
											this.setState({
												work_operation: event.target.value
											});
										}}
									/>
									<p className="text-muted float-right">
										{this.state.work_operation ? this.state.work_operation.length : 0} of 10.000
										Character remaining
									</p>
								</div>
								<div className="row col-2 align-self-start">
									<button
										onClick={this.handleAddWorkOperation}
										type="button"
										class="btn btn-primary"
										disabled={!this.state.work_operation}
									>
										<span className="fe fe-plus" />
									</button>
								</div>
							</div>
						</div>
						{/* {this.state.work_operation_list ? (
							this.state.work_operation_list.map((v) => (
								<div className="row col-12 mb-3">
									<div className="col-10">
										<LimitedTextarea
											id={v.id}
											limit={10000}
											required
											defaultValue={v.description}
											rows="1"
											onChange={(event) => {
												this.setState({
													work_operation_edit: event.text
												});
											}}
										/>
									</div>
									<div className="row col-2">
										<div className="mr-2">
											<button
												type="button"
												class="btn-primary btn"
												onClick={() => this.handleSaveEditWorkOperation(v.id)}
											>
												<span class="fe fe-save" />
											</button>
										</div>
										{this.state.work_operation_list.length !== 1 ? (
											<div className=" ">
												<button
													type="button"
													class="btn-danger btn"
													onClick={() => this.handleDeleteWorkOperation(v.id)}
												>
													<span class="fe fe-trash" />
												</button>
											</div>
										) : null}
									</div>
								</div>
							))
						) : null} */}

						{this.state.work_operation_list ? (
							<DragDropContext onDragEnd={this.onDragEnd}>
								<Droppable droppableId="droppable1">
									{(provided, snapshot) => (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											{this.state.work_operation_list.map((item, index) => (
												<Draggable key={item.id} draggableId={item.id} index={index}>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															snapshot={snapshot}
														>
															<div className="row col-12 mb-3 ">
																<div className="col-10">
																	<textarea
																		class="form-control"
																		id={item.id}
																		maxLength={10000}
																		required
																		rows="1"
																		placeholder=""
																		value={item.description}
																		onChange={(event) =>
																			this.handleEditWorkOperation(
																				event.target.value,
																				item.id
																			)}
																	/>
																	<p className="text-muted float-right">
																		{item.description ? item.description.length : 0}{' '}
																		of 10.000 Character remaining
																	</p>
																</div>
																<div className="row col-2">
																	{this.state.work_operation_list.length !== 1 ? (
																		<div className=" mr-1">
																			<button
																				type="button"
																				class="btn-danger btn"
																				onClick={() =>
																					this.handleDeleteWorkOperation(
																						item.id
																					)}
																			>
																				<span class="fe fe-trash" />
																			</button>
																		</div>
																	) : null}

																	<div className="icon-color-drag">
																		{/* <span class="fe fe-grid" /> */}
																		<div class="btn-info btn disabled">
																			<span class="fe fe-grid" />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													)}
												</Draggable>
											))}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						) : null}

						<br />
						<br />
						<div className=" col-12">
							<label className="text-muted">
								<b>WORK ACTIVITY</b>
							</label>
						</div>
						<div className="col-12">
							<button
								type="button"
								className="btn"
								onClick={() => {
									this.hideNavBar(true);
									this.setState({
										showAddModal: true
									});
								}}
							>
								<div className="row " style={{ marginRight: '5px', marginLeft: '5px' }}>
									<span className=" fe fe-plus" style={{ marginRight: '2px' }} />
									<span className="fefont"> New Task</span>
								</div>
							</button>
						</div>
						<br />
						<ReactModal
							isOpen={this.state.showDeleteModal}
							contentLabel="Minimal Modal Example"
							style={{
								content: {
									top: '50%',
									left: '50%',
									right: 'auto',
									bottom: 'auto',
									marginRight: '-50%',
									transform: 'translate(-50%, -50%)'
								}
							}}
						>
							<DeleteModal
								closeModal={() => {
									this.setState({
										showDeleteModal: false
									});
								}}
								id={this.state.deleteId}
								refreshTable={this.fetchData}
								setSuccessMessage={this.setSuccessMessage}
								setFailedMessage={this.setFailedMessage}
								deleteUrl={`${config.api.analysiscreator}/work_activity`}
							/>
						</ReactModal>
						<ReactModal
							isOpen={this.state.showhOpenEditModel}
							contentLabel="Minimal Modal Example"
							style={modalStyles}
						>
							<WorkActivityEdit
								closeModal={this.handleCloseEditModel}
								refreshTable={this.fetchData}
								analysis_creator_id={this.state.analysis_creator_id}
								work_activity_id={this.state.work_activity_id}
								tablePaging={{
									page: this.page,
									pageSize: this.pageSize
								}}
								setFailedMessage={this.setFailedMessage}
								hideNavBar={this.hideNavBar}
								setSuccessMessage={this.setSuccessMessage}
							/>
						</ReactModal>
						<ReactModal
							isOpen={this.state.showAddModal}
							contentLabel="Minimal Modal Example"
							style={modalStyles}
						>
							<WorkActivityCreate
								closeModal={() => {
									this.hideNavBar(false);
									this.setState({
										showAddModal: false
									});
								}}
								refreshTable={this.fetchData}
								analysis_creator_id={this.analysis_creator_id}
								tablePaging={{
									page: this.page,
									pageSize: this.pageSize
								}}
								setFailedMessage={this.setFailedMessage}
								setSuccessMessage={this.setSuccessMessage}
							/>
						</ReactModal>
						<ReactModal
							isOpen={this.state.showToEditModal}
							contentLabel="Minimal Modal Example"
							style={{
								content: {
									top: '50%',
									left: '50%',
									right: 'auto',
									bottom: 'auto',
									marginRight: '-50%',
									transform: 'translate(-50%, -50%)'
								}
							}}
						>
							<div>
								<div className="align-middle">
									<div className="form-group">
										{
											'Are you sure want to edit this task data? the automatically generated data system will be lost'
										}
									</div>
								</div>
								<div className="row" style={{ marginBottom: '10px' }}>
									<div className="col-md-6">
										<button
											className="btn"
											style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
											onClick={this.handleOpenEditModel}
										>
											Yes
										</button>
									</div>
									<div className="col-md-6">
										<button
											className="btn"
											style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
											onClick={this.handleCloseToEdit}
										>
											No
										</button>
									</div>
								</div>
							</div>
						</ReactModal>
						<div className="col-12">
							<ReactTable
								columns={columns}
								manual
								data={data}
								pages={pages}
								loading={loading}
								onFetchData={this.fetchData}
								filterable={false}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
						</div>
						<br />
						<br />
						<div className="col-12">
							<button
								type="submit"
								style={{ width: '100%' }}
								className="btn"
								// onClick={this.handleEditAnalysis}
							>
								<span className="fefont">Save</span>
							</button>
						</div>
						<br />
						{this.state.failedMessagePut ? (
							<div className="col-12">
								<div className="alert alert-danger  " role="alert">
									{this.state.failedMessagePut}
								</div>
							</div>
						) : null}
					</form>
				</div>
			</BlockUi>
		);
	}
}

export default withRouter(AnalysisCreatorTemplateEdit);
