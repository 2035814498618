import React, { Component } from 'react';
import ReactDropzone from 'react-dropzone';

import { setObject, getObject } from '../utils/localstorage';
import axios from 'axios';
import isBase64 from 'is-base64';
import config from '../config';
import BlockUi from 'react-block-ui';
import baseUrl from '../url';
import LimitedTextarea from '../utils/textarea_component';
import ReactModal from 'react-modal';
import DeleteModal from '../components/DeleteModal';

// import { isBase64 } from '../utils/common_function';

class WorkActivityEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			task: null,
			imageUrl: null,
			imageUrlPreventive: null,
			loading: false,
			workActivityDetail: null,
			showDeleteModal: false,
			id_work_result: null
		};

		this.handleBack = this.handleBack.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.onDropPreventive = this.onDropPreventive.bind(this);
		this.readImage = this.readImage.bind(this);
		this.handleTask = this.handleTask.bind(this);
		this.createWorkActivity = this.createWorkActivity.bind(this);
		// this.createWorkResult = this.createWorkResult.bind(this);
		this.handleEditPreventive = this.handleEditPreventive.bind(this);
		this.handleEditPotential = this.handleEditPotential.bind(this);
		this.handleSavePotentialPreventive = this.handleSavePotentialPreventive.bind(this);
		this.handleOpenDeleteModel = this.handleOpenDeleteModel.bind(this);
		this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
		this.handleDeleteListModal = this.handleDeleteListModal.bind(this);

		this.closeModal = props.closeModal;
		this.refreshTable = props.refreshTable;
		this.analysis_creator_id = props.analysis_creator_id;
		this.work_activity_id = props.work_activity_id;
		this.hideNavBar = props.hideNavBar;
	}

	handleCloseDeleteModal() {
		this.setState({
			showDeleteModal: false
		});
	}
	handleDeleteListModal() {
		const newlist = this.state.workActivityDetail.workResult.filter(
			(item) => item.id !== this.state.id_work_result
		);
		this.setState((prevState) => ({
			workActivityDetail: {
				...prevState.workActivityDetail,
				workResult: newlist
			}
		}));
	}
	handleOpenDeleteModel(id) {
		this.setState({
			showDeleteModal: true,
			id_work_result: id
		});
	}

	async componentDidMount() {
		this.handleRefresh();
	}

	createWorkActivity() {
		try {
			const user = getObject('user');

			const data = {
				task: this.state.workActivityDetail.task,
				image: this.state.workActivityDetail.image
			};
			if (!this.state.workActivityDetail.image) {
				delete data.image;
			}

			this.setState({
				messageFail: '',
				isActiveLoadingOverlay: true
			});

			axios({
				url: `${config.api.analysiscreator}/work_activity/${this.work_activity_id}`,
				method: 'put',
				data: data,
				headers: { 'x-access-token': user.token }
			})
				.then((response) => {
					const data = response.data.data;
					this.setState({
						isActiveLoadingOverlay: false
					});
					this.handleBack();
				})
				.catch((err) => {
					this.setState({
						isActiveLoadingOverlay: false
					});
				});
		} catch (e) {
			this.setState({
				isActiveLoadingOverlay: false
			});
		} finally {
		}
	}

	handleSavePotentialPreventive(id, index) {
		try {
			const user = getObject('user');

			const found = this.state.workActivityDetail.workResult.find((element) => element.id === id);

			if (found.image.length !== 0 && found.potensial.length !== 0 && found.preventive.length !== 0) {
				let markers = [ ...this.state.workActivityDetail.workResult ];
				markers[index] = { ...markers[index], message: '' };
				this.setState((prevState) => ({
					isActiveLoadingOverlay: true,
					workActivityDetail: {
						...prevState.workActivityDetail,
						workResult: markers
					}
				}));

				const data = {
					potensial: found.potensial,
					preventive: found.preventive,
					image: found.image
				};

				axios({
					url: `${config.api.analysiscreator}/work_result/${id}`,
					method: 'put',
					data: data,
					headers: { 'x-access-token': user.token }
				})
					.then((response) => {
						this.handleRefresh();
					})
					.catch((e) => {
						console.log(e.message);
						this.setState({
							isActiveLoadingOverlay: false
						});
					});
			} else {
				if (found.image.length === 0) {
					let markers = [ ...this.state.workActivityDetail.workResult ];
					markers[index] = { ...markers[index], message: 'Image required' };
					this.setState((prevState) => ({
						workActivityDetail: {
							...prevState.workActivityDetail,
							workResult: markers
						}
					}));
				}
				if (found.potensial.length === 0) {
					let markers = [ ...this.state.workActivityDetail.workResult ];
					markers[index] = { ...markers[index], message: 'Potential hazard required' };
					this.setState((prevState) => ({
						workActivityDetail: {
							...prevState.workActivityDetail,
							workResult: markers
						}
					}));
				}
				if (found.preventive.length === 0) {
					let markers = [ ...this.state.workActivityDetail.workResult ];
					markers[index] = { ...markers[index], message: 'Preventive required' };
					this.setState((prevState) => ({
						workActivityDetail: {
							...prevState.workActivityDetail,
							workResult: markers
						}
					}));
				}
			}
		} catch (e) {
			this.setState({
				isActiveLoadingOverlay: false
			});
		} finally {
		}
	}
	handleTask(value) {
		this.setState((prevState) => ({
			workActivityDetail: {
				...prevState.workActivityDetail,
				task: value
			}
		}));
	}

	onDrop(pictureFiles) {
		const reader = new FileReader();
		reader.readAsDataURL(pictureFiles[0]);
		reader.onload = () => {
			this.setState((prevState) => ({
				workActivityDetail: {
					...prevState.workActivityDetail,
					image: reader.result
				}
			}));
		};
	}

	onDropPreventive(pictureFiles, index) {
		const reader = new FileReader();
		reader.readAsDataURL(pictureFiles[0]);

		reader.onload = () => {
			let markers = [ ...this.state.workActivityDetail.workResult ];
			markers[index] = { ...markers[index], image: reader.result };
			this.setState((prevState) => ({
				workActivityDetail: {
					...prevState.workActivityDetail,
					workResult: markers
				}
			}));
		};
	}

	readImage(pictureFiles, onLoadEndSetState) {
		const reader = new FileReader();
		reader.readAsDataURL(pictureFiles[0]);

		reader.onload = () => {
			this.setState(() => onLoadEndSetState(reader.result, pictureFiles.length === 0 ? null : pictureFiles));
		};
	}

	async handleBack() {
		this.closeModal();
		this.refreshTable({
			page: this.props.tablePaging.page,
			pageSize: this.props.tablePaging.pageSize,
			filtered: []
		});
	}

	handleEditPreventive(value, index) {
		let markers = [ ...this.state.workActivityDetail.workResult ];
		markers[index] = { ...markers[index], preventive: value };
		this.setState((prevState) => ({
			workActivityDetail: {
				...prevState.workActivityDetail,
				workResult: markers
			}
		}));
	}
	handleEditPotential(value, index) {
		let markers = [ ...this.state.workActivityDetail.workResult ];
		markers[index] = { ...markers[index], potensial: value };
		this.setState((prevState) => ({
			workActivityDetail: {
				...prevState.workActivityDetail,
				workResult: markers
			}
		}));
	}

	async handleRefresh() {
		try {
			const user = getObject('user');

			this.setState({
				isActiveLoadingOverlay: true,
				workActivityDetail: null
			});

			await axios({
				url: `${config.api.analysiscreator}/work_activity/${this.work_activity_id}`,
				method: 'get',
				headers: { 'x-access-token': user.token }
			}).then((response) => {
				const data = response.data.data;

				// if (data.workResult.length === 0) {
				// 	console.log(data.workResult);
				// 	let newlist = [
				// 		{
				// 			potensial: '',
				// 			preventive: ''
				// 		}
				// 	];
				// 	data.workResult = newlist;

				// 	// this.setState({});
				// }

				this.setState({
					workActivityDetail: data
				});
			});
		} catch (e) {
		} finally {
			this.setState({
				isActiveLoadingOverlay: false
			});
		}
	}

	render() {
		return (
			<div>
				{this.state.showDeleteModal ? (
					<div className="d-flex justify-content-center  align-items-center">
						<div className="border rounded p-5">
							<DeleteModal
								closeModal={this.handleCloseDeleteModal}
								id={this.state.id_work_result}
								refreshTable={this.handleDeleteListModal}
								setSuccessMessage={this.setSuccessMessage}
								setFailedMessage={this.setFailedMessage}
								deleteUrl={`${config.api.analysiscreator}/work_result`}
							/>
						</div>
					</div>
				) : (
					<BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
						<div className=" row">
							<div className="col-md-6 mx-auto align-self-center">
								<h3 className="">Edit Task</h3>
							</div>

							<div className="col-md-6 mx-auto">
								<button
									type="button"
									className=" float-right clickable close-icon close"
									onClick={this.handleBack}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						</div>
						<br />
						<br />
						{this.state.workActivityDetail != null ? (
							<form>
								<div className="form-group row col-12">
									<div className="col-8">
										<label>Task/Job Steps</label>
										<textarea
											class="form-control"
											id="exampleFormControlTextarea1"
											maxLength={10000}
											rows="5"
											// defaultValue={this.state.workActivityDetail.task}
											value={this.state.workActivityDetail.task}
											// onKeyDown={(event) => (event.key === 'Enter' ? this.getSimilarity(event) : null)}
											onChange={(event) => this.handleTask(event.target.value)}
										/>
										<div className="row col-12 justify-content-between">
											<p className="text-muted float-left">
												Press enter to load data potential hazard
											</p>
											<p className="text-muted float-right">
												{this.state.workActivityDetail ? (
													this.state.workActivityDetail.task.length
												) : (
													0
												)}{' '}
												of 10.000 Character remaining
											</p>
										</div>
									</div>
									<div className="col-4">
										<label>Task/Job Steps Image</label>
										<div className="border rounded">
											<ReactDropzone
												className="dragAndDropArea d-flex align-items-center justify-content-center "
												style={{ height: 150 }}
												onDrop={this.onDrop}
												accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
												multiple={false}
												accept="image/*"
											>
												{/* <img className="" src={imgDrop} style={{ height: 50, width: 50 }} /> */}

												{!this.state.workActivityDetail.image ? (
													<div>
														<label className="mx-auto text-muted">
															Drag a photo here or click to upload.
														</label>
													</div>
												) : isBase64(this.state.workActivityDetail.image, {
													allowMime: true
												}) ? (
													<img
														className="profile-panel DragnDropDP"
														key={this.state.workActivityDetail.image}
														src={this.state.workActivityDetail.image}
														style={{ height: 150 }}
													/>
												) : (
													<img
														src={`${baseUrl}/${this.state.workActivityDetail.image}`}
														style={{ height: 150 }}
														className="img-fluid"
														alt="Responsive image"
													/>
												)}
											</ReactDropzone>
										</div>
									</div>
									<br />
									<br />

									<div className="col-12">
										<button
											className="btn"
											type="button"
											disabled={!this.state.workActivityDetail.task}
											style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
											onClick={this.createWorkActivity}
										>
											Save
										</button>
									</div>
								</div>
							</form>
						) : null}

						{this.state.workActivityDetail != null ? (
							<div className="col-12">
								<table className="table table-bordered-work text-center">
									<thead>
										<tr className="row ">
											<th className="col-sm-5 whitebg">Potential Hazard</th>
											<th className="col-sm-5 whitebg">Preventive/Countermeasures</th>
											<th className="col-sm-2 whitebg">Action</th>
										</tr>
									</thead>
									<tbody>
										{this.state.workActivityDetail ? (
											this.state.workActivityDetail.workResult.map((v, index) => (
												<tr className="row table-active">
													<td className="col-sm-5">
														<LimitedTextarea
															limit={10000}
															required
															defaultValue={v.potensial}
															rows="1"
															onChange={(event) =>
																this.handleEditPotential(event.text, index)}
														/>

														{v.message ? v.message === 'Potential hazard required' ? (
															<label className=" text-danger ">{v.message}</label>
														) : null : null}
													</td>
													<td className="col-sm-5 justify-content-center">
														<LimitedTextarea
															limit={10000}
															required
															defaultValue={v.preventive}
															rows="1"
															onChange={(event) =>
																this.handleEditPreventive(event.text, index)}
														/>

														{v.message ? v.message === 'Preventive required' ? (
															<label className="mx-auto text-danger  ">{v.message}</label>
														) : null : null}

														<div className="border rounded whitebg mt-5">
															<ReactDropzone
																className="dragAndDropArea d-flex align-items-center justify-content-center "
																style={{ height: 100, width: '100%' }}
																onDrop={(file) => this.onDropPreventive(file, index)}
																accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
																multiple={false}
																accept="image/*"
															>
																{/* <img className="" src={imgDrop} style={{ height: 50, width: 50 }} /> */}

																{!v.image ? (
																	<div>
																		<label className="mx-auto text-muted">
																			Drag a photo here or click to upload.
																		</label>
																	</div>
																) : isBase64(v.image, { allowMime: true }) ? (
																	<img
																		className="profile-panel DragnDropDP"
																		key={v.image}
																		src={v.image}
																		style={{ height: 90 }}
																	/>
																) : (
																	<img
																		src={`${baseUrl}/${v.image}`}
																		style={{ height: 90 }}
																		className="img-fluid"
																		alt="Responsive image"
																	/>
																)}
															</ReactDropzone>
														</div>
														{v.message ? v.message === 'Image required' ? (
															<label className="mx-auto text-danger">{v.message}</label>
														) : null : null}
													</td>
													<td className="col-sm-2">
														<button
															type="button"
															className="btn col-12 mb-3"
															disabled={
																!this.state.workActivityDetail.workResult.every(
																	(v) => v.potensial && v.preventive
																)
															}
															onClick={() =>
																this.handleSavePotentialPreventive(v.id, index)}
														>
															<div
																className=" "
																style={{ marginRight: '5px', marginLeft: '5px' }}
															>
																<span className="fefont"> Save</span>
															</div>
														</button>
														{this.state.workActivityDetail.workResult.length !== 1 ? (
															<button
																type="button"
																className="btn col-12"
																onClick={() => this.handleOpenDeleteModel(v.id)}
															>
																<div
																	className=" "
																	style={{ marginRight: '5px', marginLeft: '5px' }}
																>
																	<span
																		className="fe fe-trash"
																		style={{ marginRight: '2px' }}
																	/>
																	<span className="fefont"> Delete</span>
																</div>
															</button>
														) : null}
													</td>
												</tr>
											))
										) : (
											<tr className="row table-active">
												<td className="col-sm-6">-</td>
												<td className="col-sm-6">-</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						) : null}
					</BlockUi>
				)}
			</div>
		);
	}
}

export default WorkActivityEdit;
