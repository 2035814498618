import React, { Component } from 'react';
import ReactTable from 'react-table';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom';

import axios from 'axios';
import { convertToStd } from '../utils/date';
import { setObject, getObject } from '../utils/localstorage';
import config from '../config';
import * as R from 'ramda';
import ReactModal from 'react-modal';
import DeleteModal from '../components/DeleteModal';

import MasterAnalysisCreatorCreate from '../components/MasterAnalysisCreatorCreate';
import MasterAnalysisCreatorEdit from '../components/MasterAnalysisCreatorEdit';

import Select, { StylesConfig } from 'react-select';

class MasterAnalysisCreatorTemplate extends Component {
	constructor(props) {
		super(props);

		this.filters = [ { id: 'task', value: '' }, { id: 'company', value: '' } ];

		setObject('lastRoute', 'masteranalysiscreator');
		this.successMessage = '';
		this.state = {
			isShowingCreateAnalysisCreator: true,
			showFiltering: false,
			showAddModal: false,
			showEditModal: false,
			showDeleteModal: false,
			deleteId: 0,
			editId: 0,
			showFiltering: false,
			dataCompanys: []
		};

		this.page = 0;
		this.pageSize = 10;
		this.handleDelete = this.handleDelete.bind(this);
		this.handleFilterInput = this.handleFilterInput.bind(this);
		this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.clearMessage = this.clearMessage.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.hideNavBar = props.hideNavBar;
		this.handleCloseAddModal = this.handleCloseAddModal.bind(this);
		this.setFailedMessage = this.setFailedMessage.bind(this);
		this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);

		this.handleEdit = this.handleEdit.bind(this);
		this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
		// this.handleView = this.handleView.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.getcompany = this.getcompany.bind(this);
		this.handleChangeCompany = this.handleChangeCompany.bind(this);

		const userObj = getObject('user');
		this.userObj = userObj;

		if (userObj) {
			this.user = userObj.user;
			this.userType = this.user.type;
			console.log(userObj.user);
		} else {
			this.props.history.push('/login');
		}
	}

	handleFilterInput() {
		this.setState((state) => ({
			showFiltering: !state.showFiltering
		}));
	}

	handleAdd() {
		this.hideNavBar(true);
		this.setState({
			showAddModal: true
		});
	}

	handleCloseFilterInput() {
		this.filters = [ { id: 'task', value: '' }, { id: 'company', value: '' } ];
		this.setState((state) => ({
			showFiltering: !state.showFiltering
		}));
		this.refreshTable({
			page: this.page,
			pageSize: this.pageSize
		});
	}

	handleCloseAddModal() {
		this.hideNavBar(false);
		this.setState({
			showAddModal: false
		});
	}

	clearMessage() {
		this.setState({
			successMessage: '',
			failedMessage: ''
		});
	}

	fetchData(state, instance) {
		this.clearMessage();
		this.setState({ loading: true });

		this.refreshTable(state);
		this.getcompany();
	}

	setFailedMessage(failedMessage) {
		this.setState({
			failedMessage,
			successMessage: ''
		});
	}

	handleDelete(deleteId) {
		this.setState({
			showDeleteModal: true,
			deleteId
		});
	}

	handleCloseDeleteModal() {
		this.setState({
			showDeleteModal: false
		});
	}

	handleEdit(editId) {
		this.hideNavBar(true);
		this.setState({
			showEditModal: true,
			editId
		});
	}

	handleCloseEditModal(id) {
		this.hideNavBar(false);
		this.setState({
			showEditModal: false
		});
	}

	handleSearch(idx, value) {
		this.filters[idx].value = value;

		this.refreshTable({
			page: this.page,
			pageSize: this.pageSize
		});
	}

	refreshTable(state) {
		// const user = getObject('user');
		const size = state.pageSize;
		const page = parseInt(state.page) + 1;

		this.page = parseInt(state.page);
		this.pageSize = state.pageSize;

		const filtered = this.filters;

		let filterString = '';
		const filterXs = R.pipe(R.filter((x) => x.value !== ''), R.map((x) => `${x.id}=${x.value}`))(filtered);

		filterString = filterXs.join('&');

		// const sortedXs = R.map((x) => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`, state.sorted || []);
		// let sortedString = '&orderBy=createdDate&orderDirection=desc';

		// if (sortedXs.length > 0) {
		// 	sortedString = sortedXs.join('&');
		// }

		axios({
			url: `${config.api.masteranalysis}?size=${size}&page=${page}&${filterString}`,
			method: 'get',
			headers: { 'x-access-token': this.userObj.token }
		})
			.then((response) => {
				const data = response.data.data;
				console.log(data);
				this.setState({
					data: data.results,
					pages: data.totalPage,
					loading: false
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleChangeCompany(value) {
		if (value) {
			this.handleSearch(1, value.label);
		} else {
			this.handleSearch(1, '');
		}
	}

	getcompany() {
		axios({
			url: `${config.api.company}`,
			method: 'get',
			headers: { 'x-access-token': this.userObj.token }
		})
			.then((response) => {
				const data = response.data.data;
				this.setState({
					dataCompanys: R.map(
						(x) => ({
							value: x.id,
							label: x.company
						}),
						data.results
					)
				});
			})
			.catch((err) => {
				const statusCode = err.response.status;
				if (statusCode == 403) {
					// this.setState({
					//   isForbidden: true
					// });
				}
			});
	}

	render() {
		const columns = [
			{
				Header: 'Action',
				accessor: 'id',
				// id: "inspection",
				width: 150,
				sortable: false,
				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					if (this.userType === 'SUPER_ADMIN') {
						return (
							<div className="row">
								<div className="col-md-auto">
									<button
										type="button"
										className="btn btn-sm"
										onClick={() => this.handleEdit(cellInfo.value)}
									>
										Edit
									</button>
								</div>

								<div className="col-md-auto">
									<button
										type="button"
										className="btn btn-sm"
										onClick={() => this.handleDelete(cellInfo.value)}
									>
										Delete
									</button>
								</div>
							</div>
						);
					} else {
						if (dataAnalysis.type !== 'Super Admin') {
							return (
								<div className="row">
									<div className="col-md-auto">
										<button
											type="button"
											className="btn btn-sm"
											onClick={() => this.handleEdit(cellInfo.value)}
										>
											Edit
										</button>
									</div>

									{this.userType === 'ADMIN' ? (
										<div className="col-md-auto">
											<button
												type="button"
												className="btn btn-sm"
												onClick={() => this.handleDelete(cellInfo.value)}
											>
												Delete
											</button>
										</div>
									) : this.userType === 'ADMIN_LOCATION' && this.user.id === dataAnalysis.adminId ? (
										<div className="col-md-auto">
											<button
												type="button"
												className="btn btn-sm"
												onClick={() => this.handleDelete(cellInfo.value)}
											>
												Delete
											</button>
										</div>
									) : null}
								</div>
							);
						} else {
							return <div />;
						}
					}
				}
			},

			...(this.userType === 'SUPER_ADMIN'
				? [
						{
							Header: 'Company',
							accessor: 'company',
							// id: "inspection",
							width: 150,
							sortable: false,
							Cell: (cellInfo) => {
								const dataAnalysis = cellInfo.original;

								return (
									<div className="col-md-auto">
										<p>{cellInfo.value} </p>
									</div>
								);
							}
						}
					]
				: []),

			{
				Header: 'Task/Job',
				accessor: 'task',
				Cell: (cellInfo) => {
					// const dataAnalysis = cellInfo.original;
					return (
						<div>
							{cellInfo.value.map((v) => (
								<div className="col-md-auto">
									<p>{v.taskName}, </p>
								</div>
							))}
						</div>
					);
				}
			},
			{
				Header: 'Potential Hazard',
				accessor: 'potential',
				Cell: (cellInfo) => {
					// const dataAnalysis = cellInfo.original;
					return (
						<div>
							{cellInfo.value.map((v) => (
								<div className="col-md-auto">
									<p>{v.potential} </p>
								</div>
							))}
						</div>
					);
				}
			},
			{
				Header: 'Preventive/Countermeasures',
				accessor: 'potential',
				Cell: (cellInfo) => {
					// const dataAnalysis = cellInfo.original;
					// console.log('Masuk inspection = ', dataAnalysis);
					return (
						<div>
							{cellInfo.value.map((v) => (
								<div className="col-md-auto">
									<p>{v.preventive} </p>
								</div>
							))}
						</div>
					);
				}
			},

			{
				Header: 'Created date',
				accessor: 'createdDate',
				Cell: (cellInfo) => {
					const date = convertToStd(cellInfo.value);
					if (date == 'Invalid date') {
						return '';
					}
					return date;
				}
			},
			{
				Header: 'Update date',
				accessor: 'updatedDate',
				Cell: (cellInfo) => {
					const date = convertToStd(cellInfo.value);
					if (date == 'Invalid date') {
						return '';
					}
					return date;
				}
			}
			// {
			// 	Header: 'Update date',
			// 	accessor: 'task',
			// 	Cell: (cellInfo) => {
			// 		const dateTask = cellInfo.value[0].updatedDate;
			// 		const date = convertToStd(dateTask);
			// 		if (date == 'Invalid date') {
			// 			return '';
			// 		}
			// 		return date;
			// 	}
			// }
		];

		const CustomStyle = {
			control: (base, state) => ({
				...base,
				border: '1px solid #C2C8BF',
				boxShadow: 'none',
				'&:hover': {
					border: '1px solid #C2C8BF'
				}
			}),

			option: (base, state) => ({
				...base,
				backgroundColor: state.isSelected ? ' #F5F5F5' : '#fff',
				color: '#121212',
				':hover': {
					...base[':hover'],
					backgroundColor: '#F5F5F5'
				}
			})
		};

		const { data, pages, loading, loadingCreate } = this.state;
		var modalStyles = { overlay: { zIndex: 10 } };
		return (
			<div>
				<br />
				{this.state.failedMessage ? (
					<div className="alert alert-danger" role="alert">
						{this.state.failedMessage}
					</div>
				) : null}
				<hr />
				<h1>Analysis</h1>
				<hr />
				<div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
					<div className="col-md-6">
						{this.state.isShowingCreateAnalysisCreator ? (
							<button type="button" className="btn" onClick={this.handleAdd}>
								<div className="row " style={{ marginRight: '5px', marginLeft: '5px' }}>
									<span class=" fe fe-plus" style={{ marginRight: '2px' }} />
									<span class="fefont"> New Analysis</span>
								</div>
							</button>
						) : null}
					</div>
					{!this.state.showFiltering ? (
						<div className="col-md-6">
							<button type="button" className="btn float-right" onClick={this.handleFilterInput}>
								<span class="fe fe-search" />
							</button>
						</div>
					) : null}
				</div>
				{this.state.showFiltering ? (
					<div>
						<div className="card">
							<div className="pull-right" style={{ marginRight: '10px' }}>
								<button
									type="button"
									className="pull-right clickable close-icon close"
									onClick={this.handleCloseFilterInput}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-auto">
										<label>
											<b>Task</b>
										</label>
										<input
											className="form-control"
											onChange={(e) => this.handleSearch(0, e.target.value)}
										/>
									</div>

									{this.userType === 'SUPER_ADMIN' ? (
										<div className="col-md-auto w-25">
											<label>
												<b>Company</b>
											</label>

											<Select
												styles={CustomStyle}
												isClearable={true}
												onChange={this.handleChangeCompany}
												options={this.state.dataCompanys}
											/>
											{/* <input
											className="form-control"
											onChange={(e) => this.handleSearch(2, e.target.value)}
										/> */}
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
				) : null}
				<ReactModal isOpen={this.state.showAddModal} contentLabel="Minimal Modal Example" style={modalStyles}>
					<MasterAnalysisCreatorCreate
						closeModal={this.handleCloseAddModal}
						refreshTable={this.fetchData}
						tablePaging={{
							page: this.page,
							pageSize: this.pageSize
						}}
						setFailedMessage={this.setFailedMessage}
						setSuccessMessage={this.setSuccessMessage}
					/>
				</ReactModal>

				<ReactModal
					isOpen={this.state.showDeleteModal}
					contentLabel="Minimal Modal Example"
					style={{
						content: {
							top: '50%',
							left: '50%',
							right: 'auto',
							bottom: 'auto',
							marginRight: '-50%',
							transform: 'translate(-50%, -50%)'
						}
					}}
				>
					<DeleteModal
						closeModal={this.handleCloseDeleteModal}
						id={this.state.deleteId}
						refreshTable={this.fetchData}
						setSuccessMessage={this.setSuccessMessage}
						setFailedMessage={this.setFailedMessage}
						deleteUrl={config.api.masteranalysis}
					/>
				</ReactModal>
				<ReactModal isOpen={this.state.showEditModal} contentLabel="Minimal Modal Example">
					<MasterAnalysisCreatorEdit
						closeModal={this.handleCloseEditModal}
						id={this.state.editId}
						refreshTable={this.fetchData}
						setSuccessMessage={this.setSuccessMessage}
						setFailedMessage={this.setFailedMessage}
						tablePaging={{
							page: this.page,
							pageSize: this.pageSize
						}}
					/>
				</ReactModal>
				<ReactTable
					columns={columns}
					manual
					data={data}
					pages={pages}
					loading={loading}
					onFetchData={this.fetchData}
					filterable={false}
					defaultPageSize={10}
					className="-striped -highlight"
				/>
				<br />
			</div>
		);
	}
}

export default withRouter(MasterAnalysisCreatorTemplate);
