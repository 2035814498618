import React, { Component } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import baseUrl from '../url';
import { convertToStd } from '../utils/date';
import { EmailShareButton, EmailIcon } from 'react-share';
import BlockUi from 'react-block-ui';
import axios from 'axios';
import config from '../config';
import { setObject, getObject } from '../utils/localstorage';
import validator from 'validator';

export default class AnalysisCreatorTemplatePdf extends Component {
	constructor(props) {
		super(props);
		this.state = {
			setBack: false,
			email: null,
			setMessage: '',
			isEmail: false
		};
		this.closeModal = props.closeModal;
		this.detailData = props.detailData;
		this.workActivity = props.workActivity;
		this.onGeneratePdf = this.onGeneratePdf.bind(this);
		this.onSharePdf = this.onSharePdf.bind(this);
	}

	onSharePdf() {
		if (validator.isEmail(this.state.email)) {
			const user = getObject('user');
			this.setState({
				isActiveLoadingOverlay: true
			});
			var htmlElement = document.getElementById('doc-target');

			html2canvas(htmlElement, { useCORS: true, allowTaint: true, scrollY: 0 }).then((canvas) => {
				const image = { type: 'jpeg', quality: 0.98 };
				const margin = [ 0.5, 0.5 ];

				var imgWidth = 11;
				var pageHeight = 8.5;
				// var imgWidth = 8.5;
				// var pageHeight = 11;

				var innerPageWidth = imgWidth - margin[0] * 2;
				var innerPageHeight = pageHeight - margin[1] * 2;

				// Calculate the number of pages.
				var pxFullHeight = canvas.height;
				var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
				var nPages = Math.ceil(pxFullHeight / pxPageHeight);

				// Define pageHeight separately so it can be trimmed on the final page.
				var pageHeight = innerPageHeight;

				// Create a one-page canvas to split up the full image.
				var pageCanvas = document.createElement('canvas');
				var pageCtx = pageCanvas.getContext('2d');
				pageCanvas.width = canvas.width;
				pageCanvas.height = pxPageHeight;

				// Initialize the PDF.
				// var pdf = new jsPDF('p', 'in', [ 8.5, 11 ]);
				var pdf = new jsPDF('l', 'in', [ 11, 8.5 ]);

				for (var page = 0; page < nPages; page++) {
					// Trim the final page to reduce file size.
					if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
						pageCanvas.height = pxFullHeight % pxPageHeight;
						pageHeight = pageCanvas.height * innerPageWidth / pageCanvas.width;
					}

					// Display the page.
					var w = pageCanvas.width;
					var h = pageCanvas.height;
					pageCtx.fillStyle = 'white';
					pageCtx.fillRect(0, 0, w, h);
					pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

					// Add the page to the PDF.
					if (page > 0) pdf.addPage();
					// debugger;
					var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
					pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
				}

				const pdfSend = new File(
						[ pdf.output('blob') ],
						`${this.detailData ? this.detailData.project_name : '-'}.pdf`,
						{
							type: pdf.output('blob').type,
							lastModified: new Date()
						}
					),
					data = new FormData();

				data.append('email', `${this.state.email}`);
				data.append('subject', `${this.detailData ? this.detailData.project_name : '-'}`);
				data.append('content', `${this.detailData ? this.detailData.description : '-'}`);
				data.append('file', pdfSend);

				axios({
					url: `${config.api.analysiscreator}/sendemail`,
					method: 'post',
					data: data,
					headers: { 'x-access-token': user.token }
				})
					.then((response) => {
						this.closeModal();
						this.setState({
							isActiveLoadingOverlay: false
						});
					})
					.catch((err) => {
						this.setState({
							isActiveLoadingOverlay: false
						});
					});
			});
		}
	}

	onGeneratePdf() {
		this.setState({
			isActiveLoadingOverlay: true
		});
		var htmlElement = document.getElementById('doc-target');

		html2canvas(htmlElement, { useCORS: true, allowTaint: true, scrollY: 0 }).then((canvas) => {
			const image = { type: 'jpeg', quality: 0.98 };
			const margin = [ 0.5, 0.5 ];

			var imgWidth = 11;
			var pageHeight = 8.5;
			// var imgWidth = 8.5;
			// var pageHeight = 11;

			var innerPageWidth = imgWidth - margin[0] * 2;
			var innerPageHeight = pageHeight - margin[1] * 2;

			// Calculate the number of pages.
			var pxFullHeight = canvas.height;
			var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
			var nPages = Math.ceil(pxFullHeight / pxPageHeight);

			// Define pageHeight separately so it can be trimmed on the final page.
			var pageHeight = innerPageHeight;

			// Create a one-page canvas to split up the full image.
			var pageCanvas = document.createElement('canvas');
			var pageCtx = pageCanvas.getContext('2d');
			pageCanvas.width = canvas.width;
			pageCanvas.height = pxPageHeight;

			// Initialize the PDF.
			var pdf = new jsPDF('l', 'in', [ 11, 8.5 ]);
			// var pdf = new jsPDF('p', 'in', [ 8.5, 11 ]);

			for (var page = 0; page < nPages; page++) {
				// Trim the final page to reduce file size.
				if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
					pageCanvas.height = pxFullHeight % pxPageHeight;
					pageHeight = pageCanvas.height * innerPageWidth / pageCanvas.width;
				}

				// Display the page.
				var w = pageCanvas.width;
				var h = pageCanvas.height;
				pageCtx.fillStyle = 'white';
				pageCtx.fillRect(0, 0, w, h);
				pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

				// Add the page to the PDF.
				if (page > 0) pdf.addPage();
				// debugger;
				var imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
				pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
			}

			pdf.save(`${this.detailData ? this.detailData.project_name : '-'}`);

			this.setState({
				isActiveLoadingOverlay: false
			});
		});
	}

	render() {
		const styles = {
			page: {
				flexDirection: 'row',
				backgroundColor: '#E4E4E4'
			},
			section: {
				margin: 10,
				padding: 10,
				flexGrow: 1
			}
		};
		console.log(this.detailData);
		return (
			<BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
				<div class="container">
					<div className=" col-12 justify-content-right">
						<button
							type="button"
							className=" float-right clickable close-icon close"
							onClick={() => {
								this.closeModal();
							}}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<br />
					<div>
						<div id="doc-target" className="container">
							<div class="col-12">
								{/* <img src={mainLogo} width={300} height={220} /> */}

								{/* <img className="img-fluid" alt="Safeport Analysis" src={mainLogo} style={{ height: 150 }} /> */}
								{this.detailData ? this.detailData.logo ? (
									<img
										className="img-fluid"
										alt="Responsive image"
										key={this.detailData.logo}
										src={`${baseUrl}/${this.detailData.logo}`}
										style={{ height: 150 }}
									/>
								) : (
									<p>-</p>
								) : (
									<div>
										<label className="mx-auto text-muted"> - </label>
									</div>
								)}
							</div>
							<div className="row col-12 justify-content-center">
								<label className="">
									<b>
										<h2>Job Hazard Analysis</h2>
									</b>
								</label>{' '}
							</div>

							<table class="table table-bordered">
								<tbody>
									<tr>
										<td colSpan="2" className="col-8">
											<h2 className="">
												{' '}
												<p> Project/Operation Title: </p>{' '}
												{this.detailData ? (
													<p>{this.detailData.project_name}</p>
												) : (
													<p>-</p>
												)}{' '}
											</h2>
											<b>
												<p> Description of Work: </p>
											</b>
											{this.detailData ? <p>{this.detailData.description}</p> : <p>-</p>}
										</td>
										<td className="col-2">Project #</td>
										<td className="col-2">
											<p>
												<b>Submitted: </b>
											</p>
											<p>
												<b>Revised: </b>
											</p>
										</td>
									</tr>

									<tr>
										<td colSpan="2" className="col-8">
											<h2 className="">
												{' '}
												<p>Location : </p>{' '}
												{this.detailData ? <p> {this.detailData.location}</p> : <p>-</p>}{' '}
											</h2>
											<p> Column(s): </p>{' '}
											{this.detailData ? <p>{this.detailData.column}</p> : <p>-</p>}
											<p> Line Name(s): </p>{' '}
											{this.detailData ? <p>{this.detailData.line_names}</p> : <p>-</p>}
										</td>

										<td colSpan="2" className="col-2">
											<h2>
												<u>Contact Information</u>
											</h2>
											<div className="col-12">
												<label>
													<b>Foreman/Supervisor Name</b>
												</label>
												{this.detailData ? <p>{this.detailData.foreman}</p> : <p>-</p>}
											</div>

											<div className="col-12">
												<label>
													<b>Safety Representative</b>
												</label>
												{this.detailData ? (
													<p>{this.detailData.safety_representative}</p>
												) : (
													<p>-</p>
												)}
											</div>

											<div className="col-12">
												<label>
													<b>Site Supervisor</b>
												</label>
												{this.detailData ? <p>{this.detailData.site_supervisor}</p> : <p>-</p>}
											</div>

											<div className="col-12">
												<label>
													<b>Site Safety Representative</b>
												</label>
												{this.detailData ? (
													<p>{this.detailData.site_safety_supervisor}</p>
												) : (
													<p>-</p>
												)}
											</div>
										</td>
									</tr>

									<tr>
										<td colSpan="2" className="col-8">
											<h2 className="text-danger">
												{' '}
												<p>
													<u> Required Personal Protective Equipment</u>
												</p>{' '}
											</h2>

											{this.detailData ? (
												<ul>
													{this.detailData.list_equipment.split(',').map((v) => <li>{v}</li>)}
												</ul>
											) : (
												<p>-</p>
											)}
										</td>
										<td className="col-2">
											<h2 className="">
												{' '}
												<b>
													<u>Estimate Work Dates</u>
												</b>{' '}
											</h2>

											<b>
												<p>Begin: </p>
												{this.detailData ? (
													<p>{convertToStd(this.detailData.estimate_start_date)}</p>
												) : (
													<p>-</p>
												)}
											</b>

											<b>
												<p>End: </p>
												{this.detailData ? (
													<p>{convertToStd(this.detailData.estimate_end_date)}</p>
												) : (
													<p>-</p>
												)}{' '}
											</b>
										</td>
										<td className="col-2">
											<h2 className="">
												{' '}
												<b>
													<u>Work Schedule</u>
												</b>{' '}
											</h2>

											<b>
												<p>Begin: </p>
												{this.detailData ? (
													<p>{convertToStd(this.detailData.schedule_start_date)}</p>
												) : (
													<p>-</p>
												)}
											</b>

											<b>
												<p>End: </p>
												{this.detailData ? (
													<p>{convertToStd(this.detailData.schedule_end_date)}</p>
												) : (
													<p>-</p>
												)}{' '}
											</b>
										</td>
									</tr>

									<tr>
										<td colSpan="2" className="col-8">
											<h3 className="">
												{' '}
												<p>Work Operation</p>{' '}
											</h3>

											{this.detailData ? this.detailData.workOperation.length !== 0 ? (
												<ul className="list-group">
													{this.detailData.workOperation.map((v, index) => (
														<li>{v.description}</li>
													))}
												</ul>
											) : (
												<p>-</p>
											) : (
												<p>-</p>
											)}
											<br />
											<p className="text-danger">
												<b>
													Remember if there is any doubt or changes to the scop of work, STOP,
													CALL, WAIT
												</b>
											</p>
										</td>
										<td className="col-2">
											<h2 className="">
												{' '}
												<b>
													<u>Meetings</u>
												</b>{' '}
											</h2>
										</td>
										<td className="col-2">
											<b>Client:</b>
										</td>
									</tr>
									<tr>
										<td className="col-2">
											<h3 className="">
												{' '}
												<b>Work Activity</b>{' '}
											</h3>
										</td>
										<td className="col-2">
											<h3 className="">
												{' '}
												<b>Potential Hazard</b>{' '}
											</h3>
										</td>
										<td colSpan="2" className="col-8">
											<h3 className="">
												{' '}
												<b>Preventive or Corrective Measures</b>{' '}
											</h3>
										</td>
									</tr>

									{this.workActivity ? this.workActivity.length !== 0 ? (
										this.workActivity.map((v, index) => (
											<tr>
												<td className="col-2">
													<div className="col-md-auto">
														<div className="col-md-auto">
															<label>{v.task}</label>
														</div>
														{v.image ? (
															<div className="col-md-auto">
																<img
																	src={`${baseUrl}/${v.image}`}
																	width="200"
																	height="140"
																	class="img-fluid"
																	alt="Responsive image"
																/>
															</div>
														) : null}
													</div>
												</td>
												<td className="col-2">
													<div>
														{v.workResult.map((v, index) => (
															<div className="col-md-auto">
																<p>{v.potensial} </p>
															</div>
														))}
													</div>
												</td>
												<td colSpan="2" className="col-8">
													<div>
														{v.workResult.map((v, index) => (
															<div className="col-md-auto">
																<div className="col-md-auto">
																	<p>{v.preventive} </p>
																</div>
																{v.image ? (
																	<div className="col-md-auto">
																		<img
																			src={`${baseUrl}/${v.image}`}
																			width="200"
																			height="140"
																			className="img-fluid"
																			alt="Responsive image"
																		/>
																	</div>
																) : null}
															</div>
														))}
													</div>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td className="col-2">
												<p>-</p>
											</td>
											<td className="col-2">
												<p>-</p>
											</td>
											<td colSpan="2" className="col-8">
												<p>-,</p>
											</td>
										</tr>
									) : (
										<tr>
											<td className="col-2">
												<p>-</p>
											</td>
											<td className="col-2">
												<p>-</p>
											</td>
											<td colSpan="2" className="col-8">
												<p>-</p>
											</td>
										</tr>
									)}

									{/* <tr>
									<td className="col-2">
										<p>-</p>
									</td>
									<td className="col-2">
										<p>-</p>
									</td>
									<td colSpan="2" className="col-8">
										<p>-,</p>
									</td>
								</tr> */}
								</tbody>
							</table>
							<br />
							<div className="container">
								<div className="row col-12 justify-content-center">
									<p>
										<b>Training Requirement</b>
									</p>
								</div>
								<div className="row col-12 justify-content-center">
									<p>
										All employees will be required to make themselves familliar with the content of
										this hazard assessment
									</p>
								</div>
								<div className="row col-12 justify-content-center">
									<h3>Date: ______________/________________/______________</h3>
								</div>
								<div className="  col-12">
									<table class="table table-bordered text-center">
										<thead>
											<tr>
												<th className="col-sm-3 whitebg text-dark">PRINT</th>
												<th className="col-sm-3 whitebg text-dark">SIGN</th>
												<th className="col-sm-3 whitebg text-dark">PRINT</th>
												<th className="col-sm-3 whitebg text-dark">SIGN</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						{this.state.setBack ? (
							<div class="container mt-5 mb-5 animate__animated animate__backInUp" data-wow-delay="0.3s">
								<div className="row justify-content-center">
									<div className="col-4 ">
										<div class="input-group mb-3">
											<input
												type="text"
												class="form-control"
												placeholder="Input Email"
												aria-label="Recipient's username"
												aria-describedby="button-addon2"
												value={this.state.email}
												onChange={(event) => {
													const email = event.target.value;

													if (validator.isEmail(email)) {
														this.setState({
															email: event.target.value,
															setMessage: '',
															isEmail: true
														});
													} else {
														this.setState({
															email: event.target.value,
															isEmail: false,
															setMessage: 'Please, enter valid Email!'
														});
													}
												}}
											/>
											<div class="input-group-append">
												<button
													onClick={this.onSharePdf}
													class="btn btn-outline-secondary"
													type="button"
													disabled={!this.state.isEmail}
													id="button-addon2"
												>
													Send
												</button>
											</div>
										</div>

										<span
											style={{
												color: 'red'
											}}
										>
											{this.state.setMessage}
										</span>
									</div>
								</div>
							</div>
						) : null}
						<div className="row col-12 justify-content-center">
							<button
								className="btn btn-primary mr-3"
								style={{}}
								onClick={() => {
									this.state.setBack
										? this.closeModal()
										: this.setState({
												setBack: true
											});
								}}
							>
								<div className="row " style={{ marginRight: '5px', marginLeft: '5px' }}>
									{this.state.setBack ? (
										<span className="fe fe-arrow-left mr-2" style={{ marginRight: '2px' }} />
									) : (
										<span className="fe fe-share mr-2" style={{ marginRight: '2px' }} />
									)}

									<span className="fefont"> {this.state.setBack ? 'Back' : 'Share Pdf'}</span>
								</div>
							</button>
							<button className="btn btn-primary mr-3" style={{}} onClick={this.onGeneratePdf}>
								<div className="row " style={{ marginRight: '5px', marginLeft: '5px' }}>
									<span className="fefont"> Generate Pdf</span>
									<span className=" fe fe-down" style={{ marginRight: '2px' }} />
								</div>
							</button>
						</div>
					</div>
				</div>
			</BlockUi>
		);
	}
}
