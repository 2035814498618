import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import config from '../config';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import Helmet from 'react-helmet';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import * as R from 'ramda';
import { formatDate, parseDate } from 'react-day-picker/moment';
import ReactTable from 'react-table';
import { convertToStd } from '../utils/date';
import ReportEmailModal from './ReportEmailModal';
import ReactModal from 'react-modal';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Select from 'react-select';

class PdfReport extends Component {
	constructor(props) {
		super(props);
		this.user = getObject('user');
		this.locationId = this.user.user.locationId;
		this.filters = [ { id: 'createdBy', value: '' } ];

		this.props.handleClickMenu('pdfreport');
		this.handleDateFilterShortcut = this.handleDateFilterShortcut.bind(this);
		this.generatePdf = this.generatePdf.bind(this);
		this.handleFromChange = this.handleFromChange.bind(this);
		this.handleToChange = this.handleToChange.bind(this);
		this.handleContractor = this.handleContractor.bind(this);
		this.handleMode = this.handleMode.bind(this);
		this.refreshTable = this.refreshTable.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.setReportState = this.setReportState.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleFilterInput = this.handleFilterInput.bind(this);
		this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
		this.loadContractorNames = this.loadContractorNames.bind(this);
		this.handleContractorName = this.handleContractorName.bind(this);
		this.handleChangeLocation = this.handleChangeLocation.bind(this);
		this.page = 0;
		this.pageSize = 10;

		this.state = {
			totalOpenItem: null,
			fromDate: null,
			toDate: null,
			from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
			to: moment().format('YYYY-MM-DD'),
			isFilterDateShowing: false,
			report: 'AUDIT_FINDING',
			isWaiting: false,
			mode: 'ADD_NEW',
			reportId: 0,
			contractorNames: [],
			contractorNameValue: {
				value: '',
				label: ''
			},
			contractorNameId: 0
		};

		setObject('lastRoute', '/pdfreport');
	}

	async componentDidMount() {
		let locations = [];
		let locationId = 0;
		let locationValue;
		if ((this.user && this.user.user.type === 'ADMIN') || this.user.user.type === 'SUPER_ADMIN') {
			const locationsResult = await axios({
				url: `${config.api.location}`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			locations = R.map(
				(location) => ({ value: location.id, label: location.location }),
				locationsResult.data.data.results
			);
			locationId = locations.length > 0 ? locations[0].value : 0;

			locationValue = locations.length > 0 ? { value: locations[0].value, label: locations[0].label } : {};

			console.log('Location value = ', locationValue);
		} else {
			locationId = this.user.user.locationId;
		}

		let contractorNames = [];
		if (locationId) {
			contractorNames = await this.loadContractorNames(locationId);
		}

		let contractorNameValue = null;
		let contractorNameId = 0;

		if (contractorNames.length > 0) {
			const contractorName = contractorNames[0];

			contractorNameValue = {
				value: contractorName.value,
				label: contractorName.label
			};
			contractorNameId = contractorName.value;
		}

		this.contractorNameValue = contractorNameValue;
		this.contractorNameId = contractorNameId;

		if (contractorNames.length > 0) {
			contractorNameValue.value = contractorNames[0].value;
			contractorNameValue.label = contractorNames[0].label;
		}

		this.setState({
			locations,
			locationValue,
			locationId,
			contractorNames,
			contractorNameValue,
			contractorNameId: contractorNameValue ? contractorNameValue.value : 0
		});
	}

	async handleChangeLocation({ value, label }) {
		const contractorNames = await this.loadContractorNames(value);
		let contractorNameValue = null;
		let contractorNameId;
		if (contractorNames.length > 0) {
			const contractorName = contractorNames[0];

			contractorNameValue = {
				value: contractorName.value,
				label: contractorName.label
			};

			contractorNameId = contractorName.value;
		}

		this.contractorNameId = contractorNameId;
		this.contractorNameValue = contractorNameValue;

		this.setState({
			locationId: value,
			locationValue: {
				value,
				label
			},
			contractorNames,
			contractorNameValue,
			contractorNameId
		});
	}

	async handleContractorName({ value, label }) {
		this.setState({
			contractorNameId: value,
			contractorNameValue: {
				value,
				label
			}
		});
	}

	handleSearch(idx, value) {
		this.filters[idx].value = value;
		this.refreshTable({
			page: this.page,
			pageSize: this.pageSize
		});
	}

	handleContractor(event) {
		const contractorId = event.target.value;
		this.setState({
			contractorId
		});
	}

	handleFilterInput() {
		this.setState((state) => ({
			showFiltering: !state.showFiltering
		}));
	}

	handleCloseFilterInput() {
		this.filters = [ { id: 'createdBy', value: '' } ];
		this.setState((state) => ({
			showFiltering: !state.showFiltering
		}));
		this.refreshTable({
			page: this.page,
			pageSize: this.pageSize
		});
	}

	handleMode(event) {
		const mode = event.target.value;
		if (mode === 'ADD_NEW') {
			this.setState({ mode, isFilterDateShowing: false });
		} else {
			this.setState({ loading: true });
			this.refreshTable(
				{
					page: this.page,
					pageSize: this.pageSize,
					isFilterDateShowing: false
				},
				mode
			);
		}
	}

	handleFromChange(from) {
		const momentFrom = moment(from);
		const str = momentFrom.format('YYYY-MM-DD');
		this.setState({ from: str, fromDate: from });
	}

	handleToChange(to) {
		const momentTo = moment(to);
		const str = momentTo.format('YYYY-MM-DD');
		this.setState({ to: str, toDate: to }, () => {
			this.showFromMonth();
		});
	}

	setReportState(state) {
		this.setState(state);
	}

	async handleDateFilterShortcut(data) {
		if (data === 'LAST_WEEK') {
			this.setState({
				isFilterDateShowing: false,
				from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
				to: moment().format('YYYY-MM-DD')
			});
		} else if (data === 'LAST_2_WEEK') {
			this.setState({
				isFilterDateShowing: false,
				from: moment().subtract(14, 'days').format('YYYY-MM-DD'),
				to: moment().format('YYYY-MM-DD')
			});
		} else if (data === 'LAST_30_DAYS') {
			this.setState({
				isFilterDateShowing: false,
				from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
				to: moment().format('YYYY-MM-DD')
			});
		} else if (data === 'CUSTOM') {
			this.setState({
				isFilterDateShowing: true,
				from: null,
				to: null
			});
		}
	}

	getRandomColor() {
		var letters = '0123456789ABCDEF';
		var color = '#';
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	}

	showFromMonth() {
		const { fromDate, toDate } = this.state;
		if (!fromDate) {
			return;
		}
		if (moment(toDate).diff(moment(fromDate), 'months') < 2) {
			this.toDate.getDayPicker().showMonth(fromDate);
		}
	}

	async generatePdf(e) {
		try {
			e.preventDefault();

			this.setState({
				isWaiting: true,
				isActiveLoadingOverlay: true
			});

			const data = {
				type: this.state.report,
				fromDate: this.state.from,
				toDate: this.state.to
			};

			console.log(data);

			if (this.state.contractorNameId) {
				data.contractorNameId = this.state.contractorNameId;
			}

			const pdf = await axios.post(`${config.api.openitems}/pdf`, data, {
				headers: { 'x-access-token': this.user.token }
			});

			this.setState({
				isWaiting: false,
				urlToDownload: pdf.data.data.pdfFullPath,
				pdfName: pdf.data.data.pdfName,
				successMessage: pdf.data.message,
				reportId: pdf.data.data.id
			});
		} catch (e) {
			this.setState({
				failedMessage: e.response ? e.response.data.message : 'error'
			});
		} finally {
			this.setState({
				isActiveLoadingOverlay: false,
				isWaiting: false
			});
		}
	}

	fetchData(state, instance) {
		this.setState({ loading: true });
		this.refreshTable(state);
	}

	async loadContractorNames(locationId) {
		const contractorNamesResult = await axios({
			url: `${config.api.contractorname}/${locationId}`,
			method: 'get',
			headers: { 'x-access-token': this.user.token }
		});

		const result = contractorNamesResult.data.data.results;

		const finalResult = R.map((x) => ({ value: x.id, label: x.contractorName }), result);
		return finalResult;
	}

	refreshTable(state, mode) {
		const user = getObject('user');
		const size = state.pageSize;
		const page = parseInt(state.page) + 1;

		this.page = parseInt(state.page);
		this.pageSize = state.pageSize;

		const filtered = this.filters;
		let filterString = '';
		const filterXs = R.pipe(R.filter((x) => x.value !== ''), R.map((x) => `${x.id}=${x.value}`))(filtered);

		filterString = filterXs.join('&');

		axios({
			url: `${config.api.openitems}/pdf/histories?type=${mode
				? mode
				: this.state.mode}&size=${size}&page=${page}&${filterString}`,
			method: 'get',
			headers: { 'x-access-token': user.token }
		})
			.then((response) => {
				const data = response.data.data;
				this.setState({
					data: data.results,
					pages: data.totalPage,
					loading: false,
					mode: mode ? mode : this.state.mode
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	render() {
		if (!this.user) {
			return <Redirect to="/login" />;
		}

		if (this.state.successMessage) {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.setState({
					successMessage: ''
				});
			}, config.notifDuration);
		}

		if (this.state.failedMessage) {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.setState({
					failedMessage: false
				});
			}, config.notifDuration);
		}

		const { fromDate, toDate } = this.state;
		const modifiers = { start: fromDate, end: toDate };
		const datasets = [];

		console.log('current inspection finding = ', this.state.currentInspectionFinding);

		if (!this.user) {
			return <Redirect to="/login" />;
		}

		const columns = [
			{
				Header: 'Name',
				Cell: ({ row, original }) => {
					return (
						<a href={original.pdfFullPath} target="_blank">
							{original.pdfName}
						</a>
					);
				}
			},
			{
				Header: 'Created by',
				accessor: 'createdBy',
				sortable: false
			},
			{
				Header: 'Created date',
				accessor: 'createdDate',
				Cell: (cellInfo) => {
					const date = convertToStd(cellInfo.value);
					if (date == 'Invalid date') {
						return '';
					}
					return date;
				}
			}
		];

		const { data, pages, loading } = this.state;

		return (
			<div>
				<BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
					<br />
					{this.state.failedMessage ? (
						<div className="alert alert-danger" role="alert">
							{this.state.failedMessage}
						</div>
					) : null}
					{this.state.successMessage ? (
						<div className="alert alert-success" role="alert">
							{this.state.successMessage}
						</div>
					) : null}
					<hr />
					<h1>PDF Report</h1>
					<hr />
					<form>
						<div className="form-group">
							<label>
								<b>Choose mode : </b>
							</label>
							<select onChange={this.handleMode} className="form-control">
								<option value="ADD_NEW">Add new</option>
								<option value="AUDIT_FINDING">Show audit finding reports</option>
								<option value="AUDIT_COUNTER_MEASURE">Show audit counter measure reports</option>
								<option value="VIOLATION_OCCURRENCE">Show violation occurrence reports</option>
								<option value="INSPECTION_SCORE">Show inspection score reports</option>
							</select>
						</div>
						{this.state.mode === 'ADD_NEW' ? (
							<div>
								<div className="form-group">
									<label>
										<b>Choose filter : </b>
									</label>
									<div className="InputFromTo">
										<select
											className="form-control"
											style={{ marginBottom: '8px' }}
											onChange={(event) => this.handleDateFilterShortcut(event.target.value)}
										>
											<option value="LAST_WEEK">Last week</option>
											<option value="LAST_2_WEEK">Last 2 weeks</option>
											<option value="LAST_30_DAYS">Last 30 days</option>
											<option value="CUSTOM">Custom</option>
										</select>
										<div
											style={{
												display: this.state.isFilterDateShowing ? 'inline-block' : 'none'
											}}
										>
											<DayPickerInput
												value={fromDate}
												placeholder="From"
												format="LL"
												formatDate={formatDate}
												parseDate={parseDate}
												dayPickerProps={{
													selectedDays: [ fromDate, { fromDate, toDate } ],
													disabledDays: { after: toDate },
													toMonth: toDate,
													modifiers,
													numberOfMonths: 1,
													onDayClick: () => this.toDate.getInput().focus()
												}}
												onDayChange={this.handleFromChange}
											/>{' '}
											—{' '}
											<span className="InputFromTo-to">
												<DayPickerInput
													ref={(el) => (this.toDate = el)}
													value={toDate}
													placeholder="To"
													format="LL"
													formatDate={formatDate}
													parseDate={parseDate}
													dayPickerProps={{
														selectedDays: [ fromDate, { fromDate, toDate } ],
														disabledDays: { before: fromDate },
														modifiers,
														month: fromDate,
														fromMonth: fromDate,
														numberOfMonths: 1
													}}
													onDayChange={this.handleToChange}
												/>
											</span>
										</div>
										<Helmet>
											<style>{`
.InputFromTo .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}
.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.InputFromTo .DayPickerInput-Overlay {
  width: 550px;
}
.InputFromTo-to .DayPickerInput-Overlay {
  margin-left: -198px;
}
`}</style>
										</Helmet>
									</div>
								</div>
								<div className="form-group">
									<label>
										<b>Choose report : </b>
									</label>
									<select
										onChange={(event) => this.setState({ report: event.target.value })}
										className="form-control"
									>
										<option value="AUDIT_FINDING">Audit Finding</option>
										<option value="AUDIT_COUNTER_MEASURE">Audit Countermeasure</option>
										<option value="VIOLATION_OCCURRENCE">Violation Occurrence</option>
										<option value="INSPECTION_SCORE">Inspection Score</option>
									</select>
								</div>
								{this.user &&
								(this.user.user.type === 'ADMIN' || this.user.user.type === 'SUPER_ADMIN') ? (
									<div className="form-group">
										<label>
											<b>Choose location: </b>
										</label>
										<Select
											onChange={this.handleChangeLocation}
											options={this.state.locations}
											value={this.state.locationValue}
										/>
									</div>
								) : null}
								<div className="form-group">
									<label>
										<b>Choose contractor name: </b>
									</label>
									<Select
										onChange={this.handleContractorName}
										options={this.state.contractorNames}
										value={this.state.contractorNameValue}
									/>
								</div>
								<div className="form-group">
									{this.state.urlToDownload ? (
										<a href={this.state.urlToDownload} target="_blank">
											{this.state.pdfName}
										</a>
									) : null}
								</div>
								<div className="form-group">
									<button onClick={this.generatePdf} disabled={this.state.isWaiting} className="btn">
										{this.state.isWaiting ? 'Please wait...' : 'Generate'}
									</button>
								</div>
							</div>
						) : (
							<div>
								<div className="grid2-container" style={{ marginBottom: '10px', marginTop: '10px' }}>
									<div className="grid-item" />
									{!this.state.showFiltering ? (
										<div className="grid-item" style={{ justifySelf: 'end' }}>
											<button type="button" className="btn" onClick={this.handleFilterInput}>
												<span class="fe fe-search" />
											</button>
										</div>
									) : null}
								</div>
								{this.state.showFiltering ? (
									<div>
										<br />
										<br />
										<div className="card">
											<div className="pull-right" style={{ marginRight: '10px' }}>
												<button
													type="button"
													className="pull-right clickable close-icon close"
													onClick={this.handleCloseFilterInput}
												>
													<span aria-hidden="true">&times;</span>
												</button>
											</div>
											<div className="card-body">
												<div className="row">
													<div className="col-md-auto">
														<label>
															<b>Created by</b>
														</label>
														<input
															className="form-control"
															onChange={(e) => this.handleSearch(0, e.target.value)}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								) : null}
								<ReactTable
									columns={columns}
									manual
									data={data}
									pages={pages}
									loading={loading}
									onFetchData={this.fetchData}
									filterable={false}
									defaultPageSize={10}
									className="-striped -highlight"
									sortable={false}
								/>
							</div>
						)}
					</form>
					<ReactModal
						isOpen={this.state.reportId}
						contentLabel="Minimal Modal Example"
						style={{
							content: {
								top: '50%',
								left: '50%',
								right: 'auto',
								bottom: 'auto',
								marginRight: '-50%',
								transform: 'translate(-50%, -50%)'
							}
						}}
					>
						<ReportEmailModal
							setReportState={this.setReportState}
							handleClose={() => this.setState({ reportId: 0 })}
							reportId={this.state.reportId}
						/>
					</ReactModal>
				</BlockUi>
			</div>
		);
	}
}

export default PdfReport;
