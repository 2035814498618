import moment from 'moment';

function convertToStd(dateTime) {
	if (!dateTime) {
		return '';
	}
	const dateStr = moment(dateTime, 'YYYY-MM-DD HH:mm:ss').format('LL');
	if (dateStr === 'Invalid date') {
		return '';
	}
	return dateStr;
}
function convertToYYMMDD(dateTime) {
	if (!dateTime) {
		return '';
	}
	const dateStr = moment(dateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
	if (dateStr === 'Invalid date') {
		return '';
	}
	return dateStr;
}

function convertToStdDay(dateTime) {
	if (!dateTime) {
		return '';
	}
	const dateStr = moment(dateTime, 'YYYY-MM-DD HH:mm:ss').format('dddd, MMMM Do, YYYY');
	if (dateStr === 'Invalid date') {
		return '';
	}
	return dateStr;
}

function getMonthDateRange(year, month) {
	var moment = require('moment');

	var startDate = moment([ year, month - 1 ]);

	var endDate = moment(startDate).endOf('month');

	return { start: startDate, end: endDate };
}

export { convertToStd, convertToStdDay, getMonthDateRange, convertToYYMMDD };
