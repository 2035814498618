import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import axios from 'axios';
import config from '../config';
import { setObject, getObject } from '../utils/localstorage';
import LimitedTextarea from '../utils/textarea_component';

class MasterAnalysisCreatorCreate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			taskName: '',
			taskNameEdit: '',
			potential: '',
			preventive: '',
			potentialEdit: '',
			preventiveEdit: '',

			masterAnalysisId: '',
			taskList: [],
			potentialHazardList: []
		};

		this.handleTaskName = this.handleTaskName.bind(this);
		this.handlePotentialName = this.handlePotentialName.bind(this);
		this.handlePreventiveName = this.handlePreventiveName.bind(this);
		this.handleSaveTask = this.handleSaveTask.bind(this);
		this.handleSavePotential = this.handleSavePotential.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleDeleteTask = this.handleDeleteTask.bind(this);
		this.handleDeletePotential = this.handleDeletePotential.bind(this);
		this.handleRefresh = this.handleRefresh.bind(this);
		this.handleRefreshPotential = this.handleRefreshPotential.bind(this);

		this.handleTaskNameEdit = this.handleTaskNameEdit.bind(this);

		this.handlePrentiveEdit = this.handlePrentiveEdit.bind(this);
		this.handlePotentialEdit = this.handlePotentialEdit.bind(this);

		this.handleSave = this.handleSave.bind(this);
		this.closeModal = props.closeModal;
		this.refreshTable = props.refreshTable;
		this.setFailedMessage = props.setFailedMessage;
		this.user = getObject('user');
	}

	async componentDidMount() {
		try {
			this.setState({
				isActiveLoadingOverlay: true
			});

			const createMasterAnalysisIdPromise = axios({
				url: `${config.api.masteranalysis}`,
				method: 'post',
				headers: { 'x-access-token': this.user.token }
			});

			const [ createMasterAnalysisIdResult ] = await Promise.all([ createMasterAnalysisIdPromise ]);
			const createMasterAnalysis = createMasterAnalysisIdResult.data.data.id;
			this.setState({
				masterAnalysisId: createMasterAnalysis
			});
		} catch (e) {
		} finally {
			this.setState({
				isActiveLoadingOverlay: false
			});
		}
	}

	async handleDelete() {
		this.closeModal();
		try {
			this.setState({
				isActiveLoadingOverlay: true
			});
			const response = await axios({
				url: `${config.api.masteranalysis}/${this.state.masterAnalysisId}`,
				method: 'delete',
				headers: { 'x-access-token': this.user.token }
			});
		} catch (e) {
		} finally {
			this.setState({
				isActiveLoadingOverlay: false
			});
			this.closeModal();
		}
	}
	async handleSave() {
		this.refreshTable({
			page: this.props.tablePaging.page,
			pageSize: this.props.tablePaging.pageSize,
			filtered: []
		});
		this.closeModal();
	}

	async handleDeleteTask(id) {
		try {
			const response = await axios({
				url: `${config.api.masteranalysis}/task/${id}`,
				method: 'delete',
				headers: { 'x-access-token': this.user.token }
			});
			this.handleRefresh();
		} catch (e) {
		} finally {
		}
	}

	async handleDeletePotential(id) {
		try {
			const response = await axios({
				url: `${config.api.masteranalysis}/potential/${id}`,
				method: 'delete',
				headers: { 'x-access-token': this.user.token }
			});
			this.handleRefreshPotential();
		} catch (e) {
		} finally {
		}
	}

	async handleRefresh() {
		try {
			this.setState({
				isActiveLoadingOverlay: true,
				taskList: []
			});

			const getMasterAnalysisTaskPromise = axios({
				url: `${config.api.masteranalysis}/task/${this.state.masterAnalysisId}`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			// const getMasterAnalysisPotentialHazardPromise = axios({
			// 	url: `${config.api.masteranalysis}/potential/${this.state.masterAnalysisId}`,
			// 	method: 'get',
			// 	headers: { 'x-access-token': this.user.token }
			// });

			const [ getMasterAnalysisTaskResult ] = await Promise.all([ getMasterAnalysisTaskPromise ]);
			const getMasterAnalysisTask = getMasterAnalysisTaskResult.data.data;

			this.setState({
				taskList: getMasterAnalysisTask
			});
		} catch (e) {
		} finally {
			this.setState({
				isActiveLoadingOverlay: false
			});
		}
	}
	async handleRefreshPotential() {
		try {
			this.setState({
				isActiveLoadingOverlay: true,

				potentialHazardList: []
			});

			const getMasterAnalysisPotentialHazardPromise = axios({
				url: `${config.api.masteranalysis}/potential/${this.state.masterAnalysisId}`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const [ getMasterAnalysisPotentialHazardResult ] = await Promise.all([
				getMasterAnalysisPotentialHazardPromise
			]);
			const getMasterAnalysisPotentialHazard = getMasterAnalysisPotentialHazardResult.data.data;

			this.setState({
				potentialHazardList: getMasterAnalysisPotentialHazard
			});
		} catch (e) {
		} finally {
			this.setState({
				isActiveLoadingOverlay: false
			});
		}
	}

	async handleSaveTask() {
		try {
			this.setState({
				isActiveLoadingOverlay: true
			});
			const user = getObject('user');

			const header = {
				headers: { 'x-access-token': user.token }
			};

			const data = {
				taskName: this.state.taskName,
				analysisId: this.state.masterAnalysisId
			};

			const res = await axios.post(`${config.api.masteranalysis}/task`, data, header);
			this.setState({
				taskName: ''
			});
			this.handleRefresh();
		} catch (e) {
		} finally {
		}
	}

	async handleSavePotential() {
		try {
			this.setState({
				isActiveLoadingOverlay: true
			});
			const user = getObject('user');

			const header = {
				headers: { 'x-access-token': user.token }
			};

			const data = {
				potential: this.state.potential,
				preventive: this.state.preventive,
				analysisId: this.state.masterAnalysisId
			};

			await axios.post(`${config.api.masteranalysis}/potential`, data, header);
			this.setState({
				potential: '',
				preventive: ''
			});
			this.handleRefreshPotential();
		} catch (e) {
		} finally {
		}
	}

	handleTaskName(taskName) {
		this.setState({
			taskName
		});
	}

	handlePotentialName(potential) {
		this.setState({
			potential: potential.text
		});
	}

	handlePreventiveName(preventive) {
		this.setState({
			preventive: preventive.text
		});
	}

	handleTaskNameEdit(taskName, index) {
		let markers = [ ...this.state.taskList ];
		markers[index] = { ...markers[index], taskName: taskName };
		this.setState({
			taskList: markers
		});
		// this.setState({
		// 	taskNameEdit: taskName
		// });
	}

	async handleSaveEditTask(id, index) {
		try {
			this.setState({
				isActiveLoadingOverlay: true
			});
			const user = getObject('user');

			const header = {
				headers: { 'x-access-token': user.token }
			};

			const data = {
				taskName: this.state.taskList[index].taskName
			};

			const res = await axios.put(`${config.api.masteranalysis}/task/${id}`, data, header);

			this.handleRefresh();
		} catch (e) {
		} finally {
			this.setState({
				taskNameEdit: '',
				isActiveLoadingOverlay: false
			});
		}
	}

	handlePotentialEdit(value, index) {
		let markers = [ ...this.state.potentialHazardList ];
		markers[index] = { ...markers[index], potential: value };
		this.setState({
			potentialHazardList: markers
		});
		// this.setState({
		// 	potentialEdit: value.text
		// });
	}
	handlePrentiveEdit(value, index) {
		let markers = [ ...this.state.potentialHazardList ];
		markers[index] = { ...markers[index], preventive: value };
		this.setState({
			potentialHazardList: markers
		});
		// this.setState({
		// 	preventiveEdit: value.text
		// });
	}

	async handleSaveEditPotentialPreventive(id, potential, preventive, event, index) {
		event.preventDefault();
		try {
			this.setState({
				isActiveLoadingOverlay: true
			});
			const user = getObject('user');

			const header = {
				headers: { 'x-access-token': user.token }
			};

			// if (this.state.potentialEdit === '') {
			// 	this.state.potentialEdit = potential;
			// }
			// if (this.state.preventiveEdit === '') {
			// 	this.state.preventiveEdit = preventive;
			// }

			const data = {
				potential: this.state.potentialHazardList[index].potential,
				preventive: this.state.potentialHazardList[index].preventive
			};

			const res = await axios.put(`${config.api.masteranalysis}/potential/${id}`, data, header);

			this.handleRefreshPotential();
		} catch (e) {
		} finally {
			this.setState({
				potentialEdit: '',
				preventiveEdit: '',
				isActiveLoadingOverlay: false
			});
		}
	}

	render() {
		return (
			<div>
				<BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
					<br />
					<div className=" row">
						<div className="col-md-6 mx-auto align-self-center">
							<h1 className="">Create Analysis Keyword Database</h1>
						</div>

						<div className="col-md-6 mx-auto">
							<button
								type="button"
								className=" float-right clickable close-icon close"
								onClick={this.handleDelete}
							>
								<span aria-hidden="true">&times;</span>
							</button>
							{/* <button
								type="button"
								className=" float-right clickable close-icon close"
								onClick={this.closeModal}
							>
								<span aria-hidden="true">&times;</span>
							</button> */}
						</div>
					</div>
					<hr />

					<br />
					<div className="row col-12">
						<label className="text-muted">
							<b>Task Keyword / Job keywords</b>
						</label>
					</div>
					<div className="row col-12">
						<label>Enter some related words</label>
					</div>
					<br />
					<form>
						<div className="form-group">
							{/* <label>Contractor Name</label> */}
							<div className="row mb-2">
								<div className="col-10">
									<input
										type="text"
										placeholder="Input task"
										value={this.state.taskName}
										className="form-control"
										onChange={(event) => this.handleTaskName(event.target.value)}
									/>
								</div>
								<div className="row col-2 align-self-center">
									<button
										type="button"
										class="btn btn-primary"
										disabled={!this.state.taskName}
										onClick={this.handleSaveTask}
									>
										<span class="fe fe-plus" />
									</button>
								</div>
							</div>

							{this.state.taskList.map((v, index) => (
								<div className="row mb-2">
									<div className="col-10">
										<input
											type="text"
											placeholder="Input task"
											value={v.taskName}
											className="form-control float-left master-analysis"
											onChange={(event) => this.handleTaskNameEdit(event.target.value, index)}
										/>
									</div>
									<div className="row col-2">
										<div className="mr-2">
											<button
												type="button"
												class="btn-primary btn"
												disabled={!v.taskName}
												onClick={() => this.handleSaveEditTask(v.id, index)}
											>
												<span class="fe fe-save" />
											</button>
										</div>
										<div className=" ">
											<button
												type="button"
												class="btn-danger btn"
												onClick={() => this.handleDeleteTask(v.id)}
											>
												<span class="fe fe-trash" />
											</button>
										</div>
									</div>
								</div>
							))}
						</div>
					</form>

					{this.state.failedMessage ? (
						<div className="alert alert-danger" role="alert">
							{this.state.failedMessage}
						</div>
					) : null}

					{/* <button type="button" class="btn btn-primary btn-sm" onClick={this.handleSaveTask}>
						<span class="fe fe-plus">Add</span>
					</button> */}

					<br />
					<br />
					<br />

					<div className="row col-12">
						<label className="text-muted">
							<b>Potential Hazard & Preventive/Countermeasures </b>
						</label>
					</div>

					<form>
						<div className="form-group">
							<div className="row mb-2 ">
								<div className="col-5">
									<label>Potential Hazard</label>

									{/* <LimitedTextarea
										limit={10000}
										defaultValue={this.state.potential}
										rows="3"
										onChange={this.handlePotentialName}
									/> */}

									<textarea
										class="form-control"
										id="exampleFormControlTextarea1"
										maxLength={10000}
										rows="2"
										value={this.state.potential}
										onChange={(event) => {
											this.setState({
												potential: event.target.value
											});
										}}
									/>
									<p className="text-muted float-right">
										{this.state.potential ? this.state.potential.length : 0} of 10.000 Character
										remaining
									</p>
								</div>
								<div className="col-5">
									<label>Preventive/Countermeasures</label>
									{/* <LimitedTextarea
										limit={10000}
										defaultValue={this.state.preventive}
										rows="3"
										onChange={this.handlePreventiveName}
									/> */}

									<textarea
										class="form-control"
										id="exampleFormControlTextarea1"
										maxLength={10000}
										rows="2"
										value={this.state.preventive}
										onChange={(event) => {
											this.setState({
												preventive: event.target.value
											});
										}}
									/>
									<p className="text-muted float-right">
										{this.state.preventive ? this.state.preventive.length : 0} of 10.000 Character
										remaining
									</p>
								</div>
								<div className="col-2 mx-auto align-self-center ">
									<button
										type="button"
										class="btn btn-primary"
										disabled={!this.state.potential || !this.state.preventive}
										onClick={this.handleSavePotential}
									>
										<span class="fe fe-plus" />
									</button>
								</div>
							</div>
						</div>
					</form>

					{this.state.potentialHazardList.map((v, index) => (
						<form
							key={v.id}
							onSubmit={(event) =>
								this.handleSaveEditPotentialPreventive(v.id, v.potential, v.preventive, event, index)}
						>
							<div className="form-group">
								<div className="row mb-2">
									<div className="col-5">
										{/* <LimitedTextarea
											limit={10000}
											defaultValue={v.potential}
											rows="3"
											onChange={(event) => {
												this.handlePotentialEdit(event, index);
											}}
										/> */}
										<textarea
											class="form-control"
											id="exampleFormControlTextarea1"
											rows="1"
											maxLength={10000}
											value={v.potential}
											onChange={(event) => {
												this.handlePotentialEdit(event.target.value, index);
											}}
										/>
										<p className="text-muted float-right">
											{v.potential ? v.potential.length : 0} of 10.000 Character remaining{' '}
										</p>
									</div>
									<div className="col-5">
										{/* <LimitedTextarea
											limit={10000}
											defaultValue={v.preventive}
											rows="3"
											onChange={(event) => {
												this.handlePrentiveEdit(event, index);
											}}
										/> */}
										<textarea
											class="form-control"
											id="exampleFormControlTextarea1"
											rows="1"
											maxLength={10000}
											value={v.preventive}
											onChange={(event) => {
												this.handlePrentiveEdit(event.target.value, index);
											}}
										/>
										<p className="text-muted float-right">
											{v.preventive ? v.preventive.length : 0} of 10.000 Character remaining{' '}
										</p>
									</div>
									<div className="row col-2">
										<div className="mr-2">
											<button
												// type="button"
												class="btn-primary btn"
												// disabled={true}
												type="submit"
												// onClick={() =>
												// 	this.handleSaveEditPotentialPreventive(
												// 		v.id,
												// 		v.potential,
												// 		v.preventive
												// 	)}
											>
												<span class="fe fe-save" />
											</button>
										</div>
										<div className=" align-self-start">
											<button
												type="button"
												class="btn-danger btn"
												onClick={() => this.handleDeletePotential(v.id)}
											>
												<span class="fe fe-trash" />
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					))}

					{/* <button type="button" class="btn btn-primary btn-sm" onClick={this.handleSavePotential}>
						<span class="fe fe-plus">Add</span>
					</button> */}

					<br />
					<br />

					<div className="row" style={{ marginBottom: '10px' }}>
						<div className="col-md-12">
							<button
								disabled={
									this.state.taskList.length === 0 || this.state.potentialHazardList.length === 0
								}
								className="btn"
								style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
								onClick={this.handleSave}
							>
								Save
							</button>
						</div>
					</div>
				</BlockUi>
			</div>
		);
	}
}

export default MasterAnalysisCreatorCreate;
