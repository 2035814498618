import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import axios from 'axios';
import config from './config';
import Login from './components/Login';
import Logout from './components/Logout';
import AnalysisCreatorTemplate from './components/AnalysisCreatorTemplate';
import MasterAnalysisCreatorTemplate from './components/MasterAnalysisCreatorTemplate';
import InspectionTemplate from './components/InspectionTemplate';
import SectionTemplate from './components/SectionTemplate';
import QuestionTemplate from './components/QuestionTemplate';
import ProjectContractor from './components/ProjectContractor';
import AnalysisCreatorTemplateCreate from './components/AnalysisCreatorTemplateCreate';
import AnalysisCreatorTemplateDetail from './components/AnalysisCreatorTemplateDetail';
import AnalysisCreatorTemplateEdit from './components/AnalysisCreatorTemplateEdit';
import ContractorProject from './components/ContractorProject';
import Inspection from './components/Inspection';
import SectionInspection from './components/SectionInspection';
import QuestionInspection from './components/QuestionInspection';
import QuestionOpenItem from './components/QuestionOpenItem';
import ClosedQuestionOpenItem from './components/ClosedQuestionOpenItem';
import ViolationOccurance from './components/ViolationOccurance';
import D12Incident from './components/D12Incident';
import D13Violation from './components/D13Violation';
import User from './components/User';
import Contractor from './components/Contractor';
import Home from './components/Home';
import MyAccount from './components/MyAccount';
import Company from './components/Company';
import PasswordChange from './components/PasswordChange';
import Project from './components/Project';
import PdfReport from './components/PdfReport';
import Logo from './logo.png';
import '../src/App.css';
import { getObject } from './utils/localstorage';
import OpenItemContractor from './components/OpenItemContractor';
import Severity from './components/Severity';
import Stop6 from './components/Stop6';
import D13Type from './components/D13Type';
import D13Consequence from './components/D13Consequence';
import ManHour from './components/ManHour';
import Department from './components/Department';
import Location from './components/Location';
import ContractorName from './components/ContractorName';
import SubContractorName from './components/SubContractorName';
import ContributingFactor from './components/ContributingFactor';
import EscalationProcess from './components/EscalationProcess';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import ManHourNonContractor from './components/ManHourNonContractor';
import UserAdminLocation from './components/UserAdminLocation';
import UserAdminDepartment from './components/UserAdminDepartment';
import UserContractor from './components/UserContractor';
import UserAdmin from './components/UserAdmin';
import 'animate.css';

const NoMatch = ({ location }) => (
	<div>
		<h3>
			No match for <code>{location.pathname}</code> back to{' '}
			<Link to="/">
				<i className="fe fe-home" /> Dashboard
			</Link>{' '}
			?
		</h3>
	</div>
);

class App extends Component {
	constructor(props) {
		super(props);
		const user = getObject('user');
		this.state = {
			navBarZindex: 20,
			activeMenu: 'dashboard',
			activeParent: '',
			userType: '',
			isShowingInspectionTemplate: false,
			isShowingUser: false,
			isShowingCompany: false,
			isShowingProject: false,
			isShowingInspectionControl: false,
			isShowingCurrentUserUpdate: false,
			isActiveLoadingOverlay: false
		};

		if (user) {
			this.state.userType = user.user.type;
		}

		this.hideNavBar = this.hideNavBar.bind(this);
		this.checkActive = this.checkActive.bind(this);
		this.handleClickMenu = this.handleClickMenu.bind(this);
		this.setUserType = this.setUserType.bind(this);
		this.setActiveMenu = this.setActiveMenu.bind(this);
		this.user = user;
	}

	checkActive(menu) {
		return this.state.activeMenu === menu ? 'nav-link active' : 'nav-link';
	}

	setActiveLoadingOverlay = (isActiveLoadingOverlay) => {
		this.setState({ isActiveLoadingOverlay });
	};

	async componentDidMount() {
		if (this.user) {
			const routes = [ '/inspectiontemplates', '/projects', '/users', '/inspections' ];

			const routePromises = [];
			for (let i = 0; i < routes.length; i++) {
				const promise = axios({
					url: `${config.api.apicontrols}/checking?route=${routes[i]}&method=GET&parameter=/`,
					method: 'get',
					headers: { 'x-access-token': this.user.token }
				});
				routePromises.push(promise);
			}

			const userUpdatePromise = axios({
				url: `${config.api.apicontrols}/checking?route=/users&method=PUT&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const violationOccurrencePromise = axios({
				url: `${config.api.apicontrols}/checking?route=/openitems&method=GET&parameter=/violationoccurances`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const severityCreatePromise = axios({
				url: `${config.api.apicontrols}/checking?route=/severities&method=POST&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const stop6CreatePromise = axios({
				url: `${config.api.apicontrols}/checking?route=/stop6&method=POST&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const d13TypeCreatePromise = axios({
				url: `${config.api.apicontrols}/checking?route=/d13types&method=POST&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const d13ConsequenceCreatePromise = axios({
				url: `${config.api.apicontrols}/checking?route=/d13consequences&method=POST&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const departmentCreatePromise = axios({
				url: `${config.api.apicontrols}/checking?route=/departments&method=POST&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const locationCreatePromise = axios({
				url: `${config.api.apicontrols}/checking?route=/locations&method=POST&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const contractorNameGetPromise = axios({
				url: `${config.api.apicontrols}/checking?route=/contractornames&method=GET&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const contributingFactorCreatePromise = axios({
				url: `${config.api.apicontrols}/checking?route=/contributingfactors&method=POST&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const escalationProcessCreatePromise = axios({
				url: `${config.api.apicontrols}/checking?route=/escalationprocesses&method=POST&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const companiesCreatePromise = axios({
				url: `${config.api.apicontrols}/checking?route=/companies&method=POST&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const subContractorNameCreatePromise = axios({
				url: `${config.api.apicontrols}/checking?route=/subcontractornames&method=POST&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const analysisPromise = axios({
				url: `${config.api.apicontrols}/checking?route=/analysiscreator&method=GET&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const masterAnalysisPromise = axios({
				url: `${config.api.apicontrols}/checking?route=/masteranalysis&method=GET&parameter=/`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			routePromises.push(userUpdatePromise);
			routePromises.push(violationOccurrencePromise);
			routePromises.push(severityCreatePromise);
			routePromises.push(stop6CreatePromise);
			routePromises.push(d13TypeCreatePromise);
			routePromises.push(d13ConsequenceCreatePromise);
			routePromises.push(departmentCreatePromise);
			routePromises.push(locationCreatePromise);
			routePromises.push(contractorNameGetPromise);
			routePromises.push(contributingFactorCreatePromise);
			routePromises.push(escalationProcessCreatePromise);
			routePromises.push(companiesCreatePromise);
			routePromises.push(subContractorNameCreatePromise);
			routePromises.push(analysisPromise);
			routePromises.push(masterAnalysisPromise);

			const [
				inspectionTemplateResult,
				projectResult,
				userResult,
				inspectionControlResult,
				userUpdateResult,
				violationOccurrenceResult,
				severityResult,
				stop6Result,
				d13TypeResult,
				d13ConsequenceResult,
				departmentResult,
				locationResult,
				contractorNameResult,
				contributingFactorResult,
				escalationProcessResult,
				companyResult,
				subContractorNameResult,
				analysisResult,
				masterAnalysisResult
			] = await Promise.all(routePromises);
			this.setState({
				isShowingInspectionTemplate: inspectionTemplateResult.data.data.isAllowed,
				isShowingUser: userResult.data.data.isAllowed,
				isShowingCreateCompany: companyResult.data.data.isAllowed,
				isShowingProject: projectResult.data.data.isAllowed,
				isShowingInspectionControl: inspectionControlResult.data.data.isAllowed,
				isShowingCurrentUserUpdate: userUpdateResult.data.data.isAllowed,
				isShowingViolationOccurrence: violationOccurrenceResult.data.data.isAllowed,
				isShowingSeverityCreate: severityResult.data.data.isAllowed,
				isShowingStop6Create: stop6Result.data.data.isAllowed,
				isShowingD13TypeCreate: d13TypeResult.data.data.isAllowed,
				isShowingD13ConsquenceCreate: d13ConsequenceResult.data.data.isAllowed,
				isShowingCreateDepartment: departmentResult.data.data.isAllowed,
				isShowingCreateLocation: locationResult.data.data.isAllowed,
				isShowingGetContractorName: contractorNameResult.data.data.isAllowed,
				isShowingCreateContributingFactor: contributingFactorResult.data.data.isAllowed,
				isShowingCreateEscalationProcess: escalationProcessResult.data.data.isAllowed,
				isShowingCreateSubContractorName: subContractorNameResult.data.data.isAllowed,
				isShowingAnalysisName: analysisResult.data.data.isAllowed,
				isShowingMasterAnalysisName: masterAnalysisResult.data.data.isAllowed
			});
		}
	}

	setActiveMenu(activeMenu) {
		this.setState({
			activeMenu
		});
	}

	setUserType(userType) {
		this.setState({
			userType
		});
	}

	handleClickMenu(activeMenu, activeParent) {
		this.setState({
			activeMenu,
			activeParent
		});
	}

	hideNavBar(isHide) {
		this.setState({
			navBarZindex: isHide ? -20 : 20
		});
	}

	render() {
		const Layout = (component) => {
			return (
				<div className="container-fluid">
					<nav
						className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
						style={{ zIndex: this.state.navBarZindex }}
					>
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#sidebarCollapse"
							aria-controls="sidebarCollapse"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon" />
						</button>
						<a href="/">
							<img src={Logo} width="200" height="140" alt="..." />
						</a>
						<div className="navbar-user d-md-none" />
						<div className="collapse navbar-collapse" id="sidebarCollapse">
							<form className="mt-4 mb-3 d-md-none">
								<div className="input-group input-group-rounded input-group-merge" />
							</form>

							<ul className="navbar-nav">
								<li className="nav-item" onClick={() => this.handleClickMenu('dashboard', '')}>
									<Link to="/" className={this.checkActive('dashboard')}>
										<i className="fe fe-home" /> Dashboard
									</Link>
								</li>

								{this.state.isShowingAnalysisName ? (
									<li
										className="nav-item"
										onClick={() => this.handleClickMenu('analysiscreator', '')}
									>
										<Link to="/analysiscreator" className={this.checkActive('analysiscreator')}>
											<i className="fe fe-edit" /> Analysis Creator
										</Link>
									</li>
								) : null}

								<li className="nav-item">
									<a
										className="nav-link"
										href="#sidebarPages"
										data-toggle="collapse"
										role="button"
										aria-expanded="false"
										aria-controls="sidebarPages"
									>
										<i className="fe fe-file" /> Master
									</a>
									<div
										className={this.state.activeParent === 'master' ? '' : 'collapse'}
										id="sidebarPages"
									>
										<ul className="nav nav-sm flex-column">
											{this.state.isShowingInspectionTemplate ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('template', 'master')}
												>
													<Link
														to="/inspectiontemplate"
														className={this.checkActive('template')}
													>
														Template
													</Link>
												</li>
											) : null}
											{this.state.isShowingUser ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('user', 'master')}
												>
													<Link to="/user" className={this.checkActive('user')}>
														User
													</Link>
												</li>
											) : null}
											{this.state.isShowingCreateCompany ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('company', 'master')}
												>
													<Link to="/company" className={this.checkActive('company')}>
														Company
													</Link>
												</li>
											) : null}
											{this.state.isShowingProject ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('project', 'master')}
												>
													<Link to="/project" className={this.checkActive('project')}>
														Project
													</Link>
												</li>
											) : null}
											{this.state.isShowingSeverityCreate ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('severity', 'master')}
												>
													<Link to="/severity" className={this.checkActive('severity')}>
														D12 Severity
													</Link>
												</li>
											) : null}
											{this.state.isShowingStop6Create ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('stop6', 'master')}
												>
													<Link to="/stop6" className={this.checkActive('stop6')}>
														D12 Stop 6
													</Link>
												</li>
											) : null}
											{this.state.isShowingD13TypeCreate ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('d13type', 'master')}
												>
													<Link to="/d13type" className={this.checkActive('d13type')}>
														D13 Type
													</Link>
												</li>
											) : null}
											{this.state.isShowingD13ConsquenceCreate ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('d13consequence', 'master')}
												>
													<Link
														to="/d13consequence"
														className={this.checkActive('d13consequence')}
													>
														D13 Consequence
													</Link>
												</li>
											) : null}
											{this.state.isShowingCreateDepartment ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('department', '')}
												>
													<Link to="/department" className={this.checkActive('department')}>
														Department
													</Link>
												</li>
											) : null}
											{this.state.isShowingCreateLocation ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('location', '')}
												>
													<Link to="/location" className={this.checkActive('location')}>
														Location
													</Link>
												</li>
											) : null}
											{this.state.isShowingGetContractorName ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('contractorname', '')}
												>
													<Link
														to="/contractorname"
														className={this.checkActive('contractorname')}
													>
														Contractor Name
													</Link>
												</li>
											) : null}
											{this.state.isShowingCreateContributingFactor ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('contributingfactor', '')}
												>
													<Link
														to="/contributingfactor"
														className={this.checkActive('contributingfactor')}
													>
														Contributing Factor
													</Link>
												</li>
											) : null}
											{this.state.isShowingCreateEscalationProcess ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('escalationprocess', '')}
												>
													<Link
														to="/escalationprocess"
														className={this.checkActive('escalationprocess')}
													>
														Escalation Process
													</Link>
												</li>
											) : null}
											{this.state.isShowingCreateSubContractorName ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('subcontractorname', '')}
												>
													<Link
														to="/subcontractorname"
														className={this.checkActive('subcontractorname')}
													>
														Sub Contractor Name
													</Link>
												</li>
											) : null}
											{this.state.isShowingMasterAnalysisName ? (
												<li
													className="nav-item"
													onClick={() => this.handleClickMenu('masteranalysiscreator', '')}
												>
													<Link
														to="/masteranalysiscreator"
														className={this.checkActive('masteranalysiscreator')}
													>
														Analysis
													</Link>
												</li>
											) : null}
										</ul>
									</div>
								</li>
								{this.state.isShowingInspectionControl ? (
									<li className="nav-item" onClick={() => this.handleClickMenu('inspection', '')}>
										<Link to="/inspection" className={this.checkActive('inspection')}>
											<i className="fe fe-clipboard" /> Inspection
										</Link>
									</li>
								) : null}
								{this.state.isShowingViolationOccurrence ? (
									<li
										className="nav-item"
										onClick={() => this.handleClickMenu('violationoccurrence', '')}
									>
										<Link
											to="/violationoccurrence"
											className={this.checkActive('violationoccurrence')}
										>
											<i className="fe fe-alert-triangle" />Violation Occurrence
										</Link>
									</li>
								) : null}
								<li className="nav-item" onClick={() => this.handleClickMenu('pdfreport', '')}>
									<Link to="/pdfreport" className={this.checkActive('pdfreport')}>
										<i className="fe fe-download" />PDF Report
									</Link>
								</li>
								{this.state.isShowingInspectionControl ? (
									<li
										className="nav-item"
										onClick={() => this.handleClickMenu('inspectionopenitem', '')}
									>
										<Link
											to="/inspectionopenitem"
											className={this.checkActive('inspectionopenitem')}
										>
											<i className="fe fe-flag" /> Open item
										</Link>
									</li>
								) : null}
								<li className="nav-item" onClick={() => this.handleClickMenu('d12incident', '')}>
									<Link to="/d12incident" className={this.checkActive('d12incident')}>
										<i className="fe fe-alert-circle" />Incident Log
									</Link>
								</li>
								<li className="nav-item" onClick={() => this.handleClickMenu('d13violation', '')}>
									<Link to="/d13violation" className={this.checkActive('d13violation')}>
										<i className="fe fe-x-circle" />Violation Log
									</Link>
								</li>
								<li className="nav-item" onClick={() => this.handleClickMenu('manhour', '')}>
									<Link to="/manhour" className={this.checkActive('manhour')}>
										<i className="fe fe-clock" />Man Hour
									</Link>
								</li>
								<li className="nav-item">
									<a
										className="nav-link"
										href="#sidebarAuth"
										data-toggle="collapse"
										role="button"
										aria-expanded="false"
										aria-controls="sidebarAuth"
									>
										<i className="fe fe-user" /> Account
									</a>
									<div
										className={this.state.activeParent === 'account' ? '' : 'collapse'}
										id="sidebarAuth"
									>
										<ul className="nav nav-sm flex-column">
											<li
												className="nav-item"
												onClick={() => this.handleClickMenu('my_account', 'account')}
											>
												{this.state.isShowingCurrentUserUpdate ? (
													<Link to="/myaccount" className={this.checkActive('my_account')}>
														My account
													</Link>
												) : null}
											</li>
											<li
												className="nav-item"
												onClick={() => this.handleClickMenu('password', 'account')}
											>
												{this.state.isShowingCurrentUserUpdate ? (
													<Link to="/passwordchange" className={this.checkActive('password')}>
														Change password
													</Link>
												) : null}
											</li>
											<li className="nav-item">
												<Link to="/logout" className="nav-link">
													Logout
												</Link>
											</li>
										</ul>
									</div>
								</li>
							</ul>
						</div>
					</nav>
					<div className="main-content">
						<BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
							{component}
						</BlockUi>
					</div>
				</div>
			);
		};
		return (
			<Router>
				<Switch>
					<Route
						exact
						path="/"
						render={() =>
							Layout(
								<Home
									setUserType={this.setUserType}
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route path="/login" render={() => <Login setUserType={this.setUserType} />} />
					<Route path="/logout" render={() => <Logout setActiveMenu={this.setActiveMenu} />} />
					<Route
						path="/user"
						render={({ match: { url } }) => (
							<Switch>
								<Route
									path={`${url}/:contractorId/project`}
									render={({ match, history: { location } }) =>
										Layout(
											<ContractorProject
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												contractorId={match.params.contractorId}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
								<Route
									path={`${url}/`}
									render={({ match, history: { location } }) =>
										Layout(
											<User
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												location={location}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
							</Switch>
						)}
					/>/>
					<Route
						path="/violationoccurrence"
						render={({ match: { url } }) => (
							<Switch>
								<Route
									path={`${url}/:contractorId/:fromDate/:toDate`}
									render={({ match, history: { location } }) =>
										Layout(
											<ViolationOccurance
												handleClickMenu={this.handleClickMenu}
												contractorId={match.params.contractorId}
												fromDate={match.params.fromDate}
												toDate={match.params.toDate}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
								<Route
									path={`${url}/`}
									render={() =>
										Layout(
											<ViolationOccurance
												handleClickMenu={this.handleClickMenu}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
							</Switch>
						)}
					/>
					<Route
						path="/pdfreport"
						render={() =>
							Layout(
								<PdfReport
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/myaccount"
						render={() =>
							Layout(
								<MyAccount
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/passwordchange"
						render={() =>
							Layout(
								<PasswordChange
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/company"
						render={() =>
							Layout(
								<Company
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/severity"
						render={() =>
							Layout(
								<Severity
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/stop6"
						render={() =>
							Layout(
								<Stop6
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/d13type"
						render={() =>
							Layout(
								<D13Type
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/d13consequence"
						render={() =>
							Layout(
								<D13Consequence
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/contributingfactor"
						render={() =>
							Layout(
								<ContributingFactor
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/escalationprocess"
						render={() =>
							Layout(
								<EscalationProcess
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/project"
						render={({ match: { url } }) => (
							<Switch>
								<Route
									path={`${url}/:projectId/contractor`}
									render={({ match, history: { location } }) =>
										Layout(
											<ProjectContractor
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												projectId={match.params.projectId}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
								<Route
									path={`${url}/`}
									render={({ match, history: { location } }) =>
										Layout(
											<Project
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
							</Switch>
						)}
					/>
					<Route
						path="/inspectiontemplate"
						render={({ match: { url } }) => (
							<Switch>
								<Route
									path={`${url}/:templateId/sectiontemplate/:sectionId/questiontemplate`}
									render={({ match, history: { location } }) =>
										Layout(
											<QuestionTemplate
												templateId={match.params.templateId}
												sectionId={match.params.sectionId}
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												location={location}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>

								<Route
									path={`${url}/:templateId/sectiontemplate`}
									render={({ match, history: { location } }) =>
										Layout(
											<SectionTemplate
												templateId={match.params.templateId}
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												location={location}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
								<Route
									path={`${url}/`}
									render={({ match, history: { location } }) =>
										Layout(
											<InspectionTemplate
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												location={location}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
							</Switch>
						)}
					/>
					<Route
						path="/contractor"
						render={({ match: { url } }) => (
							<Switch>
								<Route
									path={`${url}/:contractorId/openitem`}
									render={({ match, history: { location } }) =>
										Layout(
											<OpenItemContractor
												contractorId={match.params.contractorId}
												inspectionId={match.params.inspectionId}
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												location={location}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
								<Route
									path={`${url}/`}
									render={({ match, history: { location } }) =>
										Layout(
											<Contractor
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
							</Switch>
						)}
					/>
					<Route
						path="/useradmin"
						render={({ match, history: { location } }) =>
							Layout(
								<UserAdmin
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									location={location}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/useradminlocation"
						render={({ match, history: { location } }) =>
							Layout(
								<UserAdminLocation
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									location={location}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/useradmindepartment"
						render={({ match, history: { location } }) =>
							Layout(
								<UserAdminDepartment
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									location={location}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/usercontractor"
						render={({ match, history: { location } }) =>
							Layout(
								<UserContractor
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									location={location}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/d12incident"
						render={({ match, history: { location } }) =>
							Layout(
								<D12Incident
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									location={location}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/d13violation"
						render={({ match, history: { location } }) =>
							Layout(
								<D13Violation
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									location={location}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/manhour"
						render={({ match, history: { location } }) =>
							Layout(
								this.user && this.user.user.type === 'PROJECT_MONITOR' ? (
									<ManHour
										hideNavBar={this.hideNavBar}
										handleClickMenu={this.handleClickMenu}
										location={location}
										setActiveLoadingOverlay={this.setActiveLoadingOverlay}
									/>
								) : (
									<ManHourNonContractor
										hideNavBar={this.hideNavBar}
										handleClickMenu={this.handleClickMenu}
										location={location}
										setActiveLoadingOverlay={this.setActiveLoadingOverlay}
									/>
								)
							)}
					/>
					<Route
						path="/department"
						render={() =>
							Layout(
								<Department
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/location"
						render={() =>
							Layout(
								<Location
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/contractorname"
						render={() =>
							Layout(
								<ContractorName
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/subcontractorname"
						render={() =>
							Layout(
								<SubContractorName
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/inspection"
						render={({ match: { url } }) => (
							<Switch>
								<Route
									path={`${url}/:inspectionId/template/:templateId/section/:sectionId/question`}
									render={({ match, history: { location } }) =>
										Layout(
											<QuestionInspection
												inspectionId={match.params.inspectionId}
												templateId={match.params.templateId}
												sectionId={match.params.sectionId}
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												location={location}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
								<Route
									path={`${url}/:inspectionId/template/:templateId/section`}
									render={({ match, history: { location } }) =>
										Layout(
											<SectionInspection
												inspectionId={match.params.inspectionId}
												templateId={match.params.templateId}
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												location={location}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
								<Route
									exact
									path={`${url}/`}
									render={({ match, history: { location } }) =>
										Layout(
											<Inspection
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												location={location}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
							</Switch>
						)}
					/>
					<Route
						path="/inspectionopenitem"
						render={() =>
							Layout(
								<QuestionOpenItem
									hideNavBar={this.hideNavBar}
									handleClickMenu={this.handleClickMenu}
									setActiveLoadingOverlay={this.setActiveLoadingOverlay}
								/>
							)}
					/>
					<Route
						path="/analysiscreator"
						render={({ match: { url } }) =>
							this.state.isShowingAnalysisName ? (
								<Switch>
									<Route
										path={`${url}/analysiscreatoredit/:analysisCreatorId`}
										render={({ match, history: { location } }) =>
											Layout(
												<AnalysisCreatorTemplateEdit
													hideNavBar={this.hideNavBar}
													location={location}
													handleClickMenu={this.handleClickMenu}
													analysis_creator_id={match.params.analysisCreatorId}
													setActiveLoadingOverlay={this.setActiveLoadingOverlay}
												/>
											)}
									/>
									<Route
										path={`${url}/analysiscreatordetail/:analysisCreatorId`}
										render={({ match, history: { location } }) =>
											Layout(
												<AnalysisCreatorTemplateDetail
													hideNavBar={this.hideNavBar}
													location={location}
													handleClickMenu={this.handleClickMenu}
													analysis_creator_id={match.params.analysisCreatorId}
													setActiveLoadingOverlay={this.setActiveLoadingOverlay}
												/>
											)}
									/>
									<Route
										path={`${url}/analysiscreatorcreate`}
										render={({ match, history: { location } }) =>
											Layout(
												<AnalysisCreatorTemplateCreate
													hideNavBar={this.hideNavBar}
													location={location}
													handleClickMenu={this.handleClickMenu}
													// projectId={match.params.projectId}
													setActiveLoadingOverlay={this.setActiveLoadingOverlay}
												/>
											)}
									/>
									<Route
										path={`${url}/`}
										render={({ match, history: { location } }) =>
											Layout(
												<AnalysisCreatorTemplate
													hideNavBar={this.hideNavBar}
													location={location}
													handleClickMenu={this.handleClickMenu}
													setActiveLoadingOverlay={this.setActiveLoadingOverlay}
												/>
											)}
									/>
								</Switch>
							) : (
								<Switch>
									<Route component={NoMatch} />
								</Switch>
							)}
					/>
					<Route
						path="/masteranalysiscreator"
						render={({ match: { url } }) =>
							this.state.isShowingMasterAnalysisName ? (
								<Switch>
									<Route
										path={`${url}/`}
										render={({ match, history: { location } }) =>
											Layout(
												<MasterAnalysisCreatorTemplate
													hideNavBar={this.hideNavBar}
													handleClickMenu={this.handleClickMenu}
													location={location}
													setActiveLoadingOverlay={this.setActiveLoadingOverlay}
												/>
											)}
									/>
								</Switch>
							) : (
								<Switch>
									<Route component={NoMatch} />
								</Switch>
							)}
					/>
					)}/>
					<Route
						path="/closedinspectionopenitem"
						render={({ match: { url } }) => (
							<Switch>
								<Route
									exact
									path={`${url}/`}
									render={({ match, history: { location } }) =>
										Layout(
											<ClosedQuestionOpenItem
												inspectionId={match.params.inspectionId}
												hideNavBar={this.hideNavBar}
												handleClickMenu={this.handleClickMenu}
												location={location}
												setActiveLoadingOverlay={this.setActiveLoadingOverlay}
											/>
										)}
								/>
							</Switch>
						)}
					/>
					<Route component={NoMatch} />
				</Switch>
			</Router>
		);
	}
}
export default App;
