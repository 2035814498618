import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom';
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda';
import config from '../config';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class DeleteModal extends Component {
	constructor(props) {
		super(props);

		this.closeModal = props.closeModal;
		this.setSuccessMessage = props.setSuccessMessage;
		this.setFailedMessage = props.setFailedMessage;
		this.refreshTable = props.refreshTable;
		this.id = props.id;
		this.handleDelete = this.handleDelete.bind(this);
		this.user = getObject('user');
		this.deleteUrl = props.deleteUrl;

		this.state = {
			isSuccess: false,
			redirectTo: null
		};
	}

	async handleDelete() {
		try {
			this.setState({
				isActiveLoadingOverlay: true
			});

			if (this.props.clearMessage) {
				this.props.clearMessage();
			}

			const response = await axios({
				url: `${this.deleteUrl}/${this.id}`,
				method: 'delete',
				headers: { 'x-access-token': this.user.token }
			});

			this.setState({
				isSuccess: true
			});
			if (this.refreshTable) {
				this.refreshTable({
					page: 0,
					pageSize: 10,
					filtered: []
				});
			}

			if (this.setSuccessMessage) {
				this.setSuccessMessage('Item deleted successfully');
			}

			if (this.props.redirectTo) {
				this.props.history.push(this.props.redirectTo);
			} else {
				this.closeModal();
			}
		} catch (e) {
			// if (e.response.data !== undefined) {
			// 	const data = e.response.data;
			// 	if (this.setFailedMessage) {
			// 		this.setFailedMessage(data.message);
			// 	}
			// } else {
			// 	const data = e.response;
			// 	if (this.setFailedMessage) {
			// 		this.setFailedMessage(data.message);
			// 	}
			// }

			if (this.setFailedMessage) {
				this.setFailedMessage('Item failed to delete');
			}

			if (this.closeModal) {
				this.closeModal();
			}
		} finally {
			this.setState({
				isActiveLoadingOverlay: false
			});
		}
	}

	render() {
		return (
			<div>
				<BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
					<div className="align-middle">
						<div className="form-group">
							{this.props.customDeleteMessage ? (
								this.props.customDeleteMessage
							) : (
								'Are you sure want to delete the data ?'
							)}
						</div>
					</div>
					<div className="row" style={{ marginBottom: '10px' }}>
						<div className="col-md-6">
							<button
								className="btn"
								style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
								onClick={this.handleDelete}
							>
								Yes
							</button>
						</div>
						<div className="col-md-6">
							<button
								className="btn"
								style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
								onClick={this.closeModal}
							>
								No
							</button>
						</div>
					</div>
				</BlockUi>
			</div>
		);
	}
}

export default withRouter(DeleteModal);
