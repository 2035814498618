import React, { Component } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import baseUrl from '../url';
import { convertToStd } from '../utils/date';
import { EmailShareButton, EmailIcon, TelegramShareButton, TelegramIcon } from 'react-share';
import BlockUi from 'react-block-ui';
import axios from 'axios';
import config from '../config';
import { setObject, getObject } from '../utils/localstorage';
import validator from 'validator';
import {
	Document,
	Packer,
	Paragraph,
	TextRun,
	Table,
	TableRow,
	TableCell,
	HeadingLevel,
	WidthType,
	convertInchesToTwip,
	ImageRun,
	TextWrappingType,
	TextWrappingSide,
	Media,
	AlignmentType,
	TableLayoutType,
	VerticalMergeType
} from 'docx';

export default class AnalysisCreatorTemplateDoc extends Component {
	constructor(props) {
		super(props);
		this.state = {
			setBack: false,
			email: null,
			setMessage: '',
			isEmail: false
		};
		this.closeModal = props.closeModal;
		this.detailData = props.detailData;
		this.workActivity = props.workActivity;
		this.onGeneratePDoc = this.onGeneratePDoc.bind(this);
		this.onShareDoc = this.onShareDoc.bind(this);
		this.returnDoc = this.returnDoc.bind(this);
		this.docPercent = this.docPercent.bind(this);
	}

	async onShareDoc() {
		if (validator.isEmail(this.state.email)) {
			this.setState({
				isActiveLoadingOverlay: true
			});

			const doc = await this.returnDoc();
			const user = getObject('user');
			const buffer = await Packer.toBuffer(doc);
			const blob = new Blob([ buffer ], {
				type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
			});
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = `${this.detailData ? this.detailData.project_name + '.docx' : '-.docx'}`;
			// link.click();

			// console.log(link);

			const docSend = new File([ blob ], `${this.detailData ? this.detailData.project_name : '-'}.docx`, {
					type: blob.type,
					lastModified: new Date()
				}),
				data = new FormData();

			data.append('email', `${this.state.email}`);
			data.append('subject', `${this.detailData ? this.detailData.project_name : '-'}`);
			data.append('content', `${this.detailData ? this.detailData.description : '-'}`);

			data.append('file', docSend);

			if (docSend.size <= 25174926) {
				axios({
					url: `${config.api.analysiscreator}/sendemail`,
					method: 'post',
					data: data,
					headers: { 'x-access-token': user.token }
				})
					.then((response) => {
						this.closeModal();
						this.setState({
							isActiveLoadingOverlay: false,
							setMessage: ''
						});
					})
					.catch((err) => {
						this.setState({
							isActiveLoadingOverlay: false
						});
					});
			} else {
				this.setState({
					setMessage: 'File to large for the server system',
					isActiveLoadingOverlay: false
				});
			}
		}
	}

	docPercent(value) {
		return value / 100 * 9638;
	}

	async returnDoc() {
		const tableProject = new Table({
			// width: { size: 100, type: WidthType.PERCENTAGE },
			// layout: TableLayoutType.FIXED,
			width: { size: this.docPercent(100), type: WidthType.DXA },
			columnWidths: [ this.docPercent(60), this.docPercent(20), this.docPercent(20) ],

			rows: [
				new TableRow({
					children: [
						new TableCell({
							width: {
								size: this.docPercent(60),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: `Project/Operation Title : ${this.detailData
										? this.detailData.project_name
										: '-'}`,

									heading: HeadingLevel.HEADING_3
								}),
								new Paragraph({
									text: 'Description of Work: : ',
									heading: HeadingLevel.HEADING_4
								}),
								new Paragraph({
									text: `${this.detailData ? this.detailData.description : '-'}`
								})
							]
						}),
						new TableCell({
							width: {
								size: this.docPercent(20),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'Project #',
									heading: HeadingLevel.HEADING_3
								})
							]
						}),
						new TableCell({
							width: {
								size: this.docPercent(20),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'Submitted: ',
									heading: HeadingLevel.HEADING_3
								}),
								new Paragraph({
									text: 'Revised: ',
									heading: HeadingLevel.HEADING_3
								})
							]
						})
					]
				})
				// tableRowLocation
			]
		});
		const tableRequiredPersonal = new Table({
			// width: { size: 100, type: WidthType.PERCENTAGE },
			// layout: TableLayoutType.FIXED,
			width: { size: this.docPercent(100), type: WidthType.DXA },
			columnWidths: [ this.docPercent(60), this.docPercent(20), this.docPercent(20) ],

			rows: [
				new TableRow({
					children: [
						new TableCell({
							width: {
								size: this.docPercent(60),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'Required Personal Protective Equipment',
									heading: HeadingLevel.HEADING_3
								}),
								new Paragraph({
									children: this.detailData.list_equipment.split(',').map(
										(v) =>
											new TextRun({
												text: `• ${v}`,
												break: 1
											})
									)
								})
							]
						}),

						new TableCell({
							width: {
								size: this.docPercent(20),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'Estimate Work Dates',
									heading: HeadingLevel.HEADING_2
								}),
								new Paragraph({
									text: 'Begin : ',
									heading: HeadingLevel.HEADING_3
								}),
								new Paragraph({
									text: `${this.detailData
										? convertToStd(this.detailData.estimate_start_date)
										: '-'}`,
									heading: HeadingLevel.HEADING_4
								}),
								new Paragraph({
									text: 'End : ',
									heading: HeadingLevel.HEADING_3
								}),
								new Paragraph({
									text: `${this.detailData ? convertToStd(this.detailData.estimate_end_date) : '-'}`,
									heading: HeadingLevel.HEADING_4
								})
							]
						}),
						new TableCell({
							width: {
								size: this.docPercent(20),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'Work Schedule',
									heading: HeadingLevel.HEADING_2
								}),
								new Paragraph({
									text: 'Begin : ',
									heading: HeadingLevel.HEADING_3
								}),
								new Paragraph({
									text: `${this.detailData
										? convertToStd(this.detailData.schedule_start_date)
										: '-'}`,
									heading: HeadingLevel.HEADING_4
								}),
								new Paragraph({
									text: 'End : ',
									heading: HeadingLevel.HEADING_3
								}),
								new Paragraph({
									text: `${this.detailData ? convertToStd(this.detailData.schedule_end_date) : '-'}`,
									heading: HeadingLevel.HEADING_4
								})
							]
						})
					]
				})
			]
		});
		const tableWorkActivity = new Table({
			// width: { size: 100, type: WidthType.PERCENTAGE },
			// layout: TableLayoutType.FIXED,
			width: { size: this.docPercent(100), type: WidthType.DXA },
			columnWidths: [ this.docPercent(33), this.docPercent(33), this.docPercent(33) ],

			rows: [
				new TableRow({
					children: [
						new TableCell({
							width: {
								size: this.docPercent(33),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'Work Activity',
									heading: HeadingLevel.HEADING_2
								})
							]
						}),

						new TableCell({
							width: {
								size: this.docPercent(33),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'Potential Hazard',
									heading: HeadingLevel.HEADING_2
								})
							]
						}),
						new TableCell({
							width: {
								size: this.docPercent(33),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'Preventive or Corrective Measures',
									heading: HeadingLevel.HEADING_2
								})
							]
						})
					]
				})
			]
		});

		const tableWorkOperation = new Table({
			// width: { size: 100, type: WidthType.PERCENTAGE },
			// layout: TableLayoutType.FIXED,
			width: { size: this.docPercent(100), type: WidthType.DXA },
			columnWidths: [ this.docPercent(60), this.docPercent(20), this.docPercent(20) ],

			rows: [
				new TableRow({
					children: [
						new TableCell({
							width: {
								size: this.docPercent(60),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'Work Operation',
									heading: HeadingLevel.HEADING_3
								}),
								new Paragraph({
									children: this.detailData.list_equipment.split(',').map(
										(v, index) =>
											new TextRun({
												text: `• ${v}`,
												break: 1
											})
									)
								}),
								new Paragraph({
									spacing: {
										before: 400
									},
									children: [
										new TextRun({
											text:
												'Remember if there is any doubt or changes to the scop of work, STOP, CALL, WAIT',
											bold: true,
											color: 'D5352D'
										})
									]
								})
							]
						}),

						new TableCell({
							width: {
								size: this.docPercent(20),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'Meetings',
									heading: HeadingLevel.HEADING_2
								})
							]
						}),
						new TableCell({
							width: {
								size: this.docPercent(20),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'Client:',
									heading: HeadingLevel.HEADING_3
								})
							]
						})
					]
				})
			]
		});
		const tableLocation = new Table({
			// width: { size: 100, type: WidthType.PERCENTAGE },
			// layout: TableLayoutType.FIXED,
			width: { size: this.docPercent(100), type: WidthType.DXA },
			columnWidths: [ this.docPercent(70), this.docPercent(30) ],
			rows: [
				new TableRow({
					children: [
						new TableCell({
							width: {
								size: this.docPercent(70),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: `Location : ${this.detailData ? this.detailData.location : '-'}`,
									heading: HeadingLevel.HEADING_3
								}),
								new Paragraph({
									text: `Column(s): ${this.detailData ? this.detailData.column : '-'} `,
									heading: HeadingLevel.HEADING_4
								}),
								new Paragraph({
									text: `Line Name(s): ${this.detailData ? this.detailData.line_names : '-'} `,
									heading: HeadingLevel.HEADING_4
								})
							]
						}),

						new TableCell({
							width: {
								size: this.docPercent(30),
								type: WidthType.DXA
							},
							// BorderOptions: {
							// 	right: {
							// 		color: 'FFFFFF'
							// 	}
							// },

							children: [
								new Paragraph({
									text: 'Contact Information',
									heading: HeadingLevel.HEADING_3
								}),
								new Paragraph({
									text: 'Foreman/Supervisor Name  ',
									heading: HeadingLevel.HEADING_4
								}),
								new Paragraph({
									text: `${this.detailData ? this.detailData.foreman : '-'} `
								}),
								new Paragraph({
									text: 'Safety Representative',
									heading: HeadingLevel.HEADING_4
								}),
								new Paragraph({
									text: `${this.detailData ? this.detailData.safety_representative : '-'} `
								}),
								new Paragraph({
									text: 'Site Supervisor',
									heading: HeadingLevel.HEADING_4
								}),
								new Paragraph({
									text: `${this.detailData ? this.detailData.site_supervisor : '-'} `
								}),
								new Paragraph({
									text: 'Site Safety Representative',
									heading: HeadingLevel.HEADING_4
								}),
								new Paragraph({
									text: `${this.detailData ? this.detailData.site_safety_supervisor : '-'} `
								})
							]
						})
						// new TableCell({
						// 	width: {
						// 		size: this.docPercent(5),
						// 		type: WidthType.DXA
						// 	},
						// 	BorderOptions: {
						// 		left: {
						// 			color: 'FFFFFF'
						// 		}
						// 	},

						// 	children: [
						// 		new Paragraph({
						// 			text: ''
						// 		})
						// 	]
						// })
					]
				})
			]
		});

		const logo = await fetch(`${baseUrl}/${this.detailData.logo}`).then((r) => r.blob());

		const loopRow = [];

		if (this.workActivity.length !== 0) {
			for (let i = 0; i < this.workActivity.length; i++) {
				const workActivityImg = this.workActivity[i].image
					? await fetch(`${baseUrl}/${this.workActivity[i].image}`).then((r) => r.blob())
					: null;
				const workResultRow = [];
				if (this.workActivity[i].workResult.length !== 0) {
					for (let j = 0; j < this.workActivity[i].workResult.length; j++) {
						const workResultImg = this.workActivity[i].workResult[j].image
							? await fetch(`${baseUrl}/${this.workActivity[i].workResult[j].image}`).then((r) =>
									r.blob()
								)
							: null;

						var image = workResultImg
							? new Paragraph({
									heading: HeadingLevel.HEADING_2,
									children: [
										new ImageRun({
											data: workResultImg,
											transformation: {
												width: 100,
												height: 100
											},
											altText: {
												title: 'Imgae Task',
												description: `${this.workActivity[i].workResult[j].preventive}`,
												name: 'Image Task'
											}
										})
									]
								})
							: new Paragraph({
									children: [
										new TextRun({
											text: '',
											break: 1
										})
									]
								});

						var workResultGo = new Paragraph({
							children: [
								new TextRun({
									text: `${this.workActivity[i].workResult[j].preventive}`,
									break: 1
								})

								// new ImageRun({
								// 	data: workResultImg,
								// 	transformation: {
								// 		width: 100,
								// 		height: 100
								// 	},
								// 	altText: {
								// 		title: 'Imgae Task',
								// 		description: `${this.workActivity[i].workResult[j].preventive}`,
								// 		name: 'Image Task'
								// 	}
								// })
							]
						});
						workResultRow.push(workResultGo);
						workResultRow.push(image);
					}
				}

				var imageActivity = workActivityImg
					? new Paragraph({
							heading: HeadingLevel.HEADING_2,
							children: [
								new ImageRun({
									data: workActivityImg,
									transformation: {
										width: 100,
										height: 100
									},
									altText: {
										title: 'Imgae Task',
										description: `${this.workActivity[i].task}`,
										name: 'Image Task'
									}
								})
							]
						})
					: new Paragraph({
							children: [
								new TextRun({
									text: '',
									break: 1
								})
							]
						});

				var go = new TableRow({
					children: [
						new TableCell({
							width: {
								size: this.docPercent(33),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									children: [
										new TextRun({
											text: `${this.workActivity[i].task}`,
											break: 1
										})
									]
								}),
								imageActivity
								// new Paragraph({
								// 	heading: HeadingLevel.HEADING_2,
								// 	children: [
								// 		new ImageRun({
								// 			data: workActivityImg,
								// 			transformation: {
								// 				width: 100,
								// 				height: 100
								// 			},
								// 			altText: {
								// 				title: 'Imgae Task',
								// 				description: `${this.workActivity[i].task}`,
								// 				name: 'Image Task'
								// 			}
								// 		})
								// 	]
								// })
							]
						}),
						new TableCell({
							width: {
								size: this.docPercent(33),
								type: WidthType.DXA
							},

							children: this.workActivity[i].workResult.map(
								(e) =>
									new Paragraph({
										children: [
											new TextRun({
												text: `${e.potensial}`,
												break: 1
											})
										]
									})
							)
						}),
						new TableCell({
							width: {
								size: this.docPercent(33),
								type: WidthType.DXA
							},

							children: workResultRow
						})
					]
				});
				loopRow.push(go);
			}
		}

		var rowTraning = new TableRow({
			children: [
				new TableCell({
					width: {
						size: this.docPercent(25),
						type: WidthType.DXA
					},
					children: [
						new Paragraph({
							text: '',
							heading: HeadingLevel.HEADING_2
						})
					]
				}),

				new TableCell({
					width: {
						size: this.docPercent(25),
						type: WidthType.DXA
					},

					children: [
						new Paragraph({
							text: '',
							heading: HeadingLevel.HEADING_2
						})
					]
				}),
				new TableCell({
					width: {
						size: this.docPercent(25),
						type: WidthType.DXA
					},

					children: [
						new Paragraph({
							text: '',
							heading: HeadingLevel.HEADING_2
						})
					]
				}),
				new TableCell({
					width: {
						size: this.docPercent(25),
						type: WidthType.DXA
					},

					children: [
						new Paragraph({
							text: '',
							heading: HeadingLevel.HEADING_2
						})
					]
				})
			]
		});

		const tableTrainingRequirement = new Table({
			width: { size: this.docPercent(100), type: WidthType.DXA },
			columnWidths: [ this.docPercent(25), this.docPercent(25), this.docPercent(25), this.docPercent(25) ],

			// width: { size: 100, type: WidthType.PERCENTAGE },
			// layout: TableLayoutType.FIXED,
			rows: [
				new TableRow({
					children: [
						new TableCell({
							width: {
								size: this.docPercent(25),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'PRINT',
									heading: HeadingLevel.HEADING_2,
									alignment: AlignmentType.CENTER
								})
							]
						}),

						new TableCell({
							width: {
								size: this.docPercent(25),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'SIGN',
									heading: HeadingLevel.HEADING_2,
									alignment: AlignmentType.CENTER
								})
							]
						}),
						new TableCell({
							width: {
								size: this.docPercent(25),
								type: WidthType.DXA
							},

							children: [
								new Paragraph({
									text: 'PRINT',
									heading: HeadingLevel.HEADING_2,
									alignment: AlignmentType.CENTER
								})
							]
						}),
						new TableCell({
							width: {
								size: this.docPercent(25),
								type: WidthType.DXA
							},
							// width: {
							// 	size: 4505,
							// 	type: WidthType.DXA
							// },

							children: [
								new Paragraph({
									text: 'SIGN',
									heading: HeadingLevel.HEADING_2,
									alignment: AlignmentType.CENTER
								})
							]
						})
					]
				}),
				rowTraning,
				rowTraning,
				rowTraning,
				rowTraning,
				rowTraning,
				rowTraning,
				rowTraning,
				rowTraning,
				rowTraning,
				rowTraning,
				rowTraning,
				rowTraning,
				rowTraning,
				rowTraning
			]
		});

		const tableWorkActivityList = new Table({
			// width: { size: 100, type: WidthType.PERCENTAGE },
			// layout: TableLayoutType.FIXED,
			width: { size: this.docPercent(100), type: WidthType.DXA },
			columnWidths: [ this.docPercent(33), this.docPercent(33), this.docPercent(33) ],

			rows:
				loopRow.length !== 0
					? loopRow
					: [
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: this.docPercent(33),
											type: WidthType.DXA
										},

										children: [
											new Paragraph({
												text: '',
												heading: HeadingLevel.HEADING_2
											})
										]
									})
								]
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: this.docPercent(33),
											type: WidthType.DXA
										},

										children: [
											new Paragraph({
												text: '',
												heading: HeadingLevel.HEADING_2
											})
										]
									})
								]
							}),
							new TableRow({
								children: [
									new TableCell({
										width: {
											size: this.docPercent(33),
											type: WidthType.DXA
										},

										children: [
											new Paragraph({
												text: '',
												heading: HeadingLevel.HEADING_2
											})
										]
									})
								]
							})
						]

			// rows: loopRow
		});

		return new Document({
			sections: [
				{
					properties: {},
					children: [
						new Paragraph({
							children: [
								new ImageRun({
									data: logo,
									transformation: {
										width: 70,
										height: 70
									}
								})
							]
						}),
						new Paragraph({
							text: 'Job Hazard Analysis',
							alignment: AlignmentType.CENTER,
							heading: HeadingLevel.HEADING_2,
							spacing: {
								before: 400
							}
						}),
						tableProject,
						tableLocation,
						tableRequiredPersonal,
						tableWorkOperation,
						tableWorkActivity,
						tableWorkActivityList,
						new Paragraph({
							text: 'Training Requirement',
							alignment: AlignmentType.CENTER,
							heading: HeadingLevel.HEADING_2,
							spacing: {
								before: 400
							}
						}),
						new Paragraph({
							text:
								'All employees will be required to make themselves familliar with the content of this hazard assessment',
							alignment: AlignmentType.CENTER,
							heading: HeadingLevel.HEADING_3
						}),
						new Paragraph({
							text: 'Date: ______________/________________/______________',
							alignment: AlignmentType.CENTER,
							heading: HeadingLevel.HEADING_3,
							spacing: {
								before: 400,
								after: 400
							}
						}),
						tableTrainingRequirement
					]
				}
			]
		});
	}

	async onGeneratePDoc() {
		this.setState({
			isActiveLoadingOverlay: true
		});

		// this.state.workActivity.forEach((imageUrl) => {
		// 	const image = Media.fromUrl(imageUrl);
		// 	const imageParagraph = new Paragraph(image);
		// 	doc.addSection(imageParagraph);
		//   });
		const doc = await this.returnDoc();

		const buffer = await Packer.toBuffer(doc);
		const blob = new Blob([ buffer ], {
			type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
		});
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = `${this.detailData ? this.detailData.project_name + '.docx' : '-.docx'}`;
		link.click();

		// console.log(link);

		this.setState({
			isActiveLoadingOverlay: false
		});
	}

	render() {
		const styles = {
			page: {
				flexDirection: 'row',
				backgroundColor: '#E4E4E4'
			},
			section: {
				margin: 10,
				padding: 10,
				flexGrow: 1
			}
		};
		return (
			<BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
				<div class="container">
					<div className=" col-12 justify-content-right">
						<button
							type="button"
							className=" float-right clickable close-icon close"
							onClick={() => {
								this.closeModal();
							}}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<br />
					<div>
						<div id="doc-target" className="container">
							<div class="col-12">
								{/* <img src={mainLogo} width={300} height={220} /> */}

								{/* <img className="img-fluid" alt="Safeport Analysis" src={mainLogo} style={{ height: 150 }} /> */}
								{this.detailData ? this.detailData.logo ? (
									<img
										className="img-fluid"
										alt="Responsive image"
										key={this.detailData.logo}
										src={`${baseUrl}/${this.detailData.logo}`}
										style={{ height: 150 }}
									/>
								) : (
									<p>-</p>
								) : (
									<div>
										<label className="mx-auto text-muted"> - </label>
									</div>
								)}
							</div>
							<div className="row col-12 justify-content-center">
								<label className="">
									<b>
										<h2>Job Hazard Analysis</h2>
									</b>
								</label>{' '}
							</div>

							<table class="table table-bordered">
								<tbody>
									<tr>
										<td colSpan="2" className="col-8">
											<h2 className="">
												{' '}
												<p> Project/Operation Title: </p>{' '}
												{this.detailData ? (
													<p>{this.detailData.project_name}</p>
												) : (
													<p>-</p>
												)}{' '}
											</h2>
											<b>
												<p> Description of Work: </p>
											</b>
											{this.detailData ? <p>{this.detailData.description}</p> : <p>-</p>}
										</td>
										<td className="col-2">Project #</td>
										<td className="col-2">
											<p>
												<b>Submitted: </b>
											</p>
											<p>
												<b>Revised: </b>
											</p>
										</td>
									</tr>

									<tr>
										<td colSpan="2" className="col-8">
											<h2 className="">
												{' '}
												<p>Location : </p>{' '}
												{this.detailData ? <p> {this.detailData.location}</p> : <p>-</p>}{' '}
											</h2>
											<p> Column(s): </p>{' '}
											{this.detailData ? <p>{this.detailData.column}</p> : <p>-</p>}
											<p> Line Name(s): </p>{' '}
											{this.detailData ? <p>{this.detailData.line_names}</p> : <p>-</p>}
										</td>

										<td colSpan="2" className="col-2">
											<h2>
												<u>Contact Information</u>
											</h2>
											<div className="col-12">
												<label>
													<b>Foreman/Supervisor Name</b>
												</label>
												{this.detailData ? <p>{this.detailData.foreman}</p> : <p>-</p>}
											</div>

											<div className="col-12">
												<label>
													<b>Safety Representative</b>
												</label>
												{this.detailData ? (
													<p>{this.detailData.safety_representative}</p>
												) : (
													<p>-</p>
												)}
											</div>

											<div className="col-12">
												<label>
													<b>Site Supervisor</b>
												</label>
												{this.detailData ? <p>{this.detailData.site_supervisor}</p> : <p>-</p>}
											</div>
											<div className="col-12">
												<label>
													<b>Site Safety Representative</b>
												</label>
												{this.detailData ? (
													<p>{this.detailData.site_safety_supervisor}</p>
												) : (
													<p>-</p>
												)}
											</div>
										</td>
									</tr>

									<tr>
										<td colSpan="2" className="col-8">
											<h2 className="text-danger">
												{' '}
												<p>
													<u> Required Personal Protective Equipment</u>
												</p>{' '}
											</h2>

											{this.detailData ? (
												<ul>
													{this.detailData.list_equipment.split(',').map((v) => <li>{v}</li>)}
												</ul>
											) : (
												<p>-</p>
											)}
										</td>
										<td className="col-2">
											<h2 className="">
												{' '}
												<b>
													<u>Estimate Work Dates</u>
												</b>{' '}
											</h2>

											<b>
												<p>Begin: </p>
												{this.detailData ? (
													<p>{convertToStd(this.detailData.estimate_start_date)}</p>
												) : (
													<p>-</p>
												)}
											</b>

											<b>
												<p>End: </p>
												{this.detailData ? (
													<p>{convertToStd(this.detailData.estimate_end_date)}</p>
												) : (
													<p>-</p>
												)}{' '}
											</b>
										</td>
										<td className="col-2">
											<h2 className="">
												{' '}
												<b>
													<u>Work Schedule</u>
												</b>{' '}
											</h2>

											<b>
												<p>Begin: </p>
												{this.detailData ? (
													<p>{convertToStd(this.detailData.schedule_start_date)}</p>
												) : (
													<p>-</p>
												)}
											</b>

											<b>
												<p>End: </p>
												{this.detailData ? (
													<p>{convertToStd(this.detailData.schedule_end_date)}</p>
												) : (
													<p>-</p>
												)}{' '}
											</b>
										</td>
									</tr>

									<tr>
										<td colSpan="2" className="col-8">
											<h3 className="">
												{' '}
												<p>Work Operation</p>{' '}
											</h3>

											{this.detailData ? this.detailData.workOperation.length !== 0 ? (
												<ul className="list-group">
													{this.detailData.workOperation.map((v, index) => (
														<li>{v.description}</li>
													))}
												</ul>
											) : (
												<p>-</p>
											) : (
												<p>-</p>
											)}
											<br />
											<p className="text-danger">
												<b>
													Remember if there is any doubt or changes to the scop of work, STOP,
													CALL, WAIT
												</b>
											</p>
										</td>
										<td className="col-2">
											<h2 className="">
												{' '}
												<b>
													<u>Meetings</u>
												</b>{' '}
											</h2>
										</td>
										<td className="col-2">
											<b>Client:</b>
										</td>
									</tr>
									<tr>
										<td className="col-2">
											<h3 className="">
												{' '}
												<b>Work Activity</b>{' '}
											</h3>
										</td>
										<td className="col-2">
											<h3 className="">
												{' '}
												<b>Potential Hazard</b>{' '}
											</h3>
										</td>
										<td colSpan="2" className="col-8">
											<h3 className="">
												{' '}
												<b>Preventive or Corrective Measures</b>{' '}
											</h3>
										</td>
									</tr>

									{this.workActivity ? this.workActivity.length !== 0 ? (
										this.workActivity.map((v, index) => (
											<tr>
												<td className="col-2">
													<div className="col-md-auto">
														<div className="col-md-auto">
															<label>{v.task}</label>
														</div>
														<div className="col-md-auto">
															{v.image ? (
																<img
																	src={`${baseUrl}/${v.image}`}
																	width="200"
																	height="140"
																	class="img-fluid"
																	alt="Responsive image"
																/>
															) : null}
														</div>
													</div>
												</td>
												<td className="col-2">
													<div>
														{v.workResult.map((v, index) => (
															<div className="col-md-auto">
																<p>{v.potensial} </p>
															</div>
														))}
													</div>
												</td>
												<td colSpan="2" className="col-8">
													<div>
														{v.workResult.map((v, index) => (
															<div className="col-md-auto">
																<div className="col-md-auto">
																	<p>{v.preventive} </p>
																</div>
																{v.image ? (
																	<div className="col-md-auto">
																		<img
																			src={`${baseUrl}/${v.image}`}
																			width="200"
																			height="140"
																			className="img-fluid"
																			alt="Responsive image"
																		/>
																	</div>
																) : null}
															</div>
														))}
													</div>
												</td>
											</tr>
										))
									) : (
										<tr>
											<td className="col-2">
												<p>-</p>
											</td>
											<td className="col-2">
												<p>-</p>
											</td>
											<td colSpan="2" className="col-8">
												<p>-,</p>
											</td>
										</tr>
									) : (
										<tr>
											<td className="col-2">
												<p>-</p>
											</td>
											<td className="col-2">
												<p>-</p>
											</td>
											<td colSpan="2" className="col-8">
												<p>-</p>
											</td>
										</tr>
									)}

									{/* <tr>
									<td className="col-2">
										<p>-</p>
									</td>
									<td className="col-2">
										<p>-</p>
									</td>
									<td colSpan="2" className="col-8">
										<p>-,</p>
									</td>
								</tr> */}
								</tbody>
							</table>
							<br />
							<div className="container">
								<div className="row col-12 justify-content-center">
									<p>
										<b>Training Requirement</b>
									</p>
								</div>
								<div className="row col-12 justify-content-center">
									<p>
										All employees will be required to make themselves familliar with the content of
										this hazard assessment
									</p>
								</div>
								<div className="row col-12 justify-content-center">
									<h3>Date: ______________/________________/______________</h3>
								</div>
								<div className="  col-12">
									<table class="table table-bordered text-center">
										<thead>
											<tr>
												<th className="col-sm-3 whitebg text-dark">PRINT</th>
												<th className="col-sm-3 whitebg text-dark">SIGN</th>
												<th className="col-sm-3 whitebg text-dark">PRINT</th>
												<th className="col-sm-3 whitebg text-dark">SIGN</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
											<tr>
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
												<td className="col-sm-3" />
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						{this.state.setBack ? (
							<div class="container mt-5 mb-5 animate__animated animate__backInUp" data-wow-delay="0.3s">
								<div className="row justify-content-center">
									<div className="col-4 ">
										<div class="input-group mb-3">
											<input
												type="text"
												class="form-control"
												placeholder="Input Email"
												aria-label="Recipient's username"
												aria-describedby="button-addon2"
												value={this.state.email}
												onChange={(event) => {
													const email = event.target.value;

													if (validator.isEmail(email)) {
														this.setState({
															email: event.target.value,
															setMessage: '',
															isEmail: true
														});
													} else {
														this.setState({
															email: event.target.value,
															isEmail: false,
															setMessage: 'Please, enter valid Email!'
														});
													}
												}}
											/>
											<div class="input-group-append">
												<button
													onClick={this.onShareDoc}
													class="btn btn-outline-secondary"
													type="button"
													disabled={!this.state.isEmail}
													id="button-addon2"
												>
													Send
												</button>
											</div>
										</div>

										<span
											style={{
												color: 'red'
											}}
										>
											{this.state.setMessage}
										</span>
									</div>
								</div>
							</div>
						) : null}
						<div className="row col-12 justify-content-center">
							<button
								className="btn btn-primary mr-3"
								style={{}}
								onClick={() => {
									this.state.setBack
										? this.closeModal()
										: this.setState({
												setBack: true
											});
								}}
							>
								<div className="row " style={{ marginRight: '5px', marginLeft: '5px' }}>
									{this.state.setBack ? (
										<span className="fe fe-arrow-left mr-2" style={{ marginRight: '2px' }} />
									) : (
										<span className="fe fe-share mr-2" style={{ marginRight: '2px' }} />
									)}

									<span className="fefont"> {this.state.setBack ? 'Back' : 'Share Doc'}</span>
								</div>
							</button>
							<button className="btn btn-primary mr-3" style={{}} onClick={this.onGeneratePDoc}>
								<div className="row " style={{ marginRight: '5px', marginLeft: '5px' }}>
									<span className="fefont"> Generate Docx</span>
									<span className=" fe fe-down" style={{ marginRight: '2px' }} />
								</div>
							</button>
						</div>
					</div>
				</div>
			</BlockUi>
		);
	}
}
