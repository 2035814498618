import React, { Component } from 'react';

export default class TagInputComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tagData: props.tags
		};

		// this.limit = props.limit;
		// // this.defaultValue = props.defaultValue;
		// this.row = props.row;
		// this.onChange = props.onChange;
		// this.required = props.required;

		this.removeTagData = this.removeTagData.bind(this);
		this.addTagData = this.addTagData.bind(this);
		this.onChange = props.onChange;

		this.fileInput = React.createRef();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({
				tagData: nextProps.tags
			});
		}
	}

	// componentDidUpdate(prevProps, prevState, snapshot) {
	// 	console.log('Horeeeeeee--------------');
	// 	console.log(prevProps.tags);
	// 	console.log(prevState.tags);
	// 	if (prevProps.tags !== this.props.tags) {
	// 		this.setState({ tagData: prevProps.tags });
	// 	}
	// 	// if (nextProps.value !== this.props.value) {
	// 	// 	this.setState({ tagData: nextProps.value });
	// 	// }
	// }

	removeTagData(indexToRemove) {
		this.setState({
			tagData: [ ...this.state.tagData.filter((_, index) => index !== indexToRemove) ]
		});
		this.onChange({
			tagData: [ ...this.state.tagData.filter((_, index) => index !== indexToRemove) ]
		});
	}
	addTagData(event) {
		event.preventDefault();

		if (event.target.value !== '') {
			this.setState({
				tagData: [ ...this.state.tagData, event.target.value ]
			});
			this.onChange({
				tagData: [ ...this.state.tagData, event.target.value ]
			});
			event.target.value = '';
		}
	}
	render() {
		return (
			<div className="tag-input">
				<ul className="tags">
					{this.state.tagData.map((tag, index) => (
						<li key={index} className="tag">
							<span className="tag-title">{tag}</span>
							{this.state.tagData.length !== 1 ? (
								<span className="tag-close-icon" onClick={() => this.removeTagData(index)}>
									<i className="fe fe-x-circle" />
								</span>
							) : null}
						</li>
					))}
				</ul>

				<input
					type="text"
					ref={this.fileInput}
					onKeyDown={(event) => (event.key === 'Enter' ? this.addTagData(event) : null)}
					placeholder="Press enter to add a tag"
				/>
			</div>
		);
	}
}

// const TagInput = ({ tags }) => {
// 	const [ tagData, setTagData ] = React.useState(tags);
// 	const removeTagData = (indexToRemove) => {
// 		setTagData([ ...tagData.filter((_, index) => index !== indexToRemove) ]);
// 	};
// 	const addTagData = (event) => {
// 		if (event.target.value !== '') {
// 			setTagData([ ...tagData, event.target.value ]);
// 			event.target.value = '';
// 		}
// 	};
// 	return (
// 		<div className="tag-input">
// 			<ul className="tags">
// 				{tagData.map((tag, index) => (
// 					<li key={index} className="tag">
// 						<span className="tag-title">{tag}</span>
// 						<span className="tag-close-icon" onClick={() => removeTagData(index)}>
// 							x
// 						</span>
// 					</li>
// 				))}
// 			</ul>
// 			<input
// 				type="text"
// 				onKeyUp={(event) => (event.key === 'Enter' ? addTagData(event) : null)}
// 				placeholder="Press enter to add a tag"
// 			/>
// 		</div>
// 	);
// };
