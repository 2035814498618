import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { setObject, getObject } from '../utils/localstorage';
import { checkIfAdminOrSuperAdmin } from '../utils/auth';
import * as R from 'ramda'
import config from '../config';
import Select from 'react-select';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

class UserEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            department: '',
            typeId: 0,
            companyId: 0,
            failedMessage: '',
            companyOptions: [],
            isShowing: 'fe fe-eye-off'
        };

        this.handleName = this.handleName.bind(this);
        this.handleDepartment = this.handleDepartment.bind(this);
        this.handleTypeId = this.handleTypeId.bind(this);
        this.handleCompanyId = this.handleCompanyId.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handleReTypePassword = this.handleReTypePassword.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleDeleteCard = this.handleDeleteCard.bind(this);
        this.handleClickShowingPassword = this.handleClickShowingPassword.bind(this);
        this.handleChangeLocations = this.handleChangeLocations.bind(this);
        this.handleSubContractorNameId = this.handleSubContractorNameId.bind(this);
        this.closeModal = props.closeModal;
        this.id = props.id;
        this.refreshTable = props.refreshTable;
        this.setSuccessMessage = props.setSuccessMessage;
        this.setFailedMessage = props.setFailedMessage;
        this.user = getObject('user');

        this.user = getObject('user');

        this.typeOptions = [
            {
                value: 'INSPECTOR',
                text: 'Inspector'
            },
            {
                value: 'CONTRACTOR',
                text: 'Sub Contractor'
            }
        ];

        const userObj = this.user.user;
        if(userObj.type === "SUPER_ADMIN") {
            this.typeOptions.push({
                value: 'ADMIN',
                text: 'Admin'
            });
        }
    }

    async componentDidMount() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            const userIdPromise = 
                axios({
                    url: `${config.api.user}/${this.id}`,
                    method: 'get',
                    headers: { 'x-access-token': this.user.token }
                });

            const companiesPromise = axios({
                url: `${config.api.company}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const contractorNamePromise = axios({
                url: `${config.api.contractorname}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const subContractorNamePromise = axios({
                url: `${config.api.subcontractorname}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const departmentsPromise = axios({
                url: `${config.api.department}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const locationsPromise = axios({
                url: `${config.api.location}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const assignedLocationsPromise = axios({
                url: `${config.api.user}/locations/${this.props.id}`,
                method: 'get',
                headers: { 'x-access-token': this.user.token }
            });

            const [
                    userIdResult, 
                    companiesResult, 
                    contractorNameResult,
                    departmentsResult, 
                    locationsResult,
                    assignedLocationsResult,
                    subContractorNameResult
                ] = await Promise.all([
                    userIdPromise,
                    companiesPromise,
                    contractorNamePromise,
                    departmentsPromise,
                    locationsPromise,
                    assignedLocationsPromise,
                    subContractorNamePromise
                ]);

            const companies = companiesResult.data.data.results;
            const contractorNames = contractorNameResult.data.data.results;
            const userId = userIdResult.data.data;
            const departments = departmentsResult.data.data.results;
            const locations = locationsResult.data.data.results;
            const assignedLocations = assignedLocationsResult.data.data;
            const subContractorNames = subContractorNameResult.data.data.results;

            const contractorNameId = contractorNames.length > 0 ? contractorNames[0].id : "";
            const departmentId = departments.length > 0 ? departments[0].id : "";
            const locationId = locations.length > 0 ? locations[0].id : "";
            const subContractorNameId = subContractorNames.length > 0 ? subContractorNames[0].id : "";

            this.setState({
                ...userId,
                typeId: userId.type,
                phoneNumber: userId.phoneNumber,
                companies,
                contractorNameOptions: contractorNames,
                contractorNameId: userId.contractorNameId ? userId.contractorNameId : contractorNameId,
                companyOptions: companies,
                companyId: userId.companyId,
                companyName: userId.companyName,
                departmentOptions: departments,
                departmentId: userId.departmentId ? userId.departmentId : departmentId,
                locationOptions: locations,
                locationId: userId.locationId ? userId.locationId : locationId,
                selectedLocationValues: R.map(l => ({value: l.locationId, label: l.location}), assignedLocations),
                locationValues: R.map(l => ({ value: l.id, label: l.location}), locations),
                locationIds: R.map(l => l.id, locations),
                subContractorNameOptions: subContractorNames,
                subContractorNameId: userId.subContractorNameId ? userId.subContractorNameId : subContractorNameId
            });
            this.getDepartments();
        } catch(e) {
            const data = e.response.data;
            this.setFailedMessage(data.message);
            this.closeModal();
        } finally {
            this.setState({
                isActiveLoadingOverlay : false
            });
        }

    }

    getLocations = async () => {
        let url = config.api.location;
        if (this.state.companyId) {
            url += `?companyId=${this.state.companyId}`
        }
        const locationsResult = await axios({
            url,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
        });

        const locations = locationsResult.data.data.results;
        const locationId = locations.length > 0 ? locations[0].id : "";
        this.setState({
            locationOptions: locations,
            locationId: locationId,
        }, () => {
            this.afterLocationChange();
        });
    }

    getDepartments = async () => {
        let url = config.api.department;
        if (this.state.companyId) {
            url += `?companyId=${this.state.companyId}`
        }
        const departmentsResult = await axios({
            url,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
        });

        const departments = departmentsResult.data.data.results;
        this.setState({
            departmentOptions: departments
        });
    }

    afterLocationChange = async () => {
        const contractorNamePromise = axios({
            url: `${config.api.contractorname}?locationId=${this.state.locationId}`,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
        });

        const subContractorNamePromise = axios({
            url: `${config.api.subcontractorname}?locationId=${this.state.locationId}`,
            method: 'get',
            headers: { 'x-access-token': this.user.token }
        });

        const [
            contractorNameResult,
            subContractorNameResult
        ] = await Promise.all([
            contractorNamePromise,
            subContractorNamePromise
        ]);

        const contractorNames = contractorNameResult.data.data.results;
        const subContractorNames = subContractorNameResult.data.data.results;

        const contractorNameId = contractorNames.length > 0 ? contractorNames[0].id : "";
        const subContractorNameId = subContractorNames.length > 0 ? subContractorNames[0].id : "";

        this.setState({
            contractorNameOptions: contractorNames,
            contractorNameId: contractorNameId,
            subContractorNameOptions: subContractorNames,
            subContractorNameId: subContractorNameId
        });
    }

    handleDeleteCard(idx) {
        this.closeModal();
    }

    handleChangeLocations(selectedLocationValues) {
        this.setState({
            locationIds: R.map(l => l.value, selectedLocationValues),
            selectedLocationValues
        });
    }

    handleName(event) {
        const name = event.target.value;
        this.setState({
            name
        });
    }

    handleDepartment(event) {
        const department = event.target.value;
        this.setState({
            department
        });
    }

    handleTypeId(event) {
        const typeId = event.target.value;
        this.setState({
            typeId
        });
    }

    handleCompanyId(event) {
        const companyId = event.target.value;
        this.setState({
            companyId
        }, () => {
            this.getLocations();
            this.getDepartments();
        });
    }

    handlePassword(password) {
        this.setState({
            password
        });
    }

    handleReTypePassword(reTypePassword) {
        this.setState({
            reTypePassword
        });
    }

    handleClickShowingPassword(event) {
        const value = event.target.value;
        if(this.state.isShowing === 'fe fe-eye-off') {
            this.setState({
                isShowing: 'fe fe-eye'
            });
        } else {
            this.setState({
                isShowing: 'fe fe-eye-off'
            });
        }
    }

    handleDepartmentId = (departmentId) => {
        this.setState({
            departmentId
        });
    }

    handleLocationId = (locationId) => {
        this.setState({
            locationId
        }, () => {
            this.afterLocationChange();
        });
    }

    handleContractorNameId = (contractorNameId) => {
        this.setState({
            contractorNameId
        });
    }

    handlePhoneNumber = (event) => {
        const phoneNumber = event.target.value;
        this.setState({
            phoneNumber
        });
    }

    handleSubContractorNameId = (subContractorNameId) => {
        this.setState({
            subContractorNameId
        });
    }

    async handleSave() {
        try {
            this.setState({
                isActiveLoadingOverlay: true
            });
            if(this.state.password !== this.state.reTypePassword) {
                this.setState({
                    failedMessage: "password doesn't match"
                })
            } else {
                let data = {
                    name: this.state.name,
                    password: this.state.password,     
                    phoneNumber: this.state.phoneNumber == "" ? null : this.state.phoneNumber          
                };

                if(this.user.user.type === 'SUPER_ADMIN') {
                    data.companyId = this.state.companyId;
                    data.departmentId = this.state.departmentId;

                    if (this.state.typeId !== "ADMIN") {
                        data.locationId = this.state.locationId;
                    }

                    if (this.state.typeId === "ADMIN_DEPARTMENT") {
                        data.departmentId = this.state.departmentId;
                    }

                    if (this.state.typeId === "PROJECT_MONITOR" || this.state.typeId === "CONTRACTOR") {
                        data.contractorNameId = this.state.contractorNameId;
                    }

                    if (this.state.typeId === "CONTRACTOR") {
                        data.subContractorNameId = this.state.subContractorNameId
                    }
                }
    
                if(this.state.typeId === "ADMIN_DEPARTMENT" 
                    && (this.state.locationIds || this.state.locationIds.length > 0)) {
                    data.locationIds = this.state.locationIds;
                }
                // if(this.user && this.user.user.type === 'ADMIN') {
                //     data.type = this.state.typeId;
                //     if(this.state.typeId === 'INSPECTOR') {
                //         data.departmentId = this.state.departmentId;
                //         data.locationId = this.state.locationId;
                //     } else {
                //         data.departmentId = 0;
                //         data.locationId = 0;
                //         data.contractorNameId = this.state.contractorNameId;
                //     }
                // }
    
                const response = await axios({
                    url: `${config.api.user}/${this.id}`,
                    method: 'put',
                    headers: { 'x-access-token': this.user.token },
                    data
                });

                this.refreshTable({
                    page: this.props.tablePaging.page,
                    pageSize: this.props.tablePaging.pageSize,
                    filtered: []
                });
                this.setSuccessMessage('user has been updated successfully.');
                this.closeModal();
            }
        } catch(e) { 
            if(e.response && e.response.data) {
                const data = e.response.data;
                const failedMessage = data.message;
                this.setState({
                    failedMessage
                });
            }
        } finally {
            this.setState({
                isActiveLoadingOverlay: false
            });
        }
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
                <div className="pull-right">
                    <button type="button"  
                            className="clickable close-icon close" 
                            onClick={() =>this.handleDeleteCard(1)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <br/>
                <br/>
                <form>
                    {/* {
                        this.state.typeId === 'ADMIN'
                        && (this.user && this.user.user.type === 'SUPER_ADMIN')
                        ?
                        <div className="form-group">
                            <label><b>Company</b></label>
                            <p>{this.state.companyName}</p>
                        </div>
                        :
                        null

                    } */}
                    <div className="form-group">
                        <label><b>Full Name</b></label>
                        <input 
                            type="text"
                            className="form-control"
                            value={this.state.name}
                            onChange={this.handleName}/>
                    </div>
                  
                            <div className="form-group">
                                <label><b>Company</b></label>
                                <select 
                                    name="company"
                                    onChange={event => this.handleCompanyId(event)}
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    value={this.state.companyId}
                                >
                                    {
                                        R.map(
                                        x => <option value={x.id} key={'company-'+x.id}>{x.company}</option>,
                                        this.state.companyOptions || [])
                                    }
                                </select>
                            </div> 
                            <div className="form-group">
                    <label><b>Department</b></label>
                    <select onChange={event => this.handleDepartmentId(event.target.value)}
                            style={{ width: "100%" }}
                            className="form-control"
                            value={this.state.departmentId}>
                            {R.map(
                                x => <option value={x.id}>{x.department}</option>,
                                this.state.departmentOptions || [])}
                    </select>
                </div>

                    {
                        this.user && this.user.user.type === 'SUPER_ADMIN' && this.state.typeId !== 'ADMIN' ? 
                            <div className="form-group">
                                <label><b>Location</b></label>
                                <select
                                    name="location"
                                    onChange={event => this.handleLocationId(event.target.value)}
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    value={this.state.locationId}
                                >
                                    {
                                        R.map(
                                        x => <option value={x.id} key={'location-'+x.id}>{x.location}</option>,
                                        this.state.locationOptions || [])
                                    }
                                </select>
                            </div> 
                        : null
                    }

                    {
                        this.user && this.user.user.type === 'SUPER_ADMIN' && this.state.typeId === 'ADMIN_DEPARTMENT' ?
                            <div className="form-group">
                                <label><b>Department</b></label>
                                <select
                                    name="department"
                                    onChange={event => this.handleDepartmentId(event.target.value)}
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    value={this.state.departmentId}
                                >
                                    {
                                        R.map(
                                        x => <option value={x.id} key={'department-'+x.id}>{x.department}</option>,
                                        this.state.departmentOptions || [])
                                    }
                                </select>
                            </div>
                        : null
                    }

                    
                    {
                        this.user && this.user.user.type === 'SUPER_ADMIN' && (this.state.typeId === 'PROJECT_MONITOR' || this.state.typeId === 'CONTRACTOR') ?
                            <div className="form-group">
                                <label><b>Contractor Name</b></label>
                                <select
                                    name="contractor"
                                    onChange={event => this.handleContractorNameId(event.target.value)}
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    value={this.state.contractorNameId}
                                >
                                    {
                                        R.map(
                                        x => <option value={x.id} key={'contractor-'+x.id}>{x.contractorName}</option>,
                                        this.state.contractorNameOptions || [])
                                    }
                                </select>
                            </div>
                        : null
                    }

                    {
                        this.user && this.user.user.type === 'SUPER_ADMIN' && this.state.typeId === 'CONTRACTOR' ?
                            <div className="form-group">
                                <label><b>Sub Contractor Name</b></label>
                                <select
                                    name="location"
                                    onChange={event => this.handleSubContractorNameId(event.target.value)}
                                    style={{ width: "100%" }}
                                    className="form-control"
                                    value={this.state.subContractorNameId}
                                >
                                    {
                                        R.map(
                                        x => <option value={x.id} key={'subcontractor-'+x.id}>{x.subContractorName}</option>,
                                        this.state.subContractorNameOptions || [])
                                    }
                                </select>
                            </div>
                        : null
                    }
                    {/* <div className="form-group">
                        <label><b>Type</b></label>
                        <select onChange={this.handleTypeId}
                                value={this.state.typeId}
                                style={{ width: "100%" }}
                                className="form-control">
                                {R.map(
                                    x => <option value={x.value}>{x.text}</option>,
                                    this.typeOptions)}
                        </select>
                    </div> */}
                    <div className="form-group">
                        <label><b>Phone Number</b></label>
                        <input 
                            type="text"
                            className="form-control"
                            value={this.state.phoneNumber}
                            onChange={this.handlePhoneNumber}/>
                    </div>
                    {this.user && this.user.user.type === "ADMIN" && this.state.typeId === "ADMIN_DEPARTMENT"
                    ?
                    <div className="form-group">
                    <label><b>Other locations</b></label>
                        <Select
                            onChange={this.handleChangeLocations}
                            options={R.filter(locationValue => locationValue.value !== this.state.locationId,
                                            this.state.locationValues || [])}
                            value={this.state.selectedLocationValues}
                            isMulti={true}/>
                    </div>
                    :
                    null}

                {/* {this.user && this.user.user.type === 'ADMIN' && this.state.typeId === 'INSPECTOR'
                ?
                <div className="form-group">
                    <label><b>Department</b></label>
                    <select onChange={event => this.handleDepartmentId(event.target.value)}
                            style={{ width: "100%" }}
                            className="form-control"
                            defaultValue={this.state.departmentId}>
                            {R.map(
                                x => <option value={x.id}>{x.department}</option>,
                                this.state.departmentOptions || [])}
                    </select>
                </div>
                :
                null} */}
                    {/* {this.user && this.user.user.type === 'ADMIN' && this.state.typeId === 'INSPECTOR'
                    ?
                    <div className="form-group">
                        <label><b>Location</b></label>
                        <select onChange={event => this.handleLocationId(event.target.value)}
                                style={{ width: "100%" }}
                                className="form-control"
                                defaultValue={this.state.locationId}>
                                {R.map(
                                    x => <option value={x.id}>{x.location}</option>,
                                    this.state.locationOptions || [])}
                        </select>
                    </div>
                    :
                    null} */}
                    {/* {this.user 
                     && this.user.user.type === 'ADMIN'
                     && this.state.typeId === 'CONTRACTOR'
                    ?
                    <div className="form-group">
                        <label><b>Contractor Name</b></label>
                        <select onChange={event => this.handleContractorNameId(event.target.value)}
                                style={{ width: "100%" }}
                                className="form-control"
                                value={this.state.contractorNameId}>
                                {R.map(
                                    x => <option value={x.id}>{x.contractorName}</option>,
                                    this.state.contractorNameOptions || [])}
                        </select>
                    </div>
                    :
                    null} */}
                    <div className="form-group">
                        <label><b>New password</b></label>
                        <div className="row">
                            <div className="col-md-11">
                            <input 
                                type={this.state.isShowing === "fe fe-eye" ? "text" : "password"}
                                className="form-control"
                                onChange={(event) => this.handlePassword(event.target.value)} />
                            </div>
                            <div className="col-md-1">
                                <button class="btn" 
                                        type="button" 
                                        title={this.state.isShowing === "fe fe-eye" ? "Hide password" : "Show password"}
                                        onClick={this.handleClickShowingPassword}><span className={this.state.isShowing}></span></button>
                            </div>     
                        </div>
                    </div>
                    <div className="form-group">
                        <label><b>Re type new password</b></label>
                        <input 
                            type="password"
                            className="form-control"
                            onChange={(event) => this.handleReTypePassword(event.target.value)} />
                    </div>
                </form>

                {this.state.failedMessage 
                ? <div className="alert alert-danger" role="alert">
                        {this.state.failedMessage}
                  </div> 
                : null}
                <div className="row">
                    <div className="col-md-12">
                        <button className="btn"
                            style={{ width: '100%' }}
                            onClick={this.handleSave}>Save</button>
                    </div>
                </div>
                </BlockUi>
            </div>
        );
    }
}


export default UserEdit;