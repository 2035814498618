import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import axios from 'axios';
import config from '../config';
import { setObject, getObject } from '../utils/localstorage';
import LimitedTextarea from '../utils/textarea_component';

class MasterAnalysisCreatorEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			taskName: '',
			taskNameEdit: '',
			potential: '',
			preventive: '',
			potentialEdit: '',
			preventiveEdit: '',
			failedMessage: '',
			failedMessagePotential: '',
			detailMasterAnalysis: null,
			taskList: [],
			potentialHazardList: []
		};

		this.handleBack = this.handleBack.bind(this);
		this.handleDeleteTask = this.handleDeleteTask.bind(this);
		this.handleTaskNameEdit = this.handleTaskNameEdit.bind(this);
		this.handleSaveTask = this.handleSaveTask.bind(this);
		this.handleSaveEditTask = this.handleSaveEditTask.bind(this);

		// this.handleTaskName = this.handleTaskName.bind(this);
		this.handlePrentiveEdit = this.handlePrentiveEdit.bind(this);
		this.handlePotentialEdit = this.handlePotentialEdit.bind(this);
		this.handleSavePotential = this.handleSavePotential.bind(this);
		this.handleDeletePotential = this.handleDeletePotential.bind(this);
		this.handleRefresh = this.handleRefresh.bind(this);

		// this.handleSave = this.handleSave.bind(this);
		this.closeModal = props.closeModal;
		this.refreshTable = props.refreshTable;
		this.setFailedMessage = props.setFailedMessage;
		this.id = props.id;
		this.user = getObject('user');
	}

	async componentDidMount() {
		this.handleRefresh();
	}

	async handleBack() {
		this.closeModal();
		this.refreshTable({
			page: this.props.tablePaging.page,
			pageSize: this.props.tablePaging.pageSize,
			filtered: []
		});
	}

	handleTaskName(taskName) {
		this.setState({
			failedMessage: ''
		});
		this.setState({
			taskName
		});
	}

	async handleDeleteTask(id) {
		try {
			const response = await axios({
				url: `${config.api.masteranalysis}/task/${id}`,
				method: 'delete',
				headers: { 'x-access-token': this.user.token }
			});
			this.handleRefresh();
		} catch (e) {
		} finally {
		}
	}

	async handleSaveTask() {
		try {
			this.setState({
				isActiveLoadingOverlay: true
			});
			const user = getObject('user');

			const header = {
				headers: { 'x-access-token': user.token }
			};

			if (this.state.taskName !== '') {
				const data = {
					taskName: this.state.taskName,
					analysisId: this.id
				};

				const res = await axios.post(`${config.api.masteranalysis}/task`, data, header);
				this.setState({
					taskName: ''
				});
				this.handleRefresh();
			} else {
				this.setState({
					failedMessage: 'Task required'
				});
			}
		} catch (e) {
		} finally {
			this.setState({
				isActiveLoadingOverlay: false
			});
		}
	}

	handleTaskNameEdit(taskName, index) {
		let markers = [ ...this.state.detailMasterAnalysis.tasks ];
		markers[index] = { ...markers[index], taskName: taskName };
		this.setState((prevState) => ({
			detailMasterAnalysis: {
				...prevState.detailMasterAnalysis,
				tasks: markers
			}
		}));
		// this.setState({
		// 	taskNameEdit: taskName
		// });
	}
	handlePotentialEdit(value) {
		this.setState({
			potentialEdit: value.text
		});
	}
	handlePrentiveEdit(value) {
		this.setState({
			preventiveEdit: value.text
		});
	}
	async handleSaveEditTask(id, index) {
		try {
			this.setState({
				isActiveLoadingOverlay: true
			});
			const user = getObject('user');

			const header = {
				headers: { 'x-access-token': user.token }
			};

			const data = {
				taskName: this.state.detailMasterAnalysis.tasks[index].taskName
			};

			const res = await axios.put(`${config.api.masteranalysis}/task/${id}`, data, header);

			this.handleRefresh();
		} catch (e) {
		} finally {
			this.setState({
				taskNameEdit: '',
				isActiveLoadingOverlay: false
			});
		}
	}
	async handleSaveEditPotentialPreventive(id, potential, preventive, event) {
		event.preventDefault();
		try {
			this.setState({
				isActiveLoadingOverlay: true
			});
			const user = getObject('user');

			const header = {
				headers: { 'x-access-token': user.token }
			};

			if (this.state.potentialEdit === '') {
				this.state.potentialEdit = potential;
			}
			if (this.state.preventiveEdit === '') {
				this.state.preventiveEdit = preventive;
			}

			const data = {
				potential: this.state.potentialEdit,
				preventive: this.state.preventiveEdit
			};

			const res = await axios.put(`${config.api.masteranalysis}/potential/${id}`, data, header);

			this.handleRefresh();
		} catch (e) {
		} finally {
			this.setState({
				potentialEdit: '',
				preventiveEdit: '',
				isActiveLoadingOverlay: false
			});
		}
	}

	async handleRefresh() {
		try {
			this.setState({
				isActiveLoadingOverlay: true,
				detailMasterAnalysis: null
			});

			const masterAnalysisDetailPromise = axios({
				url: `${config.api.masteranalysis}/getdetail/${this.id}`,
				method: 'get',
				headers: { 'x-access-token': this.user.token }
			});

			const [ masterAnalysisDetailResult ] = await Promise.all([ masterAnalysisDetailPromise ]);
			const masterAnalysisDetail = masterAnalysisDetailResult.data.data;
			this.setState({
				detailMasterAnalysis: masterAnalysisDetail
			});
		} catch (e) {
		} finally {
			this.setState({
				isActiveLoadingOverlay: false
			});
		}
	}

	handlePotentialName(potential) {
		this.setState({
			failedMessagePotential: ''
		});
		this.setState({
			potential
		});
	}

	handlePreventiveName(preventive) {
		this.setState({
			failedMessagePotential: ''
		});
		this.setState({
			preventive
		});
	}

	async handleDeletePotential(id) {
		try {
			const response = await axios({
				url: `${config.api.masteranalysis}/potential/${id}`,
				method: 'delete',
				headers: { 'x-access-token': this.user.token }
			});
			this.handleRefresh();
		} catch (e) {
		} finally {
		}
	}

	async handleSavePotential() {
		try {
			this.setState({
				isActiveLoadingOverlay: true
			});
			const user = getObject('user');

			const header = {
				headers: { 'x-access-token': user.token }
			};

			if (this.state.potential && this.state.preventive) {
				const data = {
					potential: this.state.potential,
					preventive: this.state.preventive,
					analysisId: this.id
				};

				const res = await axios.post(`${config.api.masteranalysis}/potential`, data, header);
				this.setState({
					potential: '',
					preventive: ''
				});
				this.handleRefresh();
			} else {
				this.setState({
					failedMessagePotential: 'Potential dan Preventive tidak boleh kosong '
				});
			}
		} catch (e) {
		} finally {
			this.setState({
				isActiveLoadingOverlay: false
			});
		}
	}

	render() {
		return (
			<div>
				<BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
					<br />
					<div className=" row">
						<div className="col-md-6 mx-auto align-self-center">
							<h1 className="">Edit Master Analysis Keyword Database</h1>
						</div>

						<div className="col-md-6 mx-auto">
							<button
								type="button"
								className=" float-right clickable close-icon close"
								onClick={this.handleBack}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>
					<hr />

					<br />
					<div className="row col-12">
						<label className="text-muted">
							<b>Task Keyword / Job Keyword</b>
						</label>
					</div>
					<div className="row col-12">
						<label>Enter some related words</label>
					</div>
					<br />
					<form>
						<div className="form-group">
							<div className="row mb-2">
								<div className="col-10">
									<input
										type="text"
										placeholder="Input task"
										value={this.state.taskName}
										className="form-control"
										onChange={(event) => this.handleTaskName(event.target.value)}
									/>
								</div>
								<div className=" row col-2 align-self-center ">
									<button
										type="button"
										class="btn btn-primary"
										disabled={!this.state.taskName}
										onClick={this.handleSaveTask}
									>
										<span class="fe fe-plus" />
									</button>
								</div>
							</div>

							{this.state.detailMasterAnalysis != null ? (
								this.state.detailMasterAnalysis.tasks.map((v, index) => (
									<div className="row mb-2">
										<div className="col-10 ">
											<input
												type="text"
												value={v.taskName}
												className="form-control float-left master-analysis"
												onChange={(event) => this.handleTaskNameEdit(event.target.value, index)}
											/>
										</div>
										<div className="row col-2">
											<div className="mr-2">
												<button
													type="button"
													disabled={!v.taskName}
													class="btn-primary btn"
													onClick={() => this.handleSaveEditTask(v.id, index)}
												>
													<span class="fe fe-save" />
												</button>
											</div>
											{this.state.detailMasterAnalysis.tasks.length !== 1 ? (
												<div className=" align-self-start">
													<button
														type="button"
														class="btn-danger btn"
														onClick={() => this.handleDeleteTask(v.id)}
													>
														<span class="fe fe-trash" />
													</button>
												</div>
											) : null}
											{/* <div className=" ">
												<button
													type="button"
													class="btn-danger btn"
													onClick={() => this.handleDeleteTask(v.id)}
												>
													<span class="fe fe-trash" />
												</button>
											</div> */}
										</div>
									</div>
								))
							) : null}
						</div>
					</form>

					{this.state.failedMessage ? (
						<div className="alert alert-danger col-4" role="alert">
							{this.state.failedMessage}
						</div>
					) : null}

					{/* <button type="button" class="btn btn-primary btn-sm" onClick={this.handleSaveTask}>
						<span class="fe fe-plus">Add</span>
					</button> */}

					<br />
					<br />
					<br />

					<div className="row col-12">
						<label className="text-muted">
							<b>Potential Hazard & Preventive/Countermeasures </b>
						</label>
					</div>

					<form>
						<div className="form-group">
							<div className="row mb-2 ">
								<div className="col-5">
									<label>Potential Hazard</label>

									<textarea
										class="form-control"
										id="exampleFormControlTextarea1"
										maxLength={10000}
										rows="3"
										value={this.state.potential}
										onChange={(event) => this.handlePotentialName(event.target.value)}
									/>
									<p className="text-muted float-right">
										{this.state.potential ? this.state.potential.length : 0} of 10.000 Character
										remaining
									</p>
								</div>
								<div className="col-5">
									<label>Preventive/Countermeasures</label>
									<textarea
										class="form-control"
										id="exampleFormControlTextarea1"
										maxLength={10000}
										rows="3"
										value={this.state.preventive}
										onChange={(event) => this.handlePreventiveName(event.target.value)}
									/>
									<p className="text-muted float-right">
										{this.state.preventive ? this.state.preventive.length : 0} of 10.000 Character
										remaining
									</p>
									{/* <p className="text-muted float-right">191 of 10.000 Character remaining </p> */}
								</div>
								<div className="row col-2  align-self-center ">
									<button
										type="button"
										class="btn btn-primary"
										disabled={!this.state.potential || !this.state.preventive}
										onClick={this.handleSavePotential}
									>
										<span class="fe fe-plus" />
									</button>
								</div>
							</div>
						</div>
					</form>

					{this.state.detailMasterAnalysis != null ? (
						this.state.detailMasterAnalysis.potensials.map((v) => (
							<form
								key={v.id}
								onSubmit={(event) =>
									this.handleSaveEditPotentialPreventive(v.id, v.potential, v.preventive, event)}
							>
								<div className="form-group">
									<div className="row mb-2">
										<div className="col-5">
											<LimitedTextarea
												limit={10000}
												defaultValue={v.potential}
												rows="3"
												required
												onChange={this.handlePotentialEdit}
											/>
											{/* <textarea
												class="form-control"
												id="exampleFormControlTextarea1"
												rows="1"
												maxLength={10000}
												defaultValue={v.potential}
												required
												onChange={(event) => this.handlePotentialEdit(event.target.value)}
											/>
											<p className="text-muted float-right">18 of 10.000 Character remaining </p> */}
										</div>
										<div className="col-5">
											<LimitedTextarea
												limit={10000}
												defaultValue={v.preventive}
												rows="3"
												onChange={this.handlePrentiveEdit}
												required
											/>
											{/* <textarea
												class="form-control"
												id="exampleFormControlTextarea1"
												maxLength={10000}
												rows="1"
												required
												defaultValue={v.preventive}
												onChange={(event) => this.handlePrentiveEdit(event.target.value)}
											/>
											<p className="text-muted float-right">191 of 10.000 Character remaining </p> */}
										</div>
										<div className="row col-2">
											<div className="mr-2">
												<button
													// type="button"
													class="btn-primary btn"
													type="submit"
													// onClick={() =>
													// 	this.handleSaveEditPotentialPreventive(
													// 		v.id,
													// 		v.potential,
													// 		v.preventive
													// 	)}
												>
													<span class="fe fe-save" />
												</button>
											</div>
											{this.state.detailMasterAnalysis.potensials.length !== 1 ? (
												<div className=" align-self-start">
													<button
														type="button"
														class="btn-danger btn"
														onClick={() => this.handleDeletePotential(v.id)}
													>
														<span class="fe fe-trash" />
													</button>
												</div>
											) : null}
											{/* <div className=" align-self-start">
												<button
													type="button"
													class="btn-danger btn "
													onClick={() => this.handleDeletePotential(v.id)}
												>
													<span class="fe fe-trash" />
												</button>
											</div> */}
										</div>
									</div>
								</div>
							</form>
						))
					) : null}

					{/* <button type="button" class="btn btn-primary btn-sm" onClick={this.handleSavePotential}>
						<span class="fe fe-plus">Add</span>
					</button> */}
					<br />
					<br />

					{this.state.failedMessagePotential ? (
						<div className="alert alert-danger col-4" role="alert">
							{this.state.failedMessagePotential}
						</div>
					) : null}

					<br />
					<br />

					<div className="row" style={{ marginBottom: '10px' }}>
						<div className="col-md-12">
							<button
								className="btn"
								style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
								onClick={this.handleBack}
							>
								Back
							</button>
						</div>
					</div>
				</BlockUi>
			</div>
		);
	}
}

export default MasterAnalysisCreatorEdit;
