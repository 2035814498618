import baseUrl from './url';
const defaultPage = 0;
const defaultPageSize = 10;
const defaultFiltered = [];

export default {
	api: {
		url: baseUrl,
		user: `${baseUrl}/users`,
		inspectiontemplate: `${baseUrl}/inspectiontemplates`,
		sectiontemplate: `${baseUrl}/sectiontemplates`,
		questiontemplate: `${baseUrl}/questiontemplates`,
		company: `${baseUrl}/companies`,
		project: `${baseUrl}/projects`,
		projectcontractor: `${baseUrl}/projectcontractors`,
		inspection: `${baseUrl}/inspections`,
		analysiscreator: `${baseUrl}/analysiscreator`,
		masteranalysis: `${baseUrl}/masteranalysis`,
		contirbutingfactor: `${baseUrl}/contributingfactors`,
		escalationprocess: `${baseUrl}/escalationprocesses`,
		apicontrols: `${baseUrl}/apicontrols`,
		openitems: `${baseUrl}/openitems`,
		d12incident: `${baseUrl}/d12incidents`,
		d13violation: `${baseUrl}/d13violations`,
		severity: `${baseUrl}/severities`,
		stop6: `${baseUrl}/stop6`,
		d13violationtype: `${baseUrl}/d13violationtypes`,
		d13violationconsequence: `${baseUrl}/d13violationconsequences`,
		manhour: `${baseUrl}/manhours`,
		department: `${baseUrl}/departments`,
		location: `${baseUrl}/locations`,
		contractorname: `${baseUrl}/contractornames`,
		subcontractorname: `${baseUrl}/subcontractornames`
	},
	table: {
		defaultState: {
			page: defaultPage,
			pageSize: defaultPageSize,
			filtered: defaultFiltered
		}
	},
	notifDuration: 3000
};
