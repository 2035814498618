import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom';
import { convertToStd } from '../utils/date';
import axios from 'axios';
import config from '../config';
import ReactTable from 'react-table';
import * as R from 'ramda';
import { setObject, getObject } from '../utils/localstorage';

class AnalysisCreatorTemplate extends Component {
	constructor(props) {
		super(props);

		this.filters = [ { id: 'project_name', value: '' } ];

		setObject('lastRoute', 'analysiscreator');
		this.successMessage = '';
		this.state = {
			isShowingCreateAnalysisCreator: true,
			showFiltering: false
		};

		this.page = 0;
		this.pageSize = 10;
		this.handleFilterInput = this.handleFilterInput.bind(this);
		this.handleCloseFilterInput = this.handleCloseFilterInput.bind(this);
		this.fetchData = this.fetchData.bind(this);

		this.clearMessage = this.clearMessage.bind(this);
		this.handleEdit = this.handleEdit.bind(this);
		this.handleView = this.handleView.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
	}

	handleFilterInput() {
		this.setState((state) => ({
			showFiltering: !state.showFiltering
		}));
	}

	handleCloseFilterInput() {
		this.filters = [ { id: 'project_name', value: '' } ];
		this.setState((state) => ({
			showFiltering: !state.showFiltering
		}));

		this.refreshTable({
			page: this.page,
			pageSize: this.pageSize
		});
	}

	fetchData(state, instance) {
		this.clearMessage();
		this.setState({ loading: true });
		this.refreshTable(state);
	}

	refreshTable(state) {
		const user = getObject('user');
		const size = state.pageSize;
		const page = parseInt(state.page) + 1;

		this.page = parseInt(state.page);
		this.pageSize = state.pageSize;

		const filtered = this.filters;

		console.log(filtered);

		let filterString = '';
		const filterXs = R.pipe(R.filter((x) => x.value !== ''), R.map((x) => `${x.id}=${x.value}`))(filtered);

		filterString = filterXs.join('&');

		// const sortedXs = R.map((x) => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`, state.sorted || []);
		// let sortedString = '&orderBy=createdDate&orderDirection=desc';

		// if (sortedXs.length > 0) {
		// 	sortedString = sortedXs.join('&');
		// }

		axios({
			url: `${config.api.analysiscreator}?size=${size}&page=${page}&${filterString}`,
			method: 'get',
			headers: { 'x-access-token': user.token }
		})
			.then((response) => {
				const data = response.data.data;
				console.log(data);
				this.setState({
					data: data.results,
					pages: data.totalPage,
					loading: false
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	clearMessage() {
		this.setState({
			successMessage: '',
			failedMessage: ''
		});
	}

	handleEdit(editId) {
		// this.hideNavBar(true);
		// this.setState({
		//   showEditModal: true,
		//   editId
		// });
	}

	handleView(editId) {
		// this.hideNavBar(true);
		// this.setState({
		//   showEditModal: true,
		//   editId
		// });
	}

	handleSearch(idx, value) {
		this.filters[idx].value = value;

		this.refreshTable({
			page: this.page,
			pageSize: this.pageSize
		});
	}

	render() {
		const columns = [
			{
				Header: 'Action',
				accessor: 'id',
				// id: "inspection",
				width: 150,
				sortable: false,
				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					// console.log('Masuk inspection = ', dataAnalysis);
					return (
						<div className="row justify-content-center">
							<div className="col-sm-12 col-md-6 col-lg-6 mb-2 mb-2">
								<Link to={`/analysiscreator/analysiscreatoredit/${dataAnalysis.id}`} class="btn btn-sm">
									<span class="fefont">Edit</span>
								</Link>
							</div>
							<div className="col-sm-12 col-md-6 col-lg-6 mb-2">
								<Link
									to={`/analysiscreator/analysiscreatordetail/${dataAnalysis.id}`}
									class="btn btn-sm"
								>
									<span class="fefont"> View</span>
								</Link>
							</div>
							{/* <div className="col-md-auto">
								<button
									type="button"
									className="btn btn-sm"
									onClick={() => this.handleView(cellInfo.value)}
								>
									View
								</button>
							</div> */}
						</div>
					);
				}
			},

			{
				Header: 'Project Name',
				accessor: 'project_name'
			},
			{
				Header: 'Description',
				accessor: 'description'
			},
			{
				Header: 'Created date',
				accessor: 'createdDate',
				Cell: (cellInfo) => {
					const date = convertToStd(cellInfo.value);
					if (date == 'Invalid date') {
						return '';
					}
					return date;
				}
			},
			{
				Header: 'Update date',
				accessor: 'updatedDate',
				Cell: (cellInfo) => {
					const date = convertToStd(cellInfo.value);
					if (date == 'Invalid date') {
						return '';
					}
					return date;
				}
			}
		];
		const { data, pages, loading } = this.state;

		return (
			<div>
				<hr />
				<h1>Analysis Creator</h1>
				<hr />
				<div className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
					<div className="col-md-6">
						{this.state.isShowingCreateAnalysisCreator ? (
							// <button type="button" className="btn" onClick={this.handleAdd}>
							// 	<span class="fe fe-plus"> New Analysis</span>
							// </button>

							<Link to={`/analysiscreator/analysiscreatorcreate`} class="btn btn">
								<span class=" fe fe-plus" style={{ marginRight: '2px' }} />
								<span class="fefont"> New Analysis</span>
							</Link>
						) : null}
					</div>
					{!this.state.showFiltering ? (
						<div className="col-md-6">
							<button type="button" className="btn float-right" onClick={this.handleFilterInput}>
								<span class="fe fe-search" />
							</button>
						</div>
					) : null}
				</div>
				{this.state.showFiltering ? (
					<div>
						<div className="card">
							<div className="pull-right" style={{ marginRight: '10px' }}>
								<button
									type="button"
									className="pull-right clickable close-icon close"
									onClick={this.handleCloseFilterInput}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-auto">
										<label>
											<b>Project name</b>
										</label>
										<input
											className="form-control"
											onChange={(e) => this.handleSearch(0, e.target.value)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
				<ReactTable
					columns={columns}
					manual
					data={data}
					pages={pages}
					loading={loading}
					onFetchData={this.fetchData}
					filterable={false}
					defaultPageSize={10}
					className="-striped -highlight"
				/>
				<br />
			</div>
		);
	}
}

export default withRouter(AnalysisCreatorTemplate);
