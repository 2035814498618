import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom';

import ReactDropzone from 'react-dropzone';
import imgDrop from '../imgdrop.png';
import ReactTable from 'react-table';
import axios from 'axios';
import config from '../config';
import * as R from 'ramda';
import baseUrl from '../url';
import ReactModal from 'react-modal';
import LimitedTextarea from '../utils/textarea_component';
import TagInputComponent from '../utils/tagInput_component';
import BlockUi from 'react-block-ui';
import DateTime from 'react-datetime';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import WorkActivityCreate from '../components/WorkActivityCreate';
import WorkActivityEdit from '../components/WorkActivityEdit';

import { setObject, getObject } from '../utils/localstorage';
import DeleteModal from '../components/DeleteModal';

import { convertToStd, convertToYYMMDD } from '../utils/date';
import newId from '../utils/newid';
import { arrayMoveImmutable } from 'array-move';

class AnalysisCreatorTemplateCreate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			image1: null,

			showAddModal: false,
			isBack: false,

			project_name: null,
			description: null,
			location: null,
			column: null,
			line_names: null,
			foreman: null,
			safety_representative: null,
			site_safety_representative: null,
			site_supervisor: null,
			estimate_start_date: null,
			estimate_end_date: null,
			schedule_start_date: null,
			schedule_end_date: null,
			list_equipment: [],
			work_operation_list: [],
			work_operation: null,
			image1Url: null,

			analysis_creator_id: null,
			work_operation_edit: null,
			showDeleteModal: false,
			deleteId: null,
			showToEditModal: false,
			showhOpenEditModel: false,
			work_activity_id: null
		};

		this.handleProjectName = this.handleProjectName.bind(this);
		this.handleDescription = this.handleDescription.bind(this);
		this.handleLocation = this.handleLocation.bind(this);
		this.handleColumn = this.handleColumn.bind(this);
		this.handleLineNames = this.handleLineNames.bind(this);
		this.handleForeman = this.handleForeman.bind(this);
		this.handleSafetyRepresentative = this.handleSafetyRepresentative.bind(this);
		this.handleSiteSupervisor = this.handleSiteSupervisor.bind(this);
		this.handleSiteSafetyRepresentative = this.handleSiteSafetyRepresentative.bind(this);
		this.handleEstimateStartDate = this.handleEstimateStartDate.bind(this);
		this.handleEstimateEndDate = this.handleEstimateEndDate.bind(this);
		this.handleScheduleStartDate = this.handleScheduleStartDate.bind(this);
		this.handleScheduleEndDate = this.handleScheduleEndDate.bind(this);
		this.handleListEquipment = this.handleListEquipment.bind(this);

		this.fetchData = this.fetchData.bind(this);
		this.clearMessage = this.clearMessage.bind(this);
		this.handleAdd = this.handleAdd.bind(this);
		this.hideNavBar = props.hideNavBar;
		this.handleCloseAddModal = this.handleCloseAddModal.bind(this);

		this.onDrop1 = this.onDrop1.bind(this);
		this.readImage = this.readImage.bind(this);
		this.handleCreateAnalysis = this.handleCreateAnalysis.bind(this);
		this.image1 = null;
		this.user = getObject('user');

		this.clearMessage = this.clearMessage.bind(this);
		this.refreshWorkOperation = this.refreshWorkOperation.bind(this);
		this.handleDeleteWorkOperation = this.handleDeleteWorkOperation.bind(this);
		this.handleSaveEditWorkOperation = this.handleSaveEditWorkOperation.bind(this);
		this.handleEditAnalysis = this.handleEditAnalysis.bind(this);
		this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
		this.handleToEdit = this.handleToEdit.bind(this);
		this.handleCloseToEdit = this.handleCloseToEdit.bind(this);
		this.handleOpenEditModel = this.handleOpenEditModel.bind(this);
		this.handleCloseEditModel = this.handleCloseEditModel.bind(this);
		this.handleAddWorkOperation = this.handleAddWorkOperation.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.removeFromList = this.removeFromList.bind(this);
		this.addToList = this.addToList.bind(this);
		this.handleSaveWorkOperation = this.handleSaveWorkOperation.bind(this);

		this.nowTime = moment();
		const timeFormatted = this.nowTime.format('YYYY-MM-DD HH:mm:ss');
	}

	handleCloseEditModel() {
		this.hideNavBar(false);
		this.setState({
			showhOpenEditModel: false,
			showToEditModal: false,
			work_activity_id: null
		});
	}
	handleOpenEditModel() {
		this.setState({
			showhOpenEditModel: true,
			showToEditModal: false
		});
	}
	handleDelete(deleteId) {
		this.setState({
			showDeleteModal: true,
			deleteId
		});
	}

	clearMessage() {
		this.setState({
			successMessage: '',
			failedMessage: ''
		});
	}

	setFailedMessage(failedMessage) {
		this.setState({
			failedMessage,
			successMessage: ''
		});
	}

	handleworkOperationDescription(work_operation) {
		this.setState({
			work_operation
		});
	}
	handleAddWorkOperation() {
		let markers = [
			...this.state.work_operation_list,
			{
				id: newId(),
				description: this.state.work_operation
			}
		];

		this.setState({
			work_operation_list: markers,
			work_operation: ''
		});
	}

	handleListEquipment(list_equipment) {
		this.setState({
			list_equipment: list_equipment.tagData
		});
	}
	handleScheduleEndDate(value) {
		if (moment(value, 'YYYY-MM-DD').isValid()) {
			this.setState({
				schedule_end_date: value.format('YYYY-MM-DD')
			});
		} else {
			this.setState({
				schedule_end_date: null
			});
		}
	}
	handleScheduleStartDate(value) {
		if (moment(value, 'YYYY-MM-DD').isValid()) {
			this.setState({
				schedule_start_date: value.format('YYYY-MM-DD')
			});
		} else {
			this.setState({
				schedule_start_date: null
			});
		}
	}
	handleEstimateEndDate(value) {
		if (moment(value, 'YYYY-MM-DD').isValid()) {
			this.setState({
				estimate_end_date: value.format('YYYY-MM-DD')
			});
		} else {
			this.setState({
				estimate_end_date: null
			});
		}
	}
	handleEstimateStartDate(value) {
		if (moment(value, 'YYYY-MM-DD').isValid()) {
			this.setState({
				estimate_start_date: value.format('YYYY-MM-DD')
			});
		} else {
			this.setState({
				estimate_start_date: null
			});
		}
	}
	handleSiteSafetyRepresentative(site_safety_representative) {
		this.setState({
			site_safety_representative
		});
	}
	handleSiteSupervisor(site_supervisor) {
		this.setState({
			site_supervisor
		});
	}

	handleSafetyRepresentative(safety_representative) {
		this.setState({
			safety_representative
		});
	}
	handleForeman(foreman) {
		this.setState({
			foreman
		});
	}
	handleLineNames(line_names) {
		this.setState({
			line_names
		});
	}
	handleColumn(column) {
		this.setState({
			column
		});
	}
	handleLocation(location) {
		this.setState({
			location
		});
	}
	handleDescription(description) {
		this.setState({
			description
		});
	}
	handleProjectName(project_name) {
		this.setState({
			project_name
		});
	}

	clearMessage() {
		this.setState({
			successMessage: '',
			failedMessage: ''
		});
	}

	onCloseImage1(e) {
		e.preventDefault();
		this.setState({
			image1Url: null,
			isImage1Present: false
		});
	}

	handleAdd() {
		this.hideNavBar(true);
		this.setState({
			showAddModal: true
		});
	}
	handleToEdit(value) {
		this.hideNavBar(true);
		this.setState({
			showToEditModal: true,
			work_activity_id: value
		});
	}

	readImage(pictureFiles, onLoadEndSetState) {
		const reader = new FileReader();
		reader.readAsDataURL(pictureFiles[0]);

		reader.onload = () => {
			this.setState(() => onLoadEndSetState(reader.result, pictureFiles.length === 0 ? null : pictureFiles));
		};
	}

	onDrop1(pictureFiles) {
		this.readImage(pictureFiles, (image1Url, image1) => ({
			image1Url,
			image1
		}));
	}

	fetchData(state, instance) {
		this.clearMessage();
		this.setState({ loading: true });
		this.refreshTable(state);
	}

	handleCloseToEdit() {
		this.hideNavBar(false);
		this.setState({
			showToEditModal: false
		});
	}
	handleCloseAddModal() {
		this.hideNavBar(false);
		this.setState({
			showAddModal: false
		});
	}

	refreshWorkOperation(idAnalysisCreator) {
		const user = getObject('user');

		this.setState({
			work_operation_list: []
		});

		axios({
			url: `${config.api.analysiscreator}/list_work_operation/${idAnalysisCreator}`,
			method: 'get',
			headers: { 'x-access-token': user.token }
		})
			.then((response) => {
				const data = response.data.data;
				console.log(data);
				this.setState({
					work_operation_list: data,
					loading: false
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	refreshTable(state) {
		const user = getObject('user');
		const size = state.pageSize;
		const page = parseInt(state.page) + 1;

		this.page = parseInt(state.page);
		this.pageSize = state.pageSize;

		// const filtered = this.filters;

		// // console.log(filtered);

		// let filterString = '';
		// const filterXs = R.pipe(R.filter((x) => x.value !== ''), R.map((x) => `${x.id}=${x.value}`))(filtered);

		// filterString = filterXs.join('&');

		// const sortedXs = R.map((x) => `orderBy=${x.id}&orderDirection=${x.desc ? 'desc' : 'asc'}`, state.sorted || []);
		// let sortedString = '&orderBy=createdDate&orderDirection=desc';

		// if (sortedXs.length > 0) {
		// 	sortedString = sortedXs.join('&');
		// }

		axios({
			url: `${config.api.analysiscreator}/work_activity_all/${this.state
				.analysis_creator_id}?size=${size}&page=${page}`,
			method: 'get',
			headers: { 'x-access-token': user.token }
		})
			.then((response) => {
				const data = response.data.data;

				this.setState({
					data: data.results,
					pages: data.totalPage,
					loading: false
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}

	handleEditWorkOperation(value, id) {
		let markers = [ ...this.state.work_operation_list ];
		const fromIndex = markers.map((x) => x.id).indexOf(id);

		markers[fromIndex] = { ...markers[fromIndex], description: value };
		this.setState({
			work_operation_list: markers
		});
		// this.setState({
		// 	work_operation_edit
		// });
	}

	async handleSaveEditWorkOperation(id) {
		try {
			this.setState({
				isActiveLoadingOverlay: true
			});
			const dataWorkOperation = {
				description: this.state.work_operation_edit
			};
			const response = await axios({
				url: `${config.api.analysiscreator}/work_operation/${id}`,
				method: 'put',
				data: dataWorkOperation,
				headers: { 'x-access-token': this.user.token }
			});
			this.refreshWorkOperation(this.state.analysis_creator_id);
		} catch (e) {
		} finally {
			this.setState({
				isActiveLoadingOverlay: false,
				work_operation_edit: ''
			});
		}
	}

	async handleDeleteWorkOperation(id) {
		this.setState((prevState) => ({
			work_operation_list: prevState.work_operation_list.filter((v) => v.id !== id)
		}));
		// try {
		// 	this.setState({
		// 		isActiveLoadingOverlay: true
		// 	});

		// 	const response = await axios({
		// 		url: `${config.api.analysiscreator}/work_operation/${id}`,
		// 		method: 'delete',

		// 		headers: { 'x-access-token': this.user.token }
		// 	});
		// 	this.refreshWorkOperation(this.state.analysis_creator_id);
		// } catch (e) {
		// } finally {
		// 	this.setState({
		// 		isActiveLoadingOverlay: false
		// 	});
		// }
	}

	async handleCreateAnalysis(event) {
		event.preventDefault();

		try {
			this.clearMessage();
			if (this.state.image1Url) {
				if (this.state.list_equipment.length !== 0) {
					this.setState({
						isActiveLoadingOverlay: true
					});
					const user = getObject('user');

					const header = {
						headers: { 'x-access-token': user.token }
					};
					if (!this.state.analysis_creator_id) {
						const data = {
							project_name: this.state.project_name,
							description: this.state.description,
							location: this.state.location,
							column: this.state.column,
							line_names: this.state.line_names,
							foreman: this.state.foreman,
							safety_representative: this.state.safety_representative,
							site_safety_supervisor: this.state.site_safety_representative,
							site_supervisor: this.state.site_supervisor,
							estimate_start_date: this.state.estimate_start_date,
							estimate_end_date: this.state.estimate_end_date,
							schedule_start_date: this.state.schedule_start_date,
							schedule_end_date: this.state.schedule_end_date,
							list_equipment: this.state.list_equipment.toString(),
							logo: this.state.image1Url
						};

						const res = await axios.post(`${config.api.analysiscreator}`, data, header);

						this.setState({
							analysis_creator_id: res.data.data.id
						});
					}

					this.handleAddWorkOperation();

					// const dataWorkOperation = {
					// 	description: this.state.work_operation,
					// 	analysis_creator_id: this.state.analysis_creator_id
					// };

					// const resWorkOperation = await axios.post(
					// 	`${config.api.analysiscreator}/work_operation`,
					// 	dataWorkOperation,
					// 	header
					// );
					// this.refreshWorkOperation(this.state.analysis_creator_id);

					// console.log(resWorkOperation);
				} else {
					this.setState({
						failedMessage: 'Personal protective equipment required',
						isActiveLoadingOverlay: false
					});
				}
			} else {
				this.setState({
					failedMessage: 'Image required',
					isActiveLoadingOverlay: false
				});
			}
		} catch (e) {
			this.setState({
				isActiveLoadingOverlay: false
			});
		} finally {
			this.setState({
				work_operation: '',
				isActiveLoadingOverlay: false
			});
			// this.closeModal();
		}
	}

	async handleSaveWorkOperation() {
		try {
			// this.setState({
			// 	isActiveLoadingOverlay: true
			// });
			const dataWorkOperation = {
				analysis_creator_id: this.state.analysis_creator_id,
				data: this.state.work_operation_list.map((v) => ({
					description: v.description
				}))
			};
			const response = await axios({
				url: `${config.api.analysiscreator}/work_operation/multiple`,
				method: 'post',
				data: dataWorkOperation,
				headers: { 'x-access-token': this.user.token }
			});
			// this.refreshWorkOperation(this.state.analysis_creator_id);
		} catch (e) {
		} finally {
			this.setState({
				isActiveLoadingOverlay: false,
				work_operation_edit: ''
			});
		}
	}

	async handleEditAnalysis() {
		try {
			this.setState({
				isActiveLoadingOverlay: true,
				failedMessagePut: ''
			});
			const user = getObject('user');

			const header = {
				headers: { 'x-access-token': user.token }
			};
			const data = {
				project_name: this.state.project_name,
				description: this.state.description,
				location: this.state.location,
				column: this.state.column,
				line_names: this.state.line_names,
				foreman: this.state.foreman,
				safety_representative: this.state.safety_representative,
				site_safety_supervisor: this.state.site_safety_representative,
				site_supervisor: this.state.site_supervisor,
				estimate_start_date: this.state.estimate_start_date,
				estimate_end_date: this.state.estimate_end_date,
				schedule_start_date: this.state.schedule_start_date,
				schedule_end_date: this.state.schedule_end_date,
				list_equipment: this.state.list_equipment.toString(),
				logo: this.state.image1Url
			};
			const res = await axios.put(
				`${config.api.analysiscreator}/${this.state.analysis_creator_id}`,
				data,
				header
			);
			await this.handleSaveWorkOperation();
			this.setState({
				isBack: true
			});
		} catch (e) {
			this.setState({
				failedMessagePut: e.message
			});
		} finally {
			this.setState({
				isActiveLoadingOverlay: false
			});
		}
	}

	handleCloseDeleteModal() {
		this.setState({
			showDeleteModal: false
		});
	}

	removeFromList(list, index) {
		const result = list;
		const [ removed ] = result.splice(index, 1);
		return [ removed, result ];
	}

	addToList(list, index, element) {
		const result = list;
		result.splice(index, 0, element);
		return result;
	}

	async onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		let arr = [ ...this.state.work_operation_list ];

		const fromIndex = arr.map((x) => x.id).indexOf(result.draggableId);
		const toIndex = result.destination.index;

		var newArry = arrayMoveImmutable(arr, fromIndex, toIndex);

		this.state.work_operation_list = newArry;

		this.forceUpdate();
	}

	render() {
		if (this.state.isBack) {
			return <Redirect to="/analysiscreator" />;
		}

		const columns = [
			{
				Header: 'Action',
				accessor: 'id',
				// id: "inspection",
				width: 150,
				sortable: false,
				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					// console.log('Masuk inspection = ', dataAnalysis);
					return (
						<div className="row">
							<div className="col-md-auto">
								<button
									type="button"
									className="btn btn-sm"
									onClick={() => this.handleToEdit(cellInfo.value)}
								>
									Edit
								</button>
							</div>
							<div className="col-md-auto">
								<button
									type="button"
									className="btn btn-sm"
									onClick={() => this.handleDelete(cellInfo.value)}
								>
									Delete
								</button>
							</div>
						</div>
					);
				}
			},

			{
				Header: 'Work Activity',
				accessor: 'task',
				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					const image = `${baseUrl}/${dataAnalysis.image}`;
					// console.log('Masuk inspection = ', dataAnalysis);
					return (
						<div className="col-md-auto">
							<div className="col-md-auto">
								<label>{dataAnalysis.task}</label>
							</div>
							{dataAnalysis.image ? (
								<div className="col-md-auto">
									<img
										src={image}
										width="200"
										height="140"
										className="img-fluid"
										alt="Responsive image"
									/>
								</div>
							) : null}
							{/* <div className="col-md-auto">
								<img src={image} width="200" height="140" class="img-fluid" alt="Responsive image" />
							</div> */}
						</div>
					);
				}
			},
			{
				Header: 'Potential Hazard',
				accessor: 'workResult',
				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					// console.log('Potential Hazard = ', dataAnalysis);
					return (
						<div>
							{dataAnalysis.workResult.map((v) => (
								<div className="col-md-auto">
									<p>{v.potensial} </p>
								</div>
							))}
						</div>
					);
				}
			},
			{
				Header: 'Preventive/Countermeasures',
				accessor: 'workResult',
				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					const image = `${baseUrl}/${dataAnalysis.image}`;

					// console.log('Preventive/Countermeasures = ', dataAnalysis);
					return (
						<div>
							{dataAnalysis.workResult.map((v) => (
								<div className="col-md-auto">
									<div className="col-md-auto">
										<p>{v.preventive} </p>
									</div>
									{v.image ? (
										<div className="col-md-auto">
											<img
												src={`${baseUrl}/${v.image}`}
												width="200"
												height="140"
												className="img-fluid"
												alt="Responsive image"
											/>
										</div>
									) : null}
								</div>
							))}
						</div>
					);
				}
			}
		];
		const { data, pages, loading } = this.state;
		const previewStyle = {
			display: 'inline',
			width: 250,
			height: 250
		};
		var modalStyles = { overlay: { zIndex: 10 } };

		return (
			<div>
				<BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
					<br />
					<h1>Create Analysis</h1>
					<hr />
					<br />
					<form key={0} onSubmit={(event) => this.handleCreateAnalysis(event)}>
						<div className="col-12">
							<label className="text-muted">
								<b>COMPANY LOGO</b>
							</label>
						</div>
						<div className="col-12">
							<div className="border rounded">
								<ReactDropzone
									className="dragAndDropArea d-flex align-items-center justify-content-center"
									style={{ height: 250 }}
									onDrop={this.onDrop1}
									accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
									multiple={false}
									required
									accept="image/*"
								>
									{this.state.image1Url ? (
										<img
											className="profile-panel DragnDropDP"
											key={this.state.image1Url}
											src={this.state.image1Url}
											style={{ height: 250 }}
										/>
									) : (
										<div>
											<label className="mx-auto text-muted">
												{' '}
												Drag a photo here or click to upload.
											</label>
										</div>
									)}

									{/* <div className="img mx-auto">
								<i class="fe fe-image fa-4x material-img-icons icon-100" />
								<img
									className="profile-panel DragnDropDP"
									key={this.state.image1Url}
									src={this.state.image1Url}
								/>
							</div> */}
								</ReactDropzone>
							</div>
						</div>
						<br />
						<br />
						<div className=" col-12">
							<label className="text-muted">
								<b>JOB INFORMATION</b>
							</label>
						</div>

						<div className="col-12">
							<label>Project Name</label>
							<input
								type="text"
								placeholder="Input project name "
								value={this.state.project_name}
								required
								className="form-control mb-3"
								onChange={(event) => this.handleProjectName(event.target.value)}
							/>

							<label>Description of Work</label>
							<textarea
								className="form-control mb-3"
								id="exampleFormControlTextarea1"
								maxLength={10000}
								rows="3"
								required
								value={this.state.description}
								onChange={(event) => this.handleDescription(event.target.value)}
							/>
							<div className="row justify-content-between">
								<div className=" col-4">
									<label>Location</label>
									<input
										type="text"
										placeholder="Input location "
										required
										value={this.state.location}
										className="form-control mb-3"
										onChange={(event) => this.handleLocation(event.target.value)}
									/>
								</div>
								<div className=" col-4">
									<label>Column(s)</label>
									<input
										type="text"
										placeholder="Input column "
										required
										value={this.state.column}
										className="form-control mb-3"
										onChange={(event) => this.handleColumn(event.target.value)}
									/>
								</div>
								<div className=" col-4">
									<label>Line Name(s)</label>
									<input
										type="text"
										placeholder="Input line name "
										required
										value={this.state.line_names}
										className="form-control mb-3"
										onChange={(event) => this.handleLineNames(event.target.value)}
									/>
								</div>
							</div>
						</div>
						<br />

						<div className=" col-12">
							<label className="text-muted">
								<b>CONTACT INFORMATION</b>
							</label>
						</div>

						<div className="col-12">
							<label>Foreman/Supervisor Name</label>
							<input
								type="text"
								placeholder="Input foreman/supervisor name"
								required
								value={this.state.foreman}
								className="form-control mb-3"
								onChange={(event) => this.handleForeman(event.target.value)}
							/>
							<label>Safety Representative</label>
							<input
								type="text"
								required
								placeholder="Input safety representative"
								value={this.state.safety_representative}
								className="form-control mb-3"
								onChange={(event) => this.handleSafetyRepresentative(event.target.value)}
							/>
							<label>Site Supervisor</label>
							<input
								type="text"
								placeholder="Input site supervisor"
								required
								value={this.state.site_supervisor}
								className="form-control mb-3"
								onChange={(event) => this.handleSiteSupervisor(event.target.value)}
							/>
							<label>Site Safety Representative</label>
							<input
								type="text"
								placeholder="Input site safety representative"
								required
								value={this.state.site_safety_representative}
								className="form-control mb-3"
								onChange={(event) => this.handleSiteSafetyRepresentative(event.target.value)}
							/>
						</div>

						<br />
						<div className=" col-12">
							<label className="text-muted">
								<b>ESTIMATED WORK DATES</b>
							</label>
							<div className="row justify-content-between">
								<div className="col-6">
									<label>Start Date</label>

									<DateTime
										onChange={this.handleEstimateStartDate}
										closeOnSelect={true}
										closeOnTab={true}
										dateFormat={true}
										timeFormat={false}
										// renderInput={(props, openCalendar, closeCalendar) => (
										// 	<input {...props} type="button" required data-readonly />
										// )}
										inputProps={{
											// readOnly: true,

											placeholder: 'Start Date',
											required: true,
											value: `${this.state.estimate_start_date
												? convertToStd(this.state.estimate_start_date)
												: ''}`
										}}
										value={convertToStd(this.state.estimate_start_date)}
									/>
								</div>
								<div className=" col-6">
									<label>End Date</label>
									<DateTime
										onChange={this.handleEstimateEndDate}
										closeOnSelect={true}
										closeOnTab={true}
										dateFormat={true}
										timeFormat={false}
										// renderInput={(props) => <input {...props} required data-readonly />}
										inputProps={{
											placeholder: 'End Date',
											required: true,
											value: `${this.state.estimate_end_date
												? convertToStd(this.state.estimate_end_date)
												: ''}`
										}}
										value={convertToStd(this.state.estimate_end_date)}
									/>
								</div>
							</div>
						</div>

						<br />

						<div className=" col-12">
							<label className="text-muted">
								<b>WORK SCHEDULE</b>
							</label>
							<div className="row justify-content-between">
								<div className=" col-6">
									<label>Start Date</label>
									<DateTime
										onChange={this.handleScheduleStartDate}
										closeOnSelect={true}
										closeOnTab={true}
										dateFormat={true}
										timeFormat={false}
										// renderInput={(props) => <input {...props} required data-readonly />}
										inputProps={{
											placeholder: 'Start Date',
											required: true,
											value: `${this.state.schedule_start_date
												? convertToStd(this.state.schedule_start_date)
												: ''}`
										}}
										value={convertToStd(this.state.schedule_start_date)}
									/>
								</div>
								<div className=" col-6">
									<label>End Date</label>
									<DateTime
										onChange={this.handleScheduleEndDate}
										closeOnSelect={true}
										closeOnTab={true}
										dateFormat={true}
										timeFormat={false}
										// renderInput={(props) => <input {...props} required data-readonly />}
										inputProps={{
											placeholder: 'End Date',
											required: true,
											value: `${this.state.schedule_end_date
												? convertToStd(this.state.schedule_end_date)
												: ''}`
										}}
										value={convertToStd(this.state.schedule_end_date)}
									/>
								</div>
							</div>
						</div>

						<br />

						<div className=" col-12">
							<label className="text-muted">
								<b>PPE</b>
							</label>
						</div>

						<div className="col-12">
							<label>Required Personal Protective Equipment</label>
							<TagInputComponent tags={this.state.list_equipment} onChange={this.handleListEquipment} />
							{/* <input
								type="text"
								placeholder="Input project name "
								required
								value={this.state.list_equipment}
								className="form-control mb-3"
								onChange={(event) => this.handleListEquipment(event.target.value)}
							/> */}
							<p className="text-muted float-left">Input Multiple Item, press enter to add Item</p>
						</div>
						<br />
						<br />

						<div className=" col-12">
							<label className="text-muted">
								<b>WORK OPERATION</b>
							</label>
						</div>

						<div className="col-12">
							<label>Work Operation Description</label>
							{/* <LimitedTextarea
								limit={10000}
								required
								defaultValue={this.state.work_operation}
								rows="1"
								onChange={(event) => this.handleworkOperationDescription(event.text)}
							/> */}

							<div className="row mb-2">
								<div className="col-10">
									<textarea
										class="form-control"
										id="exampleFormControlTextarea1"
										maxLength={10000}
										rows="1"
										value={this.state.work_operation}
										onChange={(event) => this.handleworkOperationDescription(event.target.value)}
									/>
									<p className="text-muted float-right">
										{this.state.work_operation ? this.state.work_operation.length : 0} of 10.000
										Character remaining
									</p>
								</div>
								<div className="row col-2 align-self-start">
									<button type="submit" class="btn btn-primary" disabled={!this.state.work_operation}>
										<span class="fe fe-plus" />
									</button>
								</div>
								{/* <div className="row col-2 align-self-start">
									<button
										onClick={() => this.handleAddWorkOperation()}
										type="button"
										class="btn btn-primary"
										disabled={!this.state.work_operation}
									>
										<span class="fe fe-plus" />
									</button>
								</div> */}
							</div>
						</div>

						<br />

						{this.state.work_operation_list ? (
							<DragDropContext onDragEnd={this.onDragEnd}>
								<Droppable droppableId="droppable">
									{(provided, snapshot) => (
										<div {...provided.droppableProps} ref={provided.innerRef}>
											{this.state.work_operation_list.map((item, index) => (
												<Draggable key={item.id} draggableId={item.id} index={index}>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															snapshot={snapshot}
														>
															<div className="row col-12 mb-3 ">
																<div className="col-10">
																	{/* <LimitedTextarea
																		id={item.id}
																		limit={10000}
																		required
																		defaultValue={item.description}
																		rows="1"
																		onChange={(event) =>
																			this.handleEditWorkOperation(event.text)}
																	/> */}
																	<textarea
																		class="form-control"
																		id={item.id}
																		maxLength={10000}
																		required
																		rows="1"
																		placeholder=""
																		value={item.description}
																		onChange={(event) =>
																			this.handleEditWorkOperation(
																				event.target.value,
																				item.id
																			)}
																	/>
																	<p className="text-muted float-right">
																		{item.description ? item.description.length : 0}{' '}
																		of 10.000 Character remaining
																	</p>
																</div>
																<div className="row col-2">
																	{/* <div className="mr-2">
																		<button
																			type="button"
																			class="btn-primary btn"
																			onClick={() =>
																				this.handleSaveEditWorkOperation(
																					item.id
																				)}
																		>
																			<span class="fe fe-save" />
																		</button>
																	</div> */}
																	{this.state.work_operation_list.length !== 1 ? (
																		<div className="mr-1">
																			<button
																				type="button"
																				class="btn-danger btn"
																				onClick={() =>
																					this.handleDeleteWorkOperation(
																						item.id
																					)}
																			>
																				<span class="fe fe-trash" />
																			</button>
																		</div>
																	) : null}
																	<div className="icon-color-drag">
																		{/* <span class="fe fe-grid" /> */}
																		<div class="btn-info btn disabled">
																			<span class="fe fe-grid" />
																		</div>
																	</div>

																	{/* <div className=" ml-auto icon-color-drag">
																		<span class="fe fe-grid" />
																	</div> */}
																</div>
															</div>
														</div>
													)}
												</Draggable>
											))}
										</div>
									)}
								</Droppable>
							</DragDropContext>
						) : // this.state.work_operation_list.map((v, index) => (

						// ))
						null}

						<br />

						{this.state.failedMessage ? (
							<div className="col-12">
								<div className="alert alert-danger  " role="alert">
									{this.state.failedMessage}
								</div>
							</div>
						) : null}

						<br />
						{this.state.analysis_creator_id ? (
							<div>
								<div className=" col-12">
									<label className="text-muted">
										<b>WORK ACTIVITY</b>
									</label>
								</div>
								<div className="col-12">
									<button
										type="button"
										disabled={!this.state.analysis_creator_id}
										className="btn"
										onClick={this.handleAdd}
									>
										<div className="row " style={{ marginRight: '5px', marginLeft: '5px' }}>
											<span className=" fe fe-plus" style={{ marginRight: '2px' }} />
											<span className="fefont"> New Task</span>
										</div>
									</button>
								</div>
								<br />
								<ReactModal
									isOpen={this.state.showDeleteModal}
									contentLabel="Minimal Modal Example"
									style={{
										content: {
											top: '50%',
											left: '50%',
											right: 'auto',
											bottom: 'auto',
											marginRight: '-50%',
											transform: 'translate(-50%, -50%)'
										}
									}}
								>
									<DeleteModal
										closeModal={this.handleCloseDeleteModal}
										id={this.state.deleteId}
										refreshTable={this.fetchData}
										setSuccessMessage={this.setSuccessMessage}
										setFailedMessage={this.setFailedMessage}
										deleteUrl={`${config.api.analysiscreator}/work_activity`}
									/>
								</ReactModal>
								<ReactModal
									isOpen={this.state.showhOpenEditModel}
									contentLabel="Minimal Modal Example"
									style={modalStyles}
								>
									<WorkActivityEdit
										closeModal={this.handleCloseEditModel}
										refreshTable={this.fetchData}
										analysis_creator_id={this.state.analysis_creator_id}
										work_activity_id={this.state.work_activity_id}
										tablePaging={{
											page: this.page,
											pageSize: this.pageSize
										}}
										setFailedMessage={this.setFailedMessage}
										setSuccessMessage={this.setSuccessMessage}
									/>
								</ReactModal>
								<ReactModal
									isOpen={this.state.showAddModal}
									contentLabel="Minimal Modal Example"
									style={modalStyles}
								>
									<WorkActivityCreate
										closeModal={this.handleCloseAddModal}
										refreshTable={this.fetchData}
										analysis_creator_id={this.state.analysis_creator_id}
										tablePaging={{
											page: this.page,
											pageSize: this.pageSize
										}}
										setFailedMessage={this.setFailedMessage}
										setSuccessMessage={this.setSuccessMessage}
									/>
								</ReactModal>
								<ReactModal
									isOpen={this.state.showToEditModal}
									contentLabel="Minimal Modal Example"
									style={{
										content: {
											top: '50%',
											left: '50%',
											right: 'auto',
											bottom: 'auto',
											marginRight: '-50%',
											transform: 'translate(-50%, -50%)'
										}
									}}
								>
									<div>
										<div className="align-middle">
											<div className="form-group">
												{
													'Are you sure want to edit this task data? the automatically generated data system will be lost'
												}
											</div>
										</div>
										<div className="row" style={{ marginBottom: '10px' }}>
											<div className="col-md-6">
												<button
													className="btn"
													style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
													onClick={this.handleOpenEditModel}
												>
													Yes
												</button>
											</div>
											<div className="col-md-6">
												<button
													className="btn"
													style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
													onClick={this.handleCloseToEdit}
												>
													No
												</button>
											</div>
										</div>
									</div>
								</ReactModal>
								<div className="col-12">
									<ReactTable
										columns={columns}
										manual
										data={data}
										pages={pages}
										loading={loading}
										onFetchData={this.fetchData}
										filterable={false}
										defaultPageSize={10}
										className="-striped -highlight"
									/>
								</div>
								<br />
								<br />
								<div className="col-12">
									<button
										type="button"
										style={{ width: '100%' }}
										className="btn"
										disabled={!this.state.analysis_creator_id}
										onClick={this.handleEditAnalysis}
									>
										<span className="fefont">Save</span>
									</button>
								</div>
								<br />
								{this.state.failedMessagePut ? (
									<div className="col-12">
										<div className="alert alert-danger  " role="alert">
											{this.state.failedMessagePut}
										</div>
									</div>
								) : null}
							</div>
						) : null}
					</form>
				</BlockUi>
			</div>
		);
	}
}

export default withRouter(AnalysisCreatorTemplateCreate);
