import React, { Component } from 'react';
import ReactDropzone from 'react-dropzone';
import imgDrop from '../imgdrop.png';
import { setObject, getObject } from '../utils/localstorage';
import axios from 'axios';
import config from '../config';
import BlockUi from 'react-block-ui';
import LimitedTextarea from '../utils/textarea_component';
import update from 'react-addons-update';

export default class WorkActivityCreate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			task: null,
			imageUrl: null,
			similarity: null,
			listSimilarity: [],
			loading: false,
			messageFail: null
		};

		this.handleBack = this.handleBack.bind(this);
		this.handleTask = this.handleTask.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.readImage = this.readImage.bind(this);
		this.getSimilarity = this.getSimilarity.bind(this);
		this.createWorkActivity = this.createWorkActivity.bind(this);
		this.createWorkResult = this.createWorkResult.bind(this);
		this.handleEditPotential = this.handleEditPotential.bind(this);
		this.handleEditPreventive = this.handleEditPreventive.bind(this);

		this.closeModal = props.closeModal;
		this.refreshTable = props.refreshTable;
		this.analysis_creator_id = props.analysis_creator_id;
	}

	async componentDidMount() {
		this.createListSimilarity();
	}

	createListSimilarity() {
		let newlist = [
			{
				potential: '',
				preventive: ''
			}
		];
		this.setState({
			listSimilarity: newlist
		});
	}

	async handleBack() {
		this.closeModal();
		this.refreshTable({
			page: this.props.tablePaging.page,
			pageSize: this.props.tablePaging.pageSize,
			filtered: []
		});
	}

	handleTask(task) {
		this.setState({
			task
		});
	}

	onDrop(pictureFiles) {
		this.readImage(pictureFiles, (imageUrl, image1) => ({
			imageUrl,
			image1
		}));
	}

	readImage(pictureFiles, onLoadEndSetState) {
		const reader = new FileReader();
		reader.readAsDataURL(pictureFiles[0]);

		reader.onload = () => {
			this.setState(() => onLoadEndSetState(reader.result, pictureFiles.length === 0 ? null : pictureFiles));
		};
	}

	async getSimilarity(event) {
		// event.preventDefault();
		this.setState({
			task: event
		});

		if (event.length >= 4) {
			const user = getObject('user');

			const data = {
				message: event
				// message: event.target.value
			};

			this.setState({
				loading: true
			});

			await axios({
				url: `${config.api.analysiscreator}/similarity`,
				method: 'post',
				data: data,
				headers: { 'x-access-token': user.token }
			})
				.then((response) => {
					const data = response.data.message;

					if (data.length !== 0) {
						this.setState({
							listSimilarity: data,
							loading: false
						});
					} else {
						this.setState({
							loading: false
						});
						this.createListSimilarity();
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			this.createListSimilarity();
		}
	}

	createWorkActivity() {
		if (this.state.listSimilarity.length !== 0) {
			const user = getObject('user');

			const data = {
				task: this.state.task,
				analysis_creator_id: `${this.analysis_creator_id}`,
				image: this.state.imageUrl
			};
			if (!this.state.imageUrl) {
				delete data.image;
			}

			this.setState({
				messageFail: '',
				isActiveLoadingOverlay: true
			});

			axios({
				url: `${config.api.analysiscreator}/work_activity`,
				method: 'post',
				data: data,
				headers: { 'x-access-token': user.token }
			})
				.then((response) => {
					const data = response.data.data;

					this.createWorkResult(data.id);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			this.setState({
				messageFail: "Potential Hazard and Preventive/Countermeasures doesn't exist"
			});
		}
	}

	createWorkResult(id) {
		if (this.state.listSimilarity) {
			const user = getObject('user');

			const listData = this.state.listSimilarity.map((v) => ({
				potensial: v.potential,
				preventive: v.preventive
			}));

			const data = {
				work_activity_id: id,
				data: listData
			};

			axios({
				url: `${config.api.analysiscreator}/work_result/multiple`,
				method: 'post',
				data: data,
				headers: { 'x-access-token': user.token }
			})
				.then((response) => {
					this.setState({
						isActiveLoadingOverlay: false
					});
					this.handleBack();
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
		}
	}

	handleEditPreventive(value, index) {
		// console.log(value);
		// let markers = [ ...this.state.listSimilarity ];
		// markers[index] = { ...markers[index], preventive: value };
		// // markers[index].preventive = value;
		// this.setState({ listSimilarity: markers });

		// this.setState({
		// 	listSimilarity: update(this.state.listSimilarity, { index: { preventive: { $set: value } } })
		// });
		let markers = [ ...this.state.listSimilarity ];
		markers[index] = { ...markers[index], preventive: value };
		this.setState({
			listSimilarity: markers
		});
	}

	handleEditPotential(value, index) {
		let markers = [ ...this.state.listSimilarity ];
		markers[index] = { ...markers[index], potential: value };
		this.setState({
			listSimilarity: markers
		});

		// this.setState({
		// 	listSimilarity: update(this.state.listSimilarity, { 0: { potential: { $set: value } } })
		// });
	}

	render() {
		const previewStyle = {
			display: 'inline',
			width: 250,
			height: 250
		};
		var modalStyles = { overlay: { zIndex: 10 } };

		return (
			<div>
				<BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
					<div className=" row">
						<div className="col-md-6 mx-auto align-self-center">
							<h3 className="">Create New Task</h3>
						</div>

						<div className="col-md-6 mx-auto">
							<button
								type="button"
								className=" float-right clickable close-icon close"
								onClick={this.handleBack}
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>
					<br />
					{this.state.messageFail ? (
						<div className="alert alert-danger col-6" role="alert">
							{this.state.messageFail}
						</div>
					) : null}
					<br />
					<form>
						<div className="form-group row col-12">
							<div className="col-8">
								<label>Task/Job Steps</label>
								<textarea
									class="form-control"
									id="exampleFormControlTextarea1"
									maxLength={10000}
									rows="5"
									value={this.state.task}
									// onKeyDown={(event) => (event.key === 'Enter' ? this.getSimilarity(event) : null)}
									onChange={(event) => this.getSimilarity(event.target.value)}
									// onChange={(event) => this.handleTask(event.target.value)}
								/>
								<div className="row col-12 justify-content-between">
									<p className="text-muted float-left">Press enter to load data potential hazard</p>
									<p className="text-muted float-right">
										{this.state.task ? this.state.task.length : 0} of 10.000 Character remaining
									</p>
								</div>
							</div>
							<div className="col-4">
								<label>Task/Job Steps Image</label>
								<div className="border rounded">
									<ReactDropzone
										className="dragAndDropArea d-flex align-items-center justify-content-center "
										style={{ height: 150 }}
										onDrop={this.onDrop}
										accept="image/jpeg,image/jpg,image/tiff,image/gif,image/png"
										multiple={false}
										accept="image/*"
									>
										{/* <img className="" src={imgDrop} style={{ height: 50, width: 50 }} /> */}
										{this.state.imageUrl ? (
											<img
												className="profile-panel DragnDropDP"
												key={this.state.imageUrl}
												src={this.state.imageUrl}
												style={{ height: 150 }}
											/>
										) : (
											<div>
												<label className="mx-auto text-muted">
													{' '}
													Drag a photo here or click to upload.
												</label>
											</div>
										)}
									</ReactDropzone>
								</div>
							</div>
							<br />
							<br />

							<div className="col-12">
								<button
									className="btn"
									type="button"
									disabled={
										!this.state.task ||
										!this.state.listSimilarity.every((v) => v.potential && v.preventive)
									}
									// disabled={!this.state.task || !this.state.imageUrl}
									style={{ marginBottom: '10px', marginTop: '10px', width: '100%' }}
									onClick={this.createWorkActivity}
								>
									Save
								</button>
							</div>
						</div>
					</form>

					{this.state.loading ? (
						<div class="spinner-border text-primary" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					) : (
						<div class="col-12">
							<table class="table table-bordered-work text-center">
								<thead>
									<tr class="row ">
										<th class="col-sm-6 whitebg">Potential Hazard</th>
										<th class="col-sm-6 whitebg">Preventive/Countermeasures</th>
									</tr>
								</thead>
								<tbody>
									{this.state.listSimilarity.map((v, index) => (
										<tr class="row table-active">
											<td className="col-sm-6">
												<textarea
													class="form-control"
													id="exampleFormControlTextarea1"
													maxLength={10000}
													rows="5"
													value={v.potential}
													// onKeyDown={(event) => (event.key === 'Enter' ? this.getSimilarity(event) : null)}
													onChange={(event) =>
														this.handleEditPotential(event.target.value, index)}
													// onChange={(event) => this.handleTask(event.target.value)}
												/>
											</td>
											<td className="col-sm-6">
												<textarea
													class="form-control"
													id="exampleFormControlTextarea1"
													maxLength={10000}
													rows="5"
													value={v.preventive}
													// onKeyDown={(event) => (event.key === 'Enter' ? this.getSimilarity(event) : null)}
													onChange={(event) =>
														this.handleEditPreventive(event.target.value, index)}
													// onChange={(event) => this.handleTask(event.target.value)}
												/>
											</td>
											{/* <td class="col-sm-6">{v.potential}</td>
												<td class="col-sm-6">{v.preventive}</td> */}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</BlockUi>
			</div>
		);
	}
}
