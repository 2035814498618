import React, { Fragment, Component } from 'react';
import mainLogo from '../logo.png';

import ReactDropzone from 'react-dropzone';
import ReactTable from 'react-table';
import baseUrl from '../url';
import BlockUi from 'react-block-ui';

import * as R from 'ramda';
import { setObject, getObject } from '../utils/localstorage';
import ReactModal from 'react-modal';

import axios from 'axios';
import config from '../config';
import { convertToStd } from '../utils/date';
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom';
import AnalysisCreatorTemplatePdf from '../components/AnalysisCreatorTemplatePdf';
import AnalysisCreatorTemplateDoc from '../components/AnalysisCreatorTemplateDoc';

class AnalysisCreatorTemplateDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isShowingCreateAnalysisCreator: true,
			showFiltering: false,
			detailData: null,
			workActivity: null,
			isActiveLoadingOverlay: false,
			showModelPdf: false,
			showModelDoc: false
		};
		this.page = 0;
		this.pageSize = 10;
		this.fetchData = this.fetchData.bind(this);
		this.getDetailAnalysis = this.getDetailAnalysis.bind(this);

		this.analysis_creator_id = props.analysis_creator_id;
		this.hideNavBar = props.hideNavBar;
	}

	getDetailAnalysis() {
		const user = getObject('user');

		this.setState({
			isActiveLoadingOverlay: true,
			detailData: null
		});

		axios({
			url: `${config.api.analysiscreator}/${this.analysis_creator_id}`,
			method: 'get',
			headers: { 'x-access-token': user.token }
		})
			.then((response) => {
				const data = response.data.data;

				this.setState({
					detailData: data,
					isActiveLoadingOverlay: false
				});
			})
			.catch((err) => {
				console.log(err);
				this.setState({
					isActiveLoadingOverlay: false
				});
			});
	}

	async fetchData(state, instance) {
		this.setState({ loading: true });
		await this.refreshTable(state);
		this.getDetailAnalysis();
	}

	async refreshTable(state) {
		const user = getObject('user');
		const size = state.pageSize;
		const page = parseInt(state.page) + 1;

		this.page = parseInt(state.page);
		this.pageSize = state.pageSize;

		await axios({
			url: `${config.api.analysiscreator}/work_activity_all/${this
				.analysis_creator_id}?size=${size}&page=${page}`,
			method: 'get',
			headers: { 'x-access-token': user.token }
		})
			.then((response) => {
				const data = response.data.data;
				console.log(data.results);
				this.setState({
					workActivity: data.results,
					data: data.results,
					pages: data.totalPage,
					loading: false
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}
	render() {
		const styles = {
			// page: {
			// 	fontSize: 11,
			// 	flexDirection: 'column'
			// },
			// tableContainer: {
			// 	flexDirection: 'row',
			// 	flexWrap: 'wrap'
			// },

			// row: {
			// 	flexDirection: 'row',
			// 	alignItems: 'center'
			// },
			// description: {
			// 	width: '60%'
			// },
			// xyz: {
			// 	width: '40%'
			// },

			table: {
				width: '100%'
			},
			row: {
				display: 'flex',
				flexDirection: 'row',
				borderTop: '1px solid #EEE',
				paddingTop: 8,
				paddingBottom: 8
			},
			header: {
				borderTop: 'none'
			},
			bold: {
				fontWeight: 'bold'
			},
			// So Declarative and unDRY 👌
			row1: {
				width: '27%'
			},
			row2: {
				width: '15%'
			},
			row3: {
				width: '15%'
			},
			row4: {
				width: '20%'
			},
			row5: {
				width: '27%'
			}
		};
		const columns = [
			{
				Header: 'Work Activity',
				accessor: 'task',
				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					const image = `${baseUrl}/${dataAnalysis.image}`;
					return (
						<div className="col-md-auto">
							<div className="col-md-auto">
								<label>{dataAnalysis.task}</label>
							</div>
							{dataAnalysis.image ? (
								<div className="col-md-auto">
									<img
										src={image}
										width="200"
										height="140"
										class="img-fluid"
										alt="Responsive image"
									/>
								</div>
							) : null}
						</div>
					);
				}
			},
			{
				Header: 'Potential Hazard',
				accessor: 'workResult',

				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					return (
						<div>
							{dataAnalysis.workResult.map((v, index) => (
								<div className="col-md-auto">
									<p>{v.potensial} </p>
								</div>
							))}
						</div>
					);
				}
			},
			{
				Header: 'Preventive/Countermeasures',
				accessor: 'workResult',
				Cell: (cellInfo) => {
					const dataAnalysis = cellInfo.original;
					return (
						<div>
							{dataAnalysis.workResult.map((v, index) => (
								<div className="col-md-auto">
									<div className="col-md-auto">
										<p>{v.preventive} </p>
									</div>
									{v.image ? (
										<div className="col-md-auto">
											<img
												src={`${baseUrl}/${v.image}`}
												width="200"
												height="140"
												className="img-fluid"
												alt="Responsive image"
											/>
										</div>
									) : null}
								</div>
							))}
						</div>
					);
				}
			}
		];

		const { data, pages, loading } = this.state;
		var modalStyles = { overlay: { zIndex: 10 } };

		return (
			<div>
				<BlockUi tag="div" blocking={this.state.isActiveLoadingOverlay}>
					<div className="container">
						<br />
						<div className="row  justify-content-between">
							<h1 className="">Analysis Detail</h1>
							<div className="row" style={{ height: '30px' }}>
								{/* <button className="btn btn-primary mr-3" style={{}}>
								Edit
							</button> */}
								<Link
									to={`/analysiscreator/analysiscreatoredit/${this.analysis_creator_id}`}
									class="btn btn-primary mr-3"
								>
									Edit
								</Link>

								<div class="dropdown mr-3">
									<button
										class="btn btn-primary dropdown-toggle"
										type="button"
										id="dropdownMenuButton"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										Export
									</button>
									<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
										<a
											class="dropdown-item"
											href="#"
											onClick={() => {
												this.hideNavBar(true);
												this.setState({
													showModelPdf: true
												});
											}}
										>
											Pdf
										</a>
										<a
											class="dropdown-item"
											href="#"
											onClick={() => {
												this.hideNavBar(true);
												this.setState({
													showModelDoc: true
												});
											}}
										>
											Doc
										</a>
									</div>
								</div>

								{/* <Link to={`/analysiscreator/analysiscreatorpdf`} class="btn btn-primary mr-3">
									<span class="fefont"> Export</span>
								</Link> */}
								{/* <button
									className="btn btn-primary"
									style={{}}
									onClick={(v) => {
										console.log(this.state.detailData);
									}}
								>
									<div className="row " style={{ marginRight: '5px', marginLeft: '5px' }}>
										<span className=" fe fe-arrow-left" style={{ marginRight: '2px' }} />
										<span className="fefont"> Share</span>
									</div>
								</button> */}
							</div>
						</div>
						<hr className="row" />
						<br />
						<div className="row">
							<label className="text-muted">
								<b>COMPANY LOGO</b>
							</label>
						</div>
						<div class="spinner-border" />
						<div className="row">
							<div className="border rounded p-3">
								{this.state.detailData ? this.state.detailData.logo ? (
									<img
										className="img-fluid"
										alt="Responsive image"
										key={this.state.detailData.logo}
										src={`${baseUrl}/${this.state.detailData.logo}`}
										style={{ height: 250 }}
									/>
								) : (
									<p>-</p>
								) : (
									<div>
										<label className="mx-auto text-muted"> - </label>
									</div>
								)}
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12">
								<label className="text-muted">
									<b>JOB INFORMATION</b>
								</label>
							</div>
							<br />
							<div className="col-12">
								<label>
									<b>Project Name</b>
								</label>
								{this.state.detailData ? <p>{this.state.detailData.project_name}</p> : <p>-</p>}
							</div>
							<br />
							<div className="col-12">
								<label>
									<b>Description of Work</b>
								</label>
								{this.state.detailData ? <p>{this.state.detailData.description}</p> : <p>-</p>}
							</div>
							<br />
							<div className="col-12">
								<label>
									<b>Location</b>
								</label>
								{this.state.detailData ? <p>{this.state.detailData.location}</p> : <p>-</p>}
							</div>
							<br />
							<div className="col-12">
								<label>
									<b>Column</b>
								</label>
								{this.state.detailData ? <p>{this.state.detailData.column}</p> : <p>-</p>}
							</div>
							<br />
							<div className="col-12">
								<label>
									<b>Line Number</b>
								</label>
								{this.state.detailData ? <p>{this.state.detailData.line_names}</p> : <p>-</p>}
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12">
								<label className="text-muted">
									<b>ESTIMATE WORK DATES</b>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<label>
									<b>Start Date</b>
								</label>
								{this.state.detailData ? (
									<p>{convertToStd(this.state.detailData.estimate_start_date)}</p>
								) : (
									<p>-</p>
								)}
							</div>
							<div className="col-2">
								<p>-</p>
							</div>

							<div className="col-4">
								<label>
									<b>End Date</b>
								</label>
								{this.state.detailData ? (
									<p>{convertToStd(this.state.detailData.estimate_end_date)}</p>
								) : (
									<p>-</p>
								)}
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12">
								<label className="text-muted">
									<b>WORK SCHEDULE</b>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="col-4">
								<label>
									<b>Start Date</b>
								</label>
								{this.state.detailData ? (
									<p>{convertToStd(this.state.detailData.schedule_start_date)}</p>
								) : (
									<p>-</p>
								)}
							</div>
							<div className="col-2">
								<p>-</p>
							</div>

							<div className="col-4">
								<label>
									<b>End Date</b>
								</label>
								{this.state.detailData ? (
									<p>{convertToStd(this.state.detailData.schedule_end_date)}</p>
								) : (
									<p>-</p>
								)}
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12">
								<label className="text-muted">
									<b>CONTACT INFORMATION</b>
								</label>
							</div>
							<br />
							<div className="col-12">
								<label>
									<b>Foreman/Supervisor Name</b>
								</label>
								{this.state.detailData ? <p>{this.state.detailData.foreman}</p> : <p>-</p>}
							</div>
							<br />
							<div className="col-12">
								<label>
									<b>Safety Representative</b>
								</label>
								{this.state.detailData ? (
									<p>{this.state.detailData.safety_representative}</p>
								) : (
									<p>-</p>
								)}
							</div>
							<br />
							<div className="col-12">
								<label>
									<b>Site Supervisor</b>
								</label>
								{this.state.detailData ? <p>{this.state.detailData.site_supervisor}</p> : <p>-</p>}
							</div>
							<br />

							<div className="col-12">
								<label>
									<b>Site Safety Representative</b>
								</label>
								{this.state.detailData ? (
									<p>{this.state.detailData.site_safety_supervisor}</p>
								) : (
									<p>-</p>
								)}
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12">
								<label className="text-muted">
									<b>PPE</b>
								</label>
							</div>
							<br />
							<div className="col-12 dot-ul">
								<label>
									<b>Required Personal Protective Equiptment</b>
								</label>
								{this.state.detailData ? (
									<ul>{this.state.detailData.list_equipment.split(',').map((v) => <li>{v}</li>)}</ul>
								) : (
									<p>-</p>
								)}
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12">
								<label className="text-muted">
									<b>WORK OPERATION</b>
								</label>
							</div>
							<br />
							<div className="col-12 ">
								<label>
									<b>Work Operation Description</b>
								</label>
								{this.state.detailData ? this.state.detailData.workOperation.length !== 0 ? (
									<ul className="list-group">
										{this.state.detailData.workOperation.map((v, index) => (
											<li>{v.description}</li>
										))}
									</ul>
								) : (
									<p>-</p>
								) : (
									<p>-</p>
								)}
							</div>
						</div>
						<br />
						<div className="row">
							<div className="col-12">
								<label className="text-muted">
									<b>WORK ACTIVITY</b>
								</label>
							</div>
						</div>
						<ReactModal
							isOpen={this.state.showModelDoc}
							contentLabel="Minimal Modal Example"
							style={modalStyles}
						>
							<AnalysisCreatorTemplateDoc
								closeModal={() => {
									this.hideNavBar(false);
									this.setState({
										showModelDoc: false
									});
								}}
								workActivity={this.state.workActivity}
								detailData={this.state.detailData}
								setFailedMessage={this.setFailedMessage}
								setSuccessMessage={this.setSuccessMessage}
							/>
						</ReactModal>
						<ReactModal
							isOpen={this.state.showModelPdf}
							contentLabel="Minimal Modal Example"
							style={modalStyles}
						>
							<AnalysisCreatorTemplatePdf
								closeModal={() => {
									this.hideNavBar(false);
									this.setState({
										showModelPdf: false
									});
								}}
								workActivity={this.state.workActivity}
								detailData={this.state.detailData}
								setFailedMessage={this.setFailedMessage}
								setSuccessMessage={this.setSuccessMessage}
							/>
						</ReactModal>
						<ReactTable
							columns={columns}
							manual
							data={data}
							pages={pages}
							loading={loading}
							onFetchData={this.fetchData}
							filterable={false}
							defaultPageSize={10}
							className="-striped -highlight"
						/>
						<br />
					</div>
				</BlockUi>
			</div>
		);
	}
}

export default withRouter(AnalysisCreatorTemplateDetail);
