import React, { useState, useCallback, Component } from 'react';

export default class LimitedTextarea extends Component {
	constructor(props) {
		super(props);

		this.state = {
			text: props.defaultValue
		};

		this.limit = props.limit;
		this.row = props.row;
		this.onChange = props.onChange;
		this.required = props.required;
		this.id = props.id;
		this.handleText = this.handleText.bind(this);
	}

	handleText(event) {
		var text = event.target.value;

		this.setState({
			text
		});
		this.onChange({
			text: text
		});
	}

	// componentWillReceiveProps(nextProps) {

	// 	if (this.props !== nextProps) {
	// 		this.setState({
	// 			text: nextProps.defaultValue
	// 		});
	// 	}
	// }

	render() {
		return (
			<div>
				<textarea
					class="form-control"
					id={this.id}
					maxLength={this.limit}
					rows={this.row}
					defaultValue={this.state.text}
					// value={this.state.text}
					onChange={this.handleText}
					// onChange={(event) => {
					// 	// this.setState({
					// 	// 	text: event.target.value
					// 	// });
					// 	this.onChange({
					// 		text: event.target.value
					// 	});
					// }}
					required={this.required}
				/>
				<p className="text-muted float-right">{this.state.text.length} of 10.000 Character remaining </p>
			</div>
		);
	}
}
